<template #default>
  <div class="row m-0 justify-content-center">
    <div class="col-8">
      <div class="row m-0 justify-content-center">
        <div class="col-8 p-4">
          <span v-if="sprachID === 0">
            <h2 class="titel text-center BereichUeberschrift">
              Produktion
              <!-- <div id="gl1"></div> -->
            </h2>
          </span>
          <span v-if="sprachID === 1">
            <h2 class="titel text-center BereichUeberschrift">
              Production
              <!-- <div id="gl1"></div> -->
            </h2>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-3 pb-5 justify-content-center ">
    <div class="col-8 grau20 p-4" v-if="sprachID === 0">
      <div class="row">
        <div class="col-3"><h3 class="titelH3">Farben:</h3>      </div>
        <div class="col-8">natur und schwarz      </div>
      </div>
      <div class="row">
        <div class="col-3"><h3 class="titelH3">Verpackungen:</h3>      </div>
        <div class="col-8">Alusäcke (25 kg) auf CP1-Paletten oder Oktabins mit PE-Inlinern auf CP3-Paletten      </div>
      </div>    
    </div>

    <div class="col-8 grau20 p-4" v-if="sprachID === 1">
      <div class="row">
        <div class="col-3"><h3 class="titelH3">Colors:</h3>      </div>
        <div class="col-8">natural and black      </div>
      </div>
      <div class="row">
        <div class="col-3"><h3 class="titelH3">Packaging:</h3>      </div>
        <div class="col-8">aluminum bags (25 kg) on CP1 pallets or octabins with PE liners on CP3 pallets      </div>
      </div>    
    </div>
    
  </div>
  <div class="row m-0 justify-content-center" style="position:relative;">
    <div class="anchor" id="gl1" style="position:absolute; top:-80px;"></div>
    <div class="col-8">
      <div class="row m-0 justify-content-center">
        <div class="col-3">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">Glasfaserverstärkt</h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">             
              Glass Reinforced 
            </h3>
          </span>
        </div>
        <div class="col-9">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">
              Zusatz von Glasfaser modifiziert mechanische Eigenschaften
            </h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Addition of glass fibres modifies mechanical properties</h3>
          </span>
        </div>
      </div>

      <div class="row m-0 justify-content-center">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/p0.png"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span class="deColor pe-1">(DE)</span>
              <span class="enColor pe-1">(EN)</span>
              <span class="itColor">(IT)</span>
            </div>
          </div>

          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G3</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 15%</span>
              <span v-if="sprachID === 1">glass content  15%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G3 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <i
                class="fas fa-notes-medical downloadIcon pe-3 deColor"
                title="Sicherheitsdatenblatt"
                @click="openPDF('SDS_BxGxDE.pdf')"
              ></i
              ><i
                class="fas fa-notes-medical downloadIcon pe-3 enColor"
                title="safety data sheet"
                @click="openPDF('SDS_BxGxEN.pdf')"
              ></i
              ><i
                class="fas fa-notes-medical downloadIcon itColor"
                title="scheda di sicurezza"
                @click="openPDF('SDS_BxGxIT.pdf')"
              ></i>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G4</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 20%</span>
              <span v-if="sprachID === 1">glass content  20%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G4 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <i
                class="fas fa-notes-medical downloadIcon pe-3 deColor"
                title="Sicherheitsdatenblatt"
                @click="openPDF('SDS_BxGxDE.pdf')"
              ></i
              ><i
                class="fas fa-notes-medical downloadIcon pe-3 enColor"
                title="safety data sheet"
                @click="openPDF('SDS_BxGxEN.pdf')"
              ></i
              ><i
                class="fas fa-notes-medical downloadIcon itColor"
                title="scheda di sicurezza"
                @click="openPDF('SDS_BxGxIT.pdf')"
              ></i>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G5</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 25%</span>
              <span v-if="sprachID === 1">glass content  25%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G5 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G6</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 30%</span>
              <span v-if="sprachID === 1">glass content  30%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G6 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G7</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 35%</span>
              <span v-if="sprachID === 1">glass content  35%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G7 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G8</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 40%</span>
              <span v-if="sprachID === 1">glass content  40%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G8 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G9</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 45%</span>
              <span v-if="sprachID === 1">glass content  45%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G9 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1G0</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 50%</span>
              <span v-if="sprachID === 1">glass content  50%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1G0 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B2G7</div>
            <div class="col-5">
              <span v-if="sprachID === 0"
                >Glasfaseranteil 35% <br />mittlere Viskosität</span
              >
              <span v-if="sprachID === 1"
                >glass content  35% <br />medium viscosity</span
              >
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B2G7 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row m-0 justify-content-center pt-5" style="position:relative;">
    <div class="anchor" id="hi1" style="position:absolute; top:-20px;"></div>
    <div class="col-8">
      <div class="row m-0">
        <div class="col-3">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">Hitzestabilisiert</h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Heat Stabilized</h3>
          </span>
        </div>

        <div class="col-9">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">
              Zusatz von Hitzestabilisator ermöglicht Einsatz in Bereichen mit hohen Temperaturen
            </h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Addition of heat stabilizer allows application in areas with high temperatures </h3>
          </span>
        </div>
      </div>

      <div class="row m-0">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/p0.png"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span class="deColor pe-1">(DE)</span>
              <span class="enColor pe-1">(EN)</span>
              <span class="itColor">(IT)</span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG3</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 15%</span>
              <span v-if="sprachID === 1">glass content  15%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG3 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG4</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 20%</span>
              <span v-if="sprachID === 1">glass content  20%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG4 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGx + ECO_2023 STFG DE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGx + ECO_2023 STFG EN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGx + ECO_2023 STFG IT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG5</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 25%</span>
              <span v-if="sprachID === 1">glass content  25%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG5 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGx + ECO_2023 STFG DE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGx + ECO_2023 STFG EN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGx + ECO_2023 STFG IT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG6</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 30%</span>
              <span v-if="sprachID === 1">glass content  30%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG6 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG7</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 35%</span>
              <span v-if="sprachID === 1">glass content  35%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG7 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG8</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 40%</span>
              <span v-if="sprachID === 1">glass content  40%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG8 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG9</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 45%</span>
              <span v-if="sprachID === 1">glass content  45%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG9 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1HG0</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 50%</span>
              <span v-if="sprachID === 1">glass content  50%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1HG0 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <!-- <div class="row m-0 borderGrauUnten">
            <div class="col-4">B2HG7</div>
            <div class="col-5">
              <span v-if="sprachID === 0"
                >Glasfaseranteil 35% <br />mittlere Viskosität</span
              >
              <span v-if="sprachID === 1"
                >glass content  35% <br />medium viscosity</span
              >
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B2HG7 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 justify-content-center pt-5" style="position:relative;">
    <div class="anchor" id="fa1" style="position:absolute; top:-20px;"></div>
    <div class="col-8">
      <div class="row m-0">
        <div class="col-3">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">Farbstabilisiert </h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Color Stabilized</h3>
          </span>
        </div>

        <div class="col-9">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">
              Zusatz von Farbstabilisator schafft Basis für gleichbleibende Einfärbung bei Kunden 
            </h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Addition of color stabilizer enables consistent dyeing by customers </h3>
          </span>
        </div>
      </div>

      <div class="row m-0">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/p3c.png"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span  class="deColor pe-1">(DE)</span>
              <span  class="enColor pe-1">(EN)</span>
              <span  class="itColor">(IT)</span>
            </div>
          </div>

          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B1WG6</div>
            <div class="col-5">
              <span v-if="sprachID === 0">Glasfaseranteil 30%</span>
              <span v-if="sprachID === 1">glass content  30%</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B1WG6 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

 
  <div class="row m-0 justify-content-center pt-5" style="position:relative;">
    <div class="anchor" id="re1" style="position:absolute; top:-20px;"></div>
    <div class="col-8">
      <div class="row m-0">
        <div class="col-3">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">Recycelt</h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Recycled</h3>
          </span>
        </div>
        <div class="col-9">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">
              Zusatz von regranuliertem PA6 schont Ressourcen
            </h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">Addition of recycled PA6 saves resources </h3>
          </span>
        </div>
      </div>

      <div class="row m-0">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/pr2.png"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span  class="deColor pe-1">(DE)</span>
              <span  class="enColor pe-1">(EN)</span>
              <span  class="itColor">(IT)</span>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-4">ECO-G6A</div>
            <div class="col-5">
              <span v-if="sprachID === 0"
                >Glasfaseranteil 30% <br />
                Anteil recyceltes PA6 29%</span
              >
              <span v-if="sprachID === 1"
                >glass content  30% <br />
                content of recycled PA6 29%</span
              >
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS ECO G6A 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">ECO-G7A</div>
            <div class="col-5">
              <span v-if="sprachID === 0"
                >Glasfaseranteil 35% <br />
                Anteil recyceltes PA6 31%</span
              >
              <span v-if="sprachID === 1"
                >glass content  35% <br />
                content of recycled PA6 31%</span
              >
            </div>

            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS ECO G7A 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">ECO-G3B</div>
            <div class="col-5">
              <span v-if="sprachID === 0"
                >Glasfaseranteil 15% <br />Anteil recyceltes PA6 30% - 100%</span
              >
              <span v-if="sprachID === 1"
                >glass content  15% <br />content of recycled PA6 30% - 100%</span
              >
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS ECO G3B 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">ECO-G6B</div>
            <div class="col-5">
              <span v-if="sprachID === 0"
                >Glasfaseranteil  30% <br />
                Anteil recyceltes PA6 29% - 100%</span
              >
              <span v-if="sprachID === 1"
                >glass content  30% <br />
                content of recycled PA6 29% - 100%</span
              >
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS ECO G6B 2023 STFG.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3 deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxGxDE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="safety data sheet"
                  @click="openPDF('SDS_BxGxEN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon itColor"
                  title="scheda di sicurezza"
                  @click="openPDF('SDS_BxGxIT.pdf')"
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
      glasfaserAktiv: false,
      hitzeAktiv: false,
      thermoAktiv: false,
      recAktiv: false,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
