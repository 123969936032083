<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid noHandy">
          <div class="row m-0 mb-0 justify-content-center" id="top">
            <div class="col-10">
              <div class="BereichUeberschrift">
                <i class="far fa-building footerIcon2 BereichUeberschriftPicto"></i>

                <span v-if="sprachID === 0">Impressum</span>
                <span v-if="sprachID === 1">Legal Notice</span>
              </div>
            </div>
          </div>
          <div>
            <div class="row  m-0 pt-4 px-5">
              <div class="col-12 text-center textBlau">
                <h2 class="titel text-center p-3"  v-if="sprachID === 0">
                  Angaben gemäß § 5 TMG
                </h2>                
                <h2 class="titel text-center p-3" v-if="sprachID === 1">
                  Declarations according to § 5 TMG
                </h2>               
              </div>
            </div>
          </div>
          <div class="row justify-content-center pb-5">
            <div class="col-6  pb-5">
              <address class=" pb-5 mt-4 text-center">
                <span v-if="sprachID === 0">
               
                  <p>
                    STFG Filamente GmbH<br />
                    Breitscheidstrasse 135<br />
                    07407 Rudolstadt, Germany
                  </p>
                  <p>
                    Telefon: +49 (0) 3672 313 0<br />
                    E-Mail: info@stfg-rudolstadt.com<br />
                    Web: www.stfg-rudolstadt.com<br />
                  </p>
                  <p>
                    Vertretungsberechtigter Geschäftsführer: Roman Meytis<br />
                    Registergericht: Amtsgericht Jena<br />
                    Registernummer: HRB 505299<br />
                  </p>
                  <p>
                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                    DE271530969<br />
                  </p>
                </span>
                <span v-if="sprachID === 1">
                  <div class="output">
                    <p>
                      STFG Filamente GmbH<br />
                      Breitscheidstrasse 135<br />
                      07407 Rudolstadt, Germany
                    </p>
                    <p>
                      Phone: +49 (0) 3672 313 0<br />
                      E-Mail: info@stfg-rudolstadt.com<br />
                      Web: www.stfg-rudolstadt.com
                    </p>
                    Legal Representative:<br />
                    Managing Director Roman Meytis
                    <p></p>
                    <p>
                      Court of Registry: district court (Amtsgericht) Jena<br />
                      Registration No: HRB 505299<br />
                      VAT identification number: 
                      DE271530969
                    </p>
                  </div>
                </span>
              </address>
              <div class="Platzhalter"></div>
            </div>
          </div>
        </div>

        <div class="container-fluid noMonitor">
          <div class="row m-0 mb-0 justify-content-center" id="top">
            <div class="col-10">
              <div class="BereichUeberschrift">
                <i class="far fa-building footerIcon2 BereichUeberschriftPicto"></i>

                <span v-if="sprachID === 0">Impressum</span>
                <span v-if="sprachID === 1">Legal Notice</span>
              </div>
            </div>
          </div>
          <div>
            <div class="row  m-0 pt-4 px-5">
              <div class="col-12 text-center textBlau">
                <h2 class="titel text-center p-3"  v-if="sprachID === 0">
                  Angaben gemäß § 5 TMG
                </h2>                
                <h2 class="titel text-center p-3" v-if="sprachID === 1">
                  Declarations according to § 5 TMG
                </h2>               
              </div>
            </div>
          </div>
          <div class="row justify-content-center pb-5">
            <div class="col-11  pb-5">
              <address class=" pb-5 mt-4 text-center">
                <span v-if="sprachID === 0">
               
                  <p>
                    STFG Filamente GmbH<br />
                    Breitscheidstrasse 135<br />
                    07407 Rudolstadt, Germany
                  </p>
                  <p>
                    Telefon: +49 (0) 3672 313 0<br />
                    E-Mail: info@stfg-rudolstadt.com<br />
                    Web: www.stfg-rudolstadt.com<br />
                  </p>
                  <p>
                    Vertretungsberechtigter Geschäftsführer: Roman Meytis<br />
                    Registergericht: Amtsgericht Jena<br />
                    Registernummer: HRB 505299<br />
                  </p>
                  <p>
                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                    DE271530969<br />
                  </p>
                </span>
                <span v-if="sprachID === 1">
                  <div class="output">
                    <p>
                      STFG Filamente GmbH<br />
                      Breitscheidstrasse 135<br />
                      07407 Rudolstadt, Germany
                    </p>
                    <p>
                      Phone: +49 (0) 3672 313 0<br />
                      E-Mail: info@stfg-rudolstadt.com<br />
                      Web: www.stfg-rudolstadt.com
                    </p>
                    Legal Representative:<br />
                    Managing Director Roman Meytis
                    <p></p>
                    <p>
                      Court of Registry: district court (Amtsgericht) Jena<br />
                      Registration No: HRB 505299<br />
                      VAT identification number: 
                      DE271530969
                    </p>
                  </div>
                </span>
              </address>
              <div class="Platzhalter"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
