<template #default>
  <div>
    <div class="container-fluid noHandy">
      <div class="row m-0 mb-0 justify-content-center" id="top">
        <div class="col-10">
          <div class="BereichUeberschrift">
            <i class="far fa-file-alt BereichUeberschriftPicto"></i>
            <span v-if="sprachID === 0">Datenschutzerklärung</span>
            <span v-if="sprachID === 1">Privacy Policy</span>
          </div>
        </div>
      </div>

      <div>
        <div class="row m-0 p-5">
          <div class="col-12 text-center textBlau">
            <h2 class="titel text-center" v-if="sprachID === 0">Angaben gemäß DSGVO</h2>
            <h2 class="titel text-center" v-if="sprachID === 1">
              Declarations according to GDPR
            </h2>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-6">
          <article class="">
            <div>
              <p v-if="sprachID === 0">
                <b>Name und Anschrift des Verantwortlichen</b>
              </p>
              <p v-if="sprachID === 1">
                <b>Name and address of responsible company</b>
              </p>
              <p>
                STFG Filamente GmbH<br />
                Breitscheidstrasse 135<br />
                07407 Rudolstadt, Germany
              </p>
              <div v-if="sprachID === 0">
                <p>
                  Telefon: +49 (0) 3672 313 0<br />
                  E-Mail: info@stfg-rudolstadt.com<br />
                  Web: www.stfg-rudolstadt.com
                </p>
                Vertretungsberechtigter Geschäftsführer: Roman Meytis
                <p></p>
                <p>
                  Registergericht: Amtsgericht Jena<br />
                  Registernummer: HRB 505299<br />
                  Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                  DE271530969
                </p>

                <p>
                  <b>Datenschutzerklärung</b>
                </p>
                <p>
                  Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
                  sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                  entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                  Datenschutzerklärung.
                </p>
                <p>
                  Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
                  der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                  lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                  möglich.
                </p>
                <br />
                <p><b>Allgemeines zur Datenverarbeitung</b></p>
                <p><b>Umfang der Verarbeitung personenbezogener Daten</b></p>
                <p>
                  Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur,
                  soweit dies zur Bereitstellung einer funktionsfähigen Website sowie
                  unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
                  personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach
                  Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen
                  eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
                  nicht möglich ist und die Verarbeitung der Daten durch gesetzliche
                  Vorschriften gestattet ist.
                </p>
                <br />
                <p>
                  <b>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</b>
                </p>
                <p>
                  Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
                  Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
                  EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
                </p>
                <p>
                  Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
                  eines Vertrages, dessen Vertragspartei die betroffene Person ist,
                  erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage.
                  Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
                  vorvertraglicher Maßnahmen erforderlich sind.
                </p>
                <p>
                  Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
                  rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
                  unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
                </p>
                <p>
                  Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
                  einer anderen natürlichen Person eine Verarbeitung personenbezogener
                  Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
                  Rechtsgrundlage.
                </p>
                <p>
                  Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
                  Unternehmens oder eines Dritten erforderlich und überwiegen die
                  Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
                  erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
                  Rechtsgrundlage für die Verarbeitung.
                </p>
                <p><b>Datenlöschung und Speicherdauer</b></p>
                <p>
                  Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
                  gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung
                  kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
                  nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
                  sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
                  wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
                  eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
                  es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
                  Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                </p>
                <p>
                  <b>Bereitstellung der Website und Erstellung von Logfiles</b>
                </p>
                <p><b>Beschreibung und Umfang der Datenverarbeitung</b></p>
                <p>
                  Bei jedem Aufruf unserer Internetseite erfasst unser System
                  automatisiert Daten und Informationen vom Computersystem des aufrufenden
                  Rechners.
                </p>
                <p>Folgende Daten werden hierbei erhoben:</p>
                <ol>
                  <li>Informationen über den Browsertyp und die verwendete Version</li>
                  <li>Das Betriebssystem des Nutzers</li>
                  <li>Den Internet-Service-Provider des Nutzers</li>
                  <li>Die IP-Adresse des Nutzers</li>
                  <li>Datum und Uhrzeit des Zugriffs</li>
                  <li>
                    Websites, von denen das System des Nutzers auf unsere Internetseite
                    gelangt
                  </li>
                  <li>
                    Websites, die vom System des Nutzers über unsere Website aufgerufen
                    werden
                  </li>
                </ol>
                <p></p>
                <p>
                  Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.
                  Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen
                  Daten des Nutzers findet nicht statt.
                </p>
                <p><b>Rechtsgrundlage für die Datenverarbeitung</b></p>
                <p>
                  Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
                  Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p><b>Zweck der Datenverarbeitung</b></p>
                <p>
                  Die vorübergehende Speicherung der IP-Adresse durch das System ist
                  notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
                  zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
                  der Sitzung gespeichert bleiben.<br />
                  Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der
                  Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der
                  Website und zur Sicherstellung der Sicherheit unserer
                  informationstechnischen Systeme. Eine Auswertung der Daten zu
                  Marketingzwecken findet in diesem Zusammenhang nicht statt.
                </p>
                <p>
                  In diesen Zwecken liegt auch unser berechtigtes Interesse an der
                  Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p><b>Dauer der Speicherung</b></p>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
                  ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
                  Daten zur Bereitstellung der Website ist dies der Fall, wenn die
                  jeweilige Sitzung beendet ist.<br />
                  Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
                  sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist
                  möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder
                  verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr
                  möglich ist.
                </p>
                <p><b>Widerspruchs- und Beseitigungsmöglichkeit</b></p>
                <p>
                  Die Erfassung der Daten zur Bereitstellung der Website und die
                  Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite
                  zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
                  Widerspruchsmöglichkeit.
                </p>
                <p><b>Verwendung von Cookies</b></p>
                <p><b>a) Beschreibung und Umfang der Datenverarbeitung</b></p>
                <p>
                  Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
                  Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
                  Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
                  Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
                  gespeichert werden. Dieser Cookie enthält eine charakteristische
                  Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
                  erneuten Aufrufen der Website ermöglicht.<br />
                  Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
                  gestalten. Einige Elemente unserer Internetseite erfordern es, dass der
                  aufrufende Browser auch nach einem Seitenwechsel identifiziert werden
                  kann.<br />
                  In den Cookies werden dabei folgende Daten gespeichert und übermittelt:
                </p>
                <ol>
                  <li>Spracheinstellungen</li>
                  <li>Sitzungskennung für jeden Verbindungstyp</li>
                </ol>
                <p></p>
                <p>
                  Wir verwenden auf unserer Website darüber hinaus Cookies, die eine
                  Analyse des Surfverhaltens der Nutzer ermöglichen.
                </p>
                <p>Auf diese Weise können folgende Daten übermittelt werden:</p>
                <ol>
                  <li>Häufigkeit von Seitenaufrufen</li>
                </ol>
                <p></p>
                <p>
                  Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
                  Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
                  aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam
                  mit sonstigen personenbezogenen Daten der Nutzer gespeichert.
                </p>
                <p><b>b) Rechtsgrundlage für die Datenverarbeitung</b></p>
                <p>
                  Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
                  Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p><b>c) Zweck der Datenverarbeitung</b></p>
                <p>
                  Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
                  von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer
                  Internetseite können ohne den Einsatz von Cookies nicht angeboten
                  werden. Für diese ist es erforderlich, dass der Browser auch nach einem
                  Seitenwechsel wiedererkannt wird.
                </p>
                <p>
                  Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden
                  nicht zur Erstellung von Nutzerprofilen verwendet.
                </p>
                <p>
                  Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität
                  unserer Website und ihre Inhalte zu verbessern. Durch die
                  Analyse-Cookies erfahren wir, wie die Website genutzt wird und können so
                  unser Angebot stetig optimieren.
                </p>
                <p>
                  In diesen Zwecken liegt auch unser berechtigtes Interesse in der
                  Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f
                  DSGVO.
                </p>
                <p>
                  <b
                    >d) Dauer der Speicherung, Widerspruchs- und
                    Beseitigungsmöglichkeit</b
                  >
                </p>
                <p>
                  Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an
                  unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle
                  Kontrolle über die Verwendung von Cookies. Durch eine Änderung der
                  Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von
                  Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies
                  können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
                  Werden Cookies für unsere Website deaktiviert, können möglicherweise
                  nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
                </p>
                <p><b>Rechte der betroffenen Person</b></p>
                <p>
                  Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
                  Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
                  dem Verantwortlichen zu:
                </p>
                <p><b>Auskunftsrecht</b></p>
                <p>
                  Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen,
                  ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet
                  werden.
                </p>
                <p>
                  Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen
                  über folgende Informationen Auskunft verlangen:
                </p>
                <ol>
                  <li>
                    die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
                  </li>
                  <li>
                    die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
                  </li>
                  <li>
                    die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die
                    Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch
                    offengelegt werden;
                  </li>
                  <li>
                    die geplante Dauer der Speicherung der Sie betreffenden
                    personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
                    möglich sind, Kriterien für die Festlegung der Speicherdauer;
                  </li>
                  <li>
                    das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                    betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung
                    der Verarbeitung durch den Verantwortlichen oder eines
                    Widerspruchsrechts gegen diese Verarbeitung;
                  </li>
                  <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                  <li>
                    alle verfügbaren Informationen über die Herkunft der Daten, wenn die
                    personenbezogenen Daten nicht bei der betroffenen Person erhoben
                    werden;
                  </li>
                  <li>
                    das Bestehen einer automatisierten Entscheidungsfindung einschließlich
                    Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen
                    Fällen – aussagekräftige Informationen über die involvierte Logik
                    sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
                    Verarbeitung für die betroffene Person.
                  </li>
                </ol>
                <p></p>
                <p>
                  Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
                  betreffenden personenbezogenen Daten in ein Drittland oder an eine
                  internationale Organisation übermittelt werden. In diesem Zusammenhang
                  können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO
                  im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                </p>
                <p><b>Recht auf Berichtigung</b></p>
                <p>
                  Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
                  gegenüber dem Verantwortlichen, sofern die verarbeiteten
                  personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig
                  sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
                </p>
                <p><b>Recht auf Einschränkung der Verarbeitung</b></p>
                <p>
                  Unter den folgenden Voraussetzungen können Sie die Einschränkung der
                  Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
                </p>
                <ul>
                  <li>
                    wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für
                    eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
                    Richtigkeit der personenbezogenen Daten zu überprüfen;
                  </li>
                  <li>
                    die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                    personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
                    Nutzung der personenbezogenen Daten verlangen;
                  </li>
                  <li>
                    der Verantwortliche die personenbezogenen Daten für die Zwecke der
                    Verarbeitung nicht länger benötigt, Sie diese jedoch zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                    benötigen, oder
                  </li>
                  <li>
                    wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
                    eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe
                    des Verantwortlichen gegenüber Ihren Gründen überwiegen.
                  </li>
                </ul>
                <p></p>
                <p>
                  Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
                  eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
                  nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
                  anderen natürlichen oder juristischen Person oder aus Gründen eines
                  wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
                  verarbeitet werden. Wurde die Einschränkung der Verarbeitung nach den
                  o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen
                  unterrichtet bevor die Einschränkung aufgehoben wird.
                </p>
                <p><b>Recht auf Löschung</b></p>
                <p>Löschungspflicht</p>
                <p>
                  Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
                  personenbezogenen Daten unverzüglich gelöscht werden, und der
                  Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen,
                  sofern einer der folgenden Gründe zutrifft:
                </p>
                <p>
                  Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für
                  die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
                  notwendig.
                </p>
                <p>
                  Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
                  Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
                  fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                </p>
                <p>
                  Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung
                  ein und es liegen keine vorrangigen berechtigten Gründe für die
                  Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch
                  gegen die Verarbeitung ein.
                </p>
                <p>
                  Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                  verarbeitet.
                </p>
                <p>
                  Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
                  Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem
                  Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
                  unterliegt.
                </p>
                <p>
                  Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
                  angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
                  DSGVO erhoben.
                </p>
                <p>Information an Dritte</p>
                <p>
                  Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
                  öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
                  Löschung verpflichtet, so trifft er unter Berücksichtigung der
                  verfügbaren Technologie und der Implementierungskosten angemessene
                  Maßnahmen, auch technischer Art, um für die Datenverarbeitung
                  Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu
                  informieren, dass Sie als betroffene Person von ihnen die Löschung aller
                  Links zu diesen personenbezogenen Daten oder von Kopien oder
                  Replikationen dieser personenbezogenen Daten verlangt haben.
                </p>
                <p>Ausnahmen</p>
                <p>
                  Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                  erforderlich ist
                </p>
                <p>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
                <p>
                  zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach
                  dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche
                  unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                  öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
                  erfolgt, die dem Verantwortlichen übertragen wurde;
                </p>
                <p>
                  aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
                  Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                </p>
                <p>
                  für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche
                  oder historische Forschungszwecke oder für statistische Zwecke gem. Art.
                  89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht
                  voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung
                  unmöglich macht oder ernsthaft beeinträchtigt, oder
                </p>
                <p>
                  zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <p><b>Recht auf Unterrichtung</b></p>
                <p>
                  Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
                  Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
                  verpflichtet, allen Empfängern, denen die Sie betreffenden
                  personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
                  Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
                  sei denn, dies erweist sich als unmöglich oder ist mit einem
                  unverhältnismäßigen Aufwand verbunden.
                </p>
                <p>
                  Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
                  Empfänger unterrichtet zu werden.
                </p>
                <p><b>Recht auf Datenübertragbarkeit</b></p>
                <p>
                  Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
                  Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
                  gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie
                  das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
                  durch den Verantwortlichen, dem die personenbezogenen Daten
                  bereitgestellt wurden, zu übermitteln, sofern
                </p>
                <p>
                  die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO
                  oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs.
                  1 lit. b DSGVO beruht und
                </p>
                <p>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
                <p>
                  In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
                  die Sie betreffenden personenbezogenen Daten direkt von einem
                  Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
                  soweit dies technisch machbar ist. Freiheiten und Rechte anderer
                  Personen dürfen hierdurch nicht beeinträchtigt werden.
                </p>
                <p>
                  Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
                  personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
                  erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
                  öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                </p>
                <p><b>Widerspruchsrecht</b></p>
                <p>
                  Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
                  Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
                  DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                  Bestimmungen gestütztes Profiling.
                </p>
                <p>
                  Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
                  Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe
                  für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
                  Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
                  Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <p>
                  Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
                  Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
                  gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                  Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
                  soweit es mit solcher Direktwerbung in Verbindung steht.
                </p>
                <p>
                  Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
                  werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese
                  Zwecke verarbeitet.
                </p>
                <p>
                  Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
                  der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG –
                  Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
                  denen technische Spezifikationen verwendet werden.
                </p>
                <p>
                  <b
                    >Recht auf Widerruf der datenschutzrechtlichen
                    Einwilligungserklärung</b
                  >
                </p>
                <p>
                  Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
                  jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
                  Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
                  Verarbeitung nicht berührt.
                </p>
                <p>
                  <b
                    >Automatisierte Entscheidung im Einzelfall einschließlich Profiling</b
                  >
                </p>
                <p>
                  Sie haben das Recht, nicht einer ausschließlich auf einer
                  automatisierten Verarbeitung - einschließlich Profiling – beruhenden
                  Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
                  Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
                  Dies gilt nicht, wenn die Entscheidung
                </p>
                <ol>
                  <li>
                    für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
                    dem Verantwortlichen erforderlich ist,
                  </li>
                  <li>
                    aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
                    denen der Verantwortliche unterliegt, zulässig ist und diese
                    Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
                    Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
                  </li>
                  <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
                </ol>
                <p></p>
                <p>
                  Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
                  personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
                  Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum
                  Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen
                  getroffen wurden.
                </p>
                <p>
                  Hinsichtlich der in 1. und 3. genannten Fälle trifft der Verantwortliche
                  angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre
                  berechtigten Interessen zu wahren, wozu mindestens das Recht auf
                  Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf
                  Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
                  gehört.
                </p>
                <p><b>Recht auf Beschwerde bei einer Aufsichtsbehörde</b></p>
                <p>
                  Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                  gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
                  einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                  Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
                  Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                  betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                </p>
                <p>
                  Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
                  unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
                  Beschwerde einschließlich der Möglichkeit eines gerichtlichen
                  Rechtsbehelfs nach Art. 78 DSGVO.
                </p>
              </div>
              <div v-if="sprachID === 1">
                <p>
                  Phone: +49 (0) 3672 313 0 <br />
                  E-Mail: info@stfg-rudolstadt.com <br />
                  Web: www.stfg-rudolstadt.com
                </p>
                <p>Legal Representative: Managing Director Roman Meytis</p>
                <p>
                  Court of Registry: district court (Amtsgericht) Jena <br />
                  Registration No: HRB 505299 <br />
                  VAT identification number: DE271530969
                </p>
                <p><b>Privacy policy</b></p>
                <p>
                  The operators of these pages take the protection of your personal data
                  very seriously. We treat your personal data confidentially and in
                  accordance with the statutory data protection regulations and the data
                  protection declaration at hand.
                </p>
                <p>
                  However, we point out that data transmission over the Internet (e.g.
                  communication by e-mail) can be subject to security gaps. Complete
                  protection of data against access by third parties is not possible.
                </p>
                <p><b>General information regarding data processing</b></p>
                <p><b>Processing scope of personal data</b></p>
                <p>
                  We process personal data of our users as far as this is necessary for
                  the provision of a functional website along with its content and
                  services. The processing of personal data of our users is carried out
                  only upon consent of those users. An exception applies in those cases
                  where obtaining prior consent is not possible and the processing of
                  corresponding data is permitted by legal regulations.
                </p>
                <p><b>Legal basis for the processing of personal data</b></p>
                <p>
                  In case we obtain the consent for processing operations regarding
                  personal data, Art. 6 (1) lit. a EU General Data Protection Regulation
                  (GDPR) serves as the legal basis. When processing personal data is
                  necessary in order to fulfill a contract, Art. 6 (1) lit. b GDPR serves
                  as the legal basis. This also applies to processing operations that are
                  necessary due to pre-contractual obligations.
                </p>
                <p>
                  In case processing of personal data is necessary to ensure compliance
                  with legal obligations, Art. 6 (1) lit. c GDPR serves as the legal
                  basis.
                </p>
                <p>
                  In the event that vital interests of the data subject or another natural
                  person require processing of personal data, Art. 6 (1) lit. d GDPR
                  serves as the legal basis.
                </p>
                <p>
                  In case processing of personal data is necessary due to a legitimate
                  interest of our company or a third party, Art. 6 (1) f GDPR serves as
                  the legal basis for the processing. Provided that the individual’s
                  interests or fundamental rights do not take precedence.
                </p>
                <p><b>Data deletion and storage period</b></p>
                <p>
                  Personal data of individuals will be deleted or restricted for
                  processing as soon as the purpose for collection ceases to apply.
                  Storage may continue in case this has been requested by European or
                  national legislation. Data will also be deleted or restricted for
                  processing if the mandatory storage period expires, unless there is a
                  need for further storage in order to fulfill contractual obligations.
                </p>
                <p><b>Provision of the website and creation of log files</b></p>
                <p><b>Description and scope of data processin</b>g</p>
                <p>Each time our website is visited, our system automatically collects data from the accessing computer</p>
                <p>The following data is collected in this process:</p>
                <p>
                  <ol>
                    <li>information about the browser type and the version used</li>
                    <li>the operating system of the user</li>
                    <li>the internet service provider of the user</li>
                    <li>the IP address of the user</li>
                    <li>date and time of access</li>
                    <li>websites from which the user's system accesses our website</li>
                    <li>websites that are accessed by the user's system via our website</li>
                  </ol>
                </p>
                <p>This data is also stored in the log files of our system. This data is not stored together with other personal data of the user.</p>
                <p><b>Legal basis for data processing</b></p>
                <p>The legal basis for the temporary storage of data and the log files is Art. 6 (1) lit. f GDPR.</p>
                <p><b>Purpose of the data processing</b></p>
                <p>The temporary storage of the IP address by the system is necessary to enable delivery of the website to the user's computer. For this purpose, the 
                  user's IP address must remain stored for the duration of the session.</p>
                <p>The storage in log files is done to ensure the functionality of the website. In addition, we use the data to optimize the website and to ensure the
                   security of our information technology systems. An evaluation of the data for marketing purposes does not take place in this context.</p>
                <p>These purposes are also our legitimate interest in data processing according to Art. 6 para. 1 lit. f GDPR.</p>
                <p><b>Duration of storage</b></p>
                <p>The data is deleted as soon as it is no longer required to achieve the purpose for which it was collected. In the case of the collection of data for
                   the provision of the website, this is the case when the respective session has ended.</p>
                <p>In the case of storage of data in log files, this is the case after seven days at the latest. Storage beyond this period is possible. In this case, 
                  the IP addresses of the users are deleted or alienated, so that an assignment of the client is no longer possible.</p>
                <p><b>Possibility of objection and removal</b></p>
                <p>The collection of data for the provision of the website and the storage of the data in log files is absolutely necessary for the operation of the 
                  website. Consequently, there is no possibility of objection by the user.</p>
                <p><b>Use of cookies</b></p>
                <p><b>a) Description and scope of data processing</b></p>
                <p>Our website uses cookies. Cookies are text files that are stored in the Internet browser or by the Internet browser on the user's computer system.
                   When a user visits a website, a cookie may be stored on the user's operating system. This cookie contains a characteristic string of characters that 
                   enables the browser to be uniquely identified when the website is visited again.</p>
                <p>We use cookies to make our website more user-friendly. Some elements of our website require that the visiting browser can be identified even after a page change.</p>
                <p>The following data is stored and transmitted in the cookies:</p>
                <p>
                  <ol>
                    <li>language settings</li>
                    <li>session identifier for each connection type</li>
                  </ol>
                </p>
                <p>We also use cookies on our website that enable an analysis of the user's surfing behavior.</p>
                <p>
                  <ol>
                    <li>frequency of page views.</li>
                  </ol></p>
                <p>The user data collected in this way is pseudonymized by technical precautions. Therefore, an assignment of the data to the visiting user 
                  is no longer possible. The data is not stored together with other personal data of the users.</p>
                <p><b>b) Legal basis for data processing</b></p>
                <p>The legal basis for the processing of personal data using cookies is Art. 6 (1) lit. f GDPR.</p>
                <p><b>c) Purpose of the data processing</b></p>
                <p>The purpose of using technically necessary cookies is to simplify the use of websites for users. Some functions of our website cannot be 
                  offered without the use of cookies. For these, it is necessary that the browser is recognized even after a page change.</p>
                <p>The user data collected by technically necessary cookies are not used to create user profiles.</p>
                <p>The cookies are used for the purpose of improving the quality of our website and its content. Through those cookies, we learn how the website 
                  is used and can thus constantly optimize related aspects.</p>
                <p>These purposes are also our legitimate interest in processing the personal data according to Art. 6 para. 1 lit. f GDPR.</p>
                <p><b>d) Duration of storage, possibility of objection and removal.</b></p>
                <p>Cookies are stored on the user's computer and transmitted to our site by the user. Therefore, you as a user also have full control over the
                   use of cookies. By changing the settings in your Internet browser, you can disable or restrict the transmission of cookies. Cookies that have already been
                    stored can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may no longer be possible to use all functions of the website in full.</p>
                <p><b>Rights of the data subject</b></p>
                <p>If your personal data is processed, you are a data subject within the meaning of the GDPR and you have the following rights against the responsible company:</p>

                <p><b>Right to information</b></p>
                <p>You may request confirmation from the responsible company as to whether personal data concerning you is being processed.</p>
                <p>If there is such processing, you may request information from the responsible company about the following:</p>
                <p>
                  <ol>
                    <li>the purposes for which the personal data are processed;</li>
                    <li>the categories of personal data which are processed;</li>
                    <li>the recipients or categories of recipients to whom the personal data concerning you have been or will be disclosed;</li>
                    <li>the planned duration of the storage of the personal data relating to you or, if specific information cannot be provided, criteria for determining the storage period;</li>
                    <li>the existence of a right to rectification or erasure of the personal data concerning you, a right to restriction of processing by the responsible company or a right to object to such processing;</li>
                    <li>the existence of a right of appeal to a supervisory authority;</li>
                    <li>any available information on the origin of the data, if the personal data is not collected from the data subject;</li>
                    <li>the existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, 
                      meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.</li>
                  </ol>
                </p>
                <p>You have the right to request information about whether the personal data concerning you is transferred to a third country or to an international 
                  organization. In this context, you may request to be informed about the appropriate safeguards pursuant to Art. 46 GDPR regarding the transfer.</p>
                <p><b>Right to rectification</b></p>
                <p>You have a right to rectification and/or completion, in case the personal data processed concerning you is inaccurate or incomplete. The responsible 
                  company shall carry out the rectification without undue delay.</p>
                <p><b>Right to restriction of processing</b></p>
                <p>You may request the restriction of the processing of personal data concerning you under the following conditions:</p>
                <p>
                  <ul>
                    <li>if you contest the accuracy of the personal data concerning you for a period enabling the responsible company to verify the accuracy of the personal data;</li>
                    <li>the processing is unlawful and you object to the erasure of the personal data and request instead the restriction of the use of the personal data;</li>
                    <li>the responsible company no longer needs the personal data for the purposes of processing, but you need them for the establishment, exercise or defense of legal claims; or</li>
                    <li>if you have objected to the processing pursuant to Article 21 (1) GDPR and it is not yet clear whether the legitimate interests of the responsible company outweigh your interests.
                      </li>
                  </ul>
                </p>
                <p>Where the processing of personal data concerning you has been restricted, such data may be processed, with the exception of storage, only with your 
                  consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for 
                  reasons of important public interest of the European Union or a Member State. If the restriction of processing has been in turn restricted in accordance 
                  with the above conditions, you will be informed by the responsible company before the restriction is lifted.</p>
               
               
                <p> <b>Right to deletion</b></p>
                <p>Obligation to delete</p>
                <p>You may request the responsible company to erase the personal data concerning you without undue delay, and the responsible company is obliged to erase 
                  such data without undue delay, if one of the following reasons applies:</p>
                <p>The personal data concerning you is no longer necessary for the purposes for which it was collected or otherwise processed.</p>
                <p>You revoke your consent on which the processing was based pursuant to Art. 6 (1) a or Art. 9 (2) a GDPR and there is no other legal basis for the processing.</p>
                <p>You object to the processing pursuant to Art. 21 (1) GDPR and there are no overriding legitimate interests for the processing, or you object to the processing pursuant to Art. 21 (2) GDPR.</p>
                <p>The personal data concerning you has been processed unlawfully.</p>
                <p>The erasure of the personal data concerning you is necessary for compliance with a legal obligation under European Union or Member State law to which 
                  the responsible company is subject.</p>
                <p>The personal data concerning you has been collected in relation to information society services pursuant to Article 8(1) of the GDPR.</p>
                <p>Information provided to third parties</p>
                <p>If the responsible company has made the personal data concerning you public and is obliged to erase it pursuant to Article 17(1) of the GDPR, it shall 
                  take reasonable steps, including technical measures, to inform data processing entities, which process your personal data, about the request for erasure.</p>
                <p>Exceptions</p>
                <p>The right to erasure does not exist as far as the processing is necessary</p>
                <p>for the exercise of the right to freedom of expression and information;</p>
                <p>for compliance with a legal obligation which requires processing under European Union or Member State law to which the responsible company is subject, 
                  or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the responsible company;</p>
                <p>for reasons of public interest in the area of public health pursuant to Art. 9(2)(h) and (i) and Art. 9(3) GDPR;</p>
                <p>for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes pursuant to Article 89(1) GDPR, as far as 
                  the right referred to in section a) is likely to prevent the achievement of those aforementioned purposes; or</p>
                <p>for the assertion, exercise or defense of legal claims.</p>
                <p><b>Right to notification </b></p>
                <p>If you have asserted the right to rectification, erasure or restriction of processing against the responsible company, the responsible company is obliged 
                  to inform all recipients to whom the personal data concerning you has been disclosed of this rectification or erasure of the data or restriction of 
                  processing, unless this proves impossible or involves a disproportionate effort.</p>
                <p>You have the right against the responsible company to be informed about these recipients.</p>
                <p><b>Right to data portability</b></p>
                <p>You have the right to receive the personal data concerning you that you have provided to the responsible company in a structured, commonly used 
                  and machine-readable format. In addition, you have the right to transfer this data to another processing entity without hindrance from the responsible 
                  company to whom the personal data was provided, under the condition that</p>
                <p>the processing is based on consent pursuant to Art. 6 (1) a GDPR or Art. 9 (2) a GDPR or on a contract pursuant to Art. 6 (1) b GDPR and</p>
                <p>the processing is carried out with the help of automated procedures.</p>
                <p>By exercising this right, you also have the right that the personal data concerning you is transferred directly from the responsible company to 
                  another processing entity, as far as this is technically feasible. Freedoms and rights of other individuals must not be affected by this.</p>
                <p>The right to data portability does not apply to processing of personal data necessary for the performance of a task carried out in the public 
                  interest or in the exercise of official authority vested in the responsible company.</p>
                <p><b>Right to objection</b></p>
                <p>You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning you which 
                  is carried out on the basis of Article 6(1)(e) or (f) GDPR; this also applies to profiling based on these provisions.</p>
                <p>The responsible company shall no longer process the personal data concerning you unless it can demonstrate compelling legitimate interests for 
                  the processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending legal claims.</p>
                <p>If the personal data concerning you is processed for the purposes of direct marketing, you have the right to object at any time to the processing 
                  of personal data concerning you for the purposes of such marketing; this also applies to profiling, as far as it is related to such direct marketing.</p>
                <p>If you object to the processing for direct marketing purposes, the personal data concerning you will no longer be processed for these purposes.</p>
                <p>You have the possibility, in connection with the use of information society services - notwithstanding Directive 2002/58/EC - to exercise your 
                  right to object by means of automated procedures using technical specifications.</p>
                <p><b>Right to revoke the declaration of consent under data protection law</b></p>
                <p>You have the right to revoke your declaration of consent under data protection law at any time. The revocation of consent does not affect the 
                  lawfulness of the processing carried out on the basis of the consent until the revocation.</p>
                <p><b>Automated decision in individual cases including profiling</b></p>
                <p>You have the right not to be subject to a decision based solely on automated processing - including profiling - which produces legal effects 
                  concerning you or affects you in a similarly significant way. This does not apply if the decision</p>
                <p>
                  <ol>
                    <li>is necessary for the conclusion or performance of a contract between you and the responsible company,</li>
                    <li>is permissible on the basis of legal provisions of the European Union or the Member States to which the responsible company is subject and 
                      these legal provisions contain appropriate measures to protect your rights and freedoms as well as your legitimate interests, or</li>
                    <li>is made with your consent.</li>
                  </ol>
                </p>
                <p>However, these decisions may not be based on special categories of personal data pursuant to Article 9(1) of the GDPR, unless Article 9(2)(a) or 
                  (g) of the GDPR applies and appropriate measures have been taken to protect the rights and freedoms as well as your legitimate interests.</p>
                <p>With regard to the cases mentioned in 1. and 3. above, the responsible company shall take reasonable steps to safeguard the rights and freedoms 
                  as well as your legitimate interests, which include at least the right to obtain the intervention of a person, to express his or her point of view and to contest the decision.</p>
                <p><b>Right to complaint towards a supervisory authority</b></p>
                <p>Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, 
                  in particular in the Member State of your residence, workplace or the place of the alleged infringement, if you consider that the processing of
                   personal data concerning you infringes the GDPR.</p>
                <p>The supervisory authority to which the complaint has been lodged shall inform the complainant of the status and outcome of the complaint, 
                  including the possibility of a judicial remedy under Article 78 GDPR.</p>
                <p></p>
                
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>


    <div class="container-fluid noMonitor">
      <div class="row m-0 mb-0 justify-content-center" id="top">
        <div class="col-12">
          <div class="BereichUeberschrift">
            <i class="far fa-file-alt BereichUeberschriftPicto"></i>
            <span v-if="sprachID === 0">Datenschutz- erklärung</span>
            <span v-if="sprachID === 1">Privacy Policy</span>
          </div>
        </div>
      </div>

      <div>
        <div class="row m-0 p-0">
          <div class="col-12 text-center textBlau">
            <h2 class="titel text-center" v-if="sprachID === 0">Angaben gemäß DSGVO</h2>
            <h2 class="titel text-center" v-if="sprachID === 1">
              Declarations according to GDPR
            </h2>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-11">
          <article class="">
            <div>
              <p v-if="sprachID === 0">
                <b>Name und Anschrift des Verantwortlichen</b>
              </p>
              <p v-if="sprachID === 1">
                <b>Name and address of responsible company</b>
              </p>
              <p>
                STFG Filamente GmbH<br />
                Breitscheidstrasse 135<br />
                07407 Rudolstadt, Germany
              </p>
              <div v-if="sprachID === 0">
                <p>
                  Telefon: +49 (0) 3672 313 0<br />
                  E-Mail: info@stfg-rudolstadt.com<br />
                  Web: www.stfg-rudolstadt.com
                </p>
                Vertretungsberechtigter Geschäftsführer: Roman Meytis
                <p></p>
                <p>
                  Registergericht: Amtsgericht Jena<br />
                  Registernummer: HRB 505299<br />
                  Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                  DE271530969
                </p>

                <p>
                  <b>Datenschutzerklärung</b>
                </p>
                <p>
                  Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
                  sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                  entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                  Datenschutzerklärung.
                </p>
                <p>
                  Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
                  der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                  lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                  möglich.
                </p>
                <br />
                <p><b>Allgemeines zur Datenverarbeitung</b></p>
                <p><b>Umfang der Verarbeitung personenbezogener Daten</b></p>
                <p>
                  Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur,
                  soweit dies zur Bereitstellung einer funktionsfähigen Website sowie
                  unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
                  personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach
                  Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen
                  eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
                  nicht möglich ist und die Verarbeitung der Daten durch gesetzliche
                  Vorschriften gestattet ist.
                </p>
                <br />
                <p>
                  <b>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</b>
                </p>
                <p>
                  Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
                  Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
                  EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
                </p>
                <p>
                  Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
                  eines Vertrages, dessen Vertragspartei die betroffene Person ist,
                  erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage.
                  Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
                  vorvertraglicher Maßnahmen erforderlich sind.
                </p>
                <p>
                  Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
                  rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
                  unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
                </p>
                <p>
                  Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
                  einer anderen natürlichen Person eine Verarbeitung personenbezogener
                  Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
                  Rechtsgrundlage.
                </p>
                <p>
                  Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
                  Unternehmens oder eines Dritten erforderlich und überwiegen die
                  Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
                  erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
                  Rechtsgrundlage für die Verarbeitung.
                </p>
                <p><b>Datenlöschung und Speicherdauer</b></p>
                <p>
                  Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
                  gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung
                  kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
                  nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
                  sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
                  wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
                  eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
                  es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
                  Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                </p>
                <p>
                  <b>Bereitstellung der Website und Erstellung von Logfiles</b>
                </p>
                <p><b>Beschreibung und Umfang der Datenverarbeitung</b></p>
                <p>
                  Bei jedem Aufruf unserer Internetseite erfasst unser System
                  automatisiert Daten und Informationen vom Computersystem des aufrufenden
                  Rechners.
                </p>
                <p>Folgende Daten werden hierbei erhoben:</p>
                <ol>
                  <li>Informationen über den Browsertyp und die verwendete Version</li>
                  <li>Das Betriebssystem des Nutzers</li>
                  <li>Den Internet-Service-Provider des Nutzers</li>
                  <li>Die IP-Adresse des Nutzers</li>
                  <li>Datum und Uhrzeit des Zugriffs</li>
                  <li>
                    Websites, von denen das System des Nutzers auf unsere Internetseite
                    gelangt
                  </li>
                  <li>
                    Websites, die vom System des Nutzers über unsere Website aufgerufen
                    werden
                  </li>
                </ol>
                <p></p>
                <p>
                  Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.
                  Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen
                  Daten des Nutzers findet nicht statt.
                </p>
                <p><b>Rechtsgrundlage für die Datenverarbeitung</b></p>
                <p>
                  Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
                  Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p><b>Zweck der Datenverarbeitung</b></p>
                <p>
                  Die vorübergehende Speicherung der IP-Adresse durch das System ist
                  notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
                  zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
                  der Sitzung gespeichert bleiben.<br />
                  Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der
                  Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der
                  Website und zur Sicherstellung der Sicherheit unserer
                  informationstechnischen Systeme. Eine Auswertung der Daten zu
                  Marketingzwecken findet in diesem Zusammenhang nicht statt.
                </p>
                <p>
                  In diesen Zwecken liegt auch unser berechtigtes Interesse an der
                  Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p><b>Dauer der Speicherung</b></p>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
                  ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
                  Daten zur Bereitstellung der Website ist dies der Fall, wenn die
                  jeweilige Sitzung beendet ist.<br />
                  Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens
                  sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist
                  möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder
                  verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr
                  möglich ist.
                </p>
                <p><b>Widerspruchs- und Beseitigungsmöglichkeit</b></p>
                <p>
                  Die Erfassung der Daten zur Bereitstellung der Website und die
                  Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite
                  zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
                  Widerspruchsmöglichkeit.
                </p>
                <p><b>Verwendung von Cookies</b></p>
                <p><b>a) Beschreibung und Umfang der Datenverarbeitung</b></p>
                <p>
                  Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
                  Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
                  Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
                  Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
                  gespeichert werden. Dieser Cookie enthält eine charakteristische
                  Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
                  erneuten Aufrufen der Website ermöglicht.<br />
                  Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
                  gestalten. Einige Elemente unserer Internetseite erfordern es, dass der
                  aufrufende Browser auch nach einem Seitenwechsel identifiziert werden
                  kann.<br />
                  In den Cookies werden dabei folgende Daten gespeichert und übermittelt:
                </p>
                <ol>
                  <li>Spracheinstellungen</li>
                  <li>Sitzungskennung für jeden Verbindungstyp</li>
                </ol>
                <p></p>
                <p>
                  Wir verwenden auf unserer Website darüber hinaus Cookies, die eine
                  Analyse des Surfverhaltens der Nutzer ermöglichen.
                </p>
                <p>Auf diese Weise können folgende Daten übermittelt werden:</p>
                <ol>
                  <li>Häufigkeit von Seitenaufrufen</li>
                </ol>
                <p></p>
                <p>
                  Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
                  Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum
                  aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam
                  mit sonstigen personenbezogenen Daten der Nutzer gespeichert.
                </p>
                <p><b>b) Rechtsgrundlage für die Datenverarbeitung</b></p>
                <p>
                  Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
                  Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
                </p>
                <p><b>c) Zweck der Datenverarbeitung</b></p>
                <p>
                  Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
                  von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer
                  Internetseite können ohne den Einsatz von Cookies nicht angeboten
                  werden. Für diese ist es erforderlich, dass der Browser auch nach einem
                  Seitenwechsel wiedererkannt wird.
                </p>
                <p>
                  Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden
                  nicht zur Erstellung von Nutzerprofilen verwendet.
                </p>
                <p>
                  Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität
                  unserer Website und ihre Inhalte zu verbessern. Durch die
                  Analyse-Cookies erfahren wir, wie die Website genutzt wird und können so
                  unser Angebot stetig optimieren.
                </p>
                <p>
                  In diesen Zwecken liegt auch unser berechtigtes Interesse in der
                  Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f
                  DSGVO.
                </p>
                <p>
                  <b
                    >d) Dauer der Speicherung, Widerspruchs- und
                    Beseitigungsmöglichkeit</b
                  >
                </p>
                <p>
                  Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an
                  unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle
                  Kontrolle über die Verwendung von Cookies. Durch eine Änderung der
                  Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von
                  Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies
                  können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
                  Werden Cookies für unsere Website deaktiviert, können möglicherweise
                  nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
                </p>
                <p><b>Rechte der betroffenen Person</b></p>
                <p>
                  Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
                  Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
                  dem Verantwortlichen zu:
                </p>
                <p><b>Auskunftsrecht</b></p>
                <p>
                  Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen,
                  ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet
                  werden.
                </p>
                <p>
                  Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen
                  über folgende Informationen Auskunft verlangen:
                </p>
                <ol>
                  <li>
                    die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
                  </li>
                  <li>
                    die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
                  </li>
                  <li>
                    die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die
                    Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch
                    offengelegt werden;
                  </li>
                  <li>
                    die geplante Dauer der Speicherung der Sie betreffenden
                    personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
                    möglich sind, Kriterien für die Festlegung der Speicherdauer;
                  </li>
                  <li>
                    das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                    betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung
                    der Verarbeitung durch den Verantwortlichen oder eines
                    Widerspruchsrechts gegen diese Verarbeitung;
                  </li>
                  <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                  <li>
                    alle verfügbaren Informationen über die Herkunft der Daten, wenn die
                    personenbezogenen Daten nicht bei der betroffenen Person erhoben
                    werden;
                  </li>
                  <li>
                    das Bestehen einer automatisierten Entscheidungsfindung einschließlich
                    Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen
                    Fällen – aussagekräftige Informationen über die involvierte Logik
                    sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
                    Verarbeitung für die betroffene Person.
                  </li>
                </ol>
                <p></p>
                <p>
                  Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
                  betreffenden personenbezogenen Daten in ein Drittland oder an eine
                  internationale Organisation übermittelt werden. In diesem Zusammenhang
                  können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO
                  im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                </p>
                <p><b>Recht auf Berichtigung</b></p>
                <p>
                  Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
                  gegenüber dem Verantwortlichen, sofern die verarbeiteten
                  personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig
                  sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
                </p>
                <p><b>Recht auf Einschränkung der Verarbeitung</b></p>
                <p>
                  Unter den folgenden Voraussetzungen können Sie die Einschränkung der
                  Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
                </p>
                <ul>
                  <li>
                    wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für
                    eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
                    Richtigkeit der personenbezogenen Daten zu überprüfen;
                  </li>
                  <li>
                    die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                    personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
                    Nutzung der personenbezogenen Daten verlangen;
                  </li>
                  <li>
                    der Verantwortliche die personenbezogenen Daten für die Zwecke der
                    Verarbeitung nicht länger benötigt, Sie diese jedoch zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                    benötigen, oder
                  </li>
                  <li>
                    wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
                    eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe
                    des Verantwortlichen gegenüber Ihren Gründen überwiegen.
                  </li>
                </ul>
                <p></p>
                <p>
                  Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
                  eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
                  nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
                  anderen natürlichen oder juristischen Person oder aus Gründen eines
                  wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
                  verarbeitet werden. Wurde die Einschränkung der Verarbeitung nach den
                  o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen
                  unterrichtet bevor die Einschränkung aufgehoben wird.
                </p>
                <p><b>Recht auf Löschung</b></p>
                <p>Löschungspflicht</p>
                <p>
                  Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
                  personenbezogenen Daten unverzüglich gelöscht werden, und der
                  Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen,
                  sofern einer der folgenden Gründe zutrifft:
                </p>
                <p>
                  Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für
                  die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
                  notwendig.
                </p>
                <p>
                  Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
                  Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
                  fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                </p>
                <p>
                  Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung
                  ein und es liegen keine vorrangigen berechtigten Gründe für die
                  Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch
                  gegen die Verarbeitung ein.
                </p>
                <p>
                  Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                  verarbeitet.
                </p>
                <p>
                  Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
                  Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem
                  Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
                  unterliegt.
                </p>
                <p>
                  Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
                  angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
                  DSGVO erhoben.
                </p>
                <p>Information an Dritte</p>
                <p>
                  Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
                  öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
                  Löschung verpflichtet, so trifft er unter Berücksichtigung der
                  verfügbaren Technologie und der Implementierungskosten angemessene
                  Maßnahmen, auch technischer Art, um für die Datenverarbeitung
                  Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu
                  informieren, dass Sie als betroffene Person von ihnen die Löschung aller
                  Links zu diesen personenbezogenen Daten oder von Kopien oder
                  Replikationen dieser personenbezogenen Daten verlangt haben.
                </p>
                <p>Ausnahmen</p>
                <p>
                  Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                  erforderlich ist
                </p>
                <p>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
                <p>
                  zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach
                  dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche
                  unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                  öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
                  erfolgt, die dem Verantwortlichen übertragen wurde;
                </p>
                <p>
                  aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
                  Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                </p>
                <p>
                  für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche
                  oder historische Forschungszwecke oder für statistische Zwecke gem. Art.
                  89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht
                  voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung
                  unmöglich macht oder ernsthaft beeinträchtigt, oder
                </p>
                <p>
                  zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <p><b>Recht auf Unterrichtung</b></p>
                <p>
                  Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
                  Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
                  verpflichtet, allen Empfängern, denen die Sie betreffenden
                  personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
                  Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
                  sei denn, dies erweist sich als unmöglich oder ist mit einem
                  unverhältnismäßigen Aufwand verbunden.
                </p>
                <p>
                  Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
                  Empfänger unterrichtet zu werden.
                </p>
                <p><b>Recht auf Datenübertragbarkeit</b></p>
                <p>
                  Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
                  Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
                  gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie
                  das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
                  durch den Verantwortlichen, dem die personenbezogenen Daten
                  bereitgestellt wurden, zu übermitteln, sofern
                </p>
                <p>
                  die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO
                  oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs.
                  1 lit. b DSGVO beruht und
                </p>
                <p>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
                <p>
                  In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
                  die Sie betreffenden personenbezogenen Daten direkt von einem
                  Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
                  soweit dies technisch machbar ist. Freiheiten und Rechte anderer
                  Personen dürfen hierdurch nicht beeinträchtigt werden.
                </p>
                <p>
                  Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
                  personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
                  erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
                  öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                </p>
                <p><b>Widerspruchsrecht</b></p>
                <p>
                  Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
                  Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
                  DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                  Bestimmungen gestütztes Profiling.
                </p>
                <p>
                  Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
                  Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe
                  für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
                  Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
                  Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <p>
                  Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
                  Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
                  gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                  Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
                  soweit es mit solcher Direktwerbung in Verbindung steht.
                </p>
                <p>
                  Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
                  werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese
                  Zwecke verarbeitet.
                </p>
                <p>
                  Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
                  der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG –
                  Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
                  denen technische Spezifikationen verwendet werden.
                </p>
                <p>
                  <b
                    >Recht auf Widerruf der datenschutzrechtlichen
                    Einwilligungserklärung</b
                  >
                </p>
                <p>
                  Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
                  jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
                  Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
                  Verarbeitung nicht berührt.
                </p>
                <p>
                  <b
                    >Automatisierte Entscheidung im Einzelfall einschließlich Profiling</b
                  >
                </p>
                <p>
                  Sie haben das Recht, nicht einer ausschließlich auf einer
                  automatisierten Verarbeitung - einschließlich Profiling – beruhenden
                  Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
                  Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
                  Dies gilt nicht, wenn die Entscheidung
                </p>
                <ol>
                  <li>
                    für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
                    dem Verantwortlichen erforderlich ist,
                  </li>
                  <li>
                    aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
                    denen der Verantwortliche unterliegt, zulässig ist und diese
                    Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
                    Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
                  </li>
                  <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
                </ol>
                <p></p>
                <p>
                  Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
                  personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
                  Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum
                  Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen
                  getroffen wurden.
                </p>
                <p>
                  Hinsichtlich der in 1. und 3. genannten Fälle trifft der Verantwortliche
                  angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre
                  berechtigten Interessen zu wahren, wozu mindestens das Recht auf
                  Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf
                  Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
                  gehört.
                </p>
                <p><b>Recht auf Beschwerde bei einer Aufsichtsbehörde</b></p>
                <p>
                  Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                  gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
                  einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                  Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
                  Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                  betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                </p>
                <p>
                  Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
                  unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
                  Beschwerde einschließlich der Möglichkeit eines gerichtlichen
                  Rechtsbehelfs nach Art. 78 DSGVO.
                </p>
              </div>
              <div v-if="sprachID === 1">
                <p>
                  Phone: +49 (0) 3672 313 0 <br />
                  E-Mail: info@stfg-rudolstadt.com <br />
                  Web: www.stfg-rudolstadt.com
                </p>
                <p>Legal Representative: Managing Director Roman Meytis</p>
                <p>
                  Court of Registry: district court (Amtsgericht) Jena <br />
                  Registration No: HRB 505299 <br />
                  VAT identification number: DE271530969
                </p>
                <p><b>Privacy policy</b></p>
                <p>
                  The operators of these pages take the protection of your personal data
                  very seriously. We treat your personal data confidentially and in
                  accordance with the statutory data protection regulations and the data
                  protection declaration at hand.
                </p>
                <p>
                  However, we point out that data transmission over the Internet (e.g.
                  communication by e-mail) can be subject to security gaps. Complete
                  protection of data against access by third parties is not possible.
                </p>
                <p><b>General information regarding data processing</b></p>
                <p><b>Processing scope of personal data</b></p>
                <p>
                  We process personal data of our users as far as this is necessary for
                  the provision of a functional website along with its content and
                  services. The processing of personal data of our users is carried out
                  only upon consent of those users. An exception applies in those cases
                  where obtaining prior consent is not possible and the processing of
                  corresponding data is permitted by legal regulations.
                </p>
                <p><b>Legal basis for the processing of personal data</b></p>
                <p>
                  In case we obtain the consent for processing operations regarding
                  personal data, Art. 6 (1) lit. a EU General Data Protection Regulation
                  (GDPR) serves as the legal basis. When processing personal data is
                  necessary in order to fulfill a contract, Art. 6 (1) lit. b GDPR serves
                  as the legal basis. This also applies to processing operations that are
                  necessary due to pre-contractual obligations.
                </p>
                <p>
                  In case processing of personal data is necessary to ensure compliance
                  with legal obligations, Art. 6 (1) lit. c GDPR serves as the legal
                  basis.
                </p>
                <p>
                  In the event that vital interests of the data subject or another natural
                  person require processing of personal data, Art. 6 (1) lit. d GDPR
                  serves as the legal basis.
                </p>
                <p>
                  In case processing of personal data is necessary due to a legitimate
                  interest of our company or a third party, Art. 6 (1) f GDPR serves as
                  the legal basis for the processing. Provided that the individual’s
                  interests or fundamental rights do not take precedence.
                </p>
                <p><b>Data deletion and storage period</b></p>
                <p>
                  Personal data of individuals will be deleted or restricted for
                  processing as soon as the purpose for collection ceases to apply.
                  Storage may continue in case this has been requested by European or
                  national legislation. Data will also be deleted or restricted for
                  processing if the mandatory storage period expires, unless there is a
                  need for further storage in order to fulfill contractual obligations.
                </p>
                <p><b>Provision of the website and creation of log files</b></p>
                <p><b>Description and scope of data processin</b>g</p>
                <p>Each time our website is visited, our system automatically collects data from the accessing computer</p>
                <p>The following data is collected in this process:</p>
                <p>
                  <ol>
                    <li>information about the browser type and the version used</li>
                    <li>the operating system of the user</li>
                    <li>the internet service provider of the user</li>
                    <li>the IP address of the user</li>
                    <li>date and time of access</li>
                    <li>websites from which the user's system accesses our website</li>
                    <li>websites that are accessed by the user's system via our website</li>
                  </ol>
                </p>
                <p>This data is also stored in the log files of our system. This data is not stored together with other personal data of the user.</p>
                <p><b>Legal basis for data processing</b></p>
                <p>The legal basis for the temporary storage of data and the log files is Art. 6 (1) lit. f GDPR.</p>
                <p><b>Purpose of the data processing</b></p>
                <p>The temporary storage of the IP address by the system is necessary to enable delivery of the website to the user's computer. For this purpose, the 
                  user's IP address must remain stored for the duration of the session.</p>
                <p>The storage in log files is done to ensure the functionality of the website. In addition, we use the data to optimize the website and to ensure the
                   security of our information technology systems. An evaluation of the data for marketing purposes does not take place in this context.</p>
                <p>These purposes are also our legitimate interest in data processing according to Art. 6 para. 1 lit. f GDPR.</p>
                <p><b>Duration of storage</b></p>
                <p>The data is deleted as soon as it is no longer required to achieve the purpose for which it was collected. In the case of the collection of data for
                   the provision of the website, this is the case when the respective session has ended.</p>
                <p>In the case of storage of data in log files, this is the case after seven days at the latest. Storage beyond this period is possible. In this case, 
                  the IP addresses of the users are deleted or alienated, so that an assignment of the client is no longer possible.</p>
                <p><b>Possibility of objection and removal</b></p>
                <p>The collection of data for the provision of the website and the storage of the data in log files is absolutely necessary for the operation of the 
                  website. Consequently, there is no possibility of objection by the user.</p>
                <p><b>Use of cookies</b></p>
                <p><b>a) Description and scope of data processing</b></p>
                <p>Our website uses cookies. Cookies are text files that are stored in the Internet browser or by the Internet browser on the user's computer system.
                   When a user visits a website, a cookie may be stored on the user's operating system. This cookie contains a characteristic string of characters that 
                   enables the browser to be uniquely identified when the website is visited again.</p>
                <p>We use cookies to make our website more user-friendly. Some elements of our website require that the visiting browser can be identified even after a page change.</p>
                <p>The following data is stored and transmitted in the cookies:</p>
                <p>
                  <ol>
                    <li>language settings</li>
                    <li>session identifier for each connection type</li>
                  </ol>
                </p>
                <p>We also use cookies on our website that enable an analysis of the user's surfing behavior.</p>
                <p>
                  <ol>
                    <li>frequency of page views.</li>
                  </ol></p>
                <p>The user data collected in this way is pseudonymized by technical precautions. Therefore, an assignment of the data to the visiting user 
                  is no longer possible. The data is not stored together with other personal data of the users.</p>
                <p><b>b) Legal basis for data processing</b></p>
                <p>The legal basis for the processing of personal data using cookies is Art. 6 (1) lit. f GDPR.</p>
                <p><b>c) Purpose of the data processing</b></p>
                <p>The purpose of using technically necessary cookies is to simplify the use of websites for users. Some functions of our website cannot be 
                  offered without the use of cookies. For these, it is necessary that the browser is recognized even after a page change.</p>
                <p>The user data collected by technically necessary cookies are not used to create user profiles.</p>
                <p>The cookies are used for the purpose of improving the quality of our website and its content. Through those cookies, we learn how the website 
                  is used and can thus constantly optimize related aspects.</p>
                <p>These purposes are also our legitimate interest in processing the personal data according to Art. 6 para. 1 lit. f GDPR.</p>
                <p><b>d) Duration of storage, possibility of objection and removal.</b></p>
                <p>Cookies are stored on the user's computer and transmitted to our site by the user. Therefore, you as a user also have full control over the
                   use of cookies. By changing the settings in your Internet browser, you can disable or restrict the transmission of cookies. Cookies that have already been
                    stored can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may no longer be possible to use all functions of the website in full.</p>
                <p><b>Rights of the data subject</b></p>
                <p>If your personal data is processed, you are a data subject within the meaning of the GDPR and you have the following rights against the responsible company:</p>

                <p><b>Right to information</b></p>
                <p>You may request confirmation from the responsible company as to whether personal data concerning you is being processed.</p>
                <p>If there is such processing, you may request information from the responsible company about the following:</p>
                <p>
                  <ol>
                    <li>the purposes for which the personal data are processed;</li>
                    <li>the categories of personal data which are processed;</li>
                    <li>the recipients or categories of recipients to whom the personal data concerning you have been or will be disclosed;</li>
                    <li>the planned duration of the storage of the personal data relating to you or, if specific information cannot be provided, criteria for determining the storage period;</li>
                    <li>the existence of a right to rectification or erasure of the personal data concerning you, a right to restriction of processing by the responsible company or a right to object to such processing;</li>
                    <li>the existence of a right of appeal to a supervisory authority;</li>
                    <li>any available information on the origin of the data, if the personal data is not collected from the data subject;</li>
                    <li>the existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, 
                      meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.</li>
                  </ol>
                </p>
                <p>You have the right to request information about whether the personal data concerning you is transferred to a third country or to an international 
                  organization. In this context, you may request to be informed about the appropriate safeguards pursuant to Art. 46 GDPR regarding the transfer.</p>
                <p><b>Right to rectification</b></p>
                <p>You have a right to rectification and/or completion, in case the personal data processed concerning you is inaccurate or incomplete. The responsible 
                  company shall carry out the rectification without undue delay.</p>
                <p><b>Right to restriction of processing</b></p>
                <p>You may request the restriction of the processing of personal data concerning you under the following conditions:</p>
                <p>
                  <ul>
                    <li>if you contest the accuracy of the personal data concerning you for a period enabling the responsible company to verify the accuracy of the personal data;</li>
                    <li>the processing is unlawful and you object to the erasure of the personal data and request instead the restriction of the use of the personal data;</li>
                    <li>the responsible company no longer needs the personal data for the purposes of processing, but you need them for the establishment, exercise or defense of legal claims; or</li>
                    <li>if you have objected to the processing pursuant to Article 21 (1) GDPR and it is not yet clear whether the legitimate interests of the responsible company outweigh your interests.
                      </li>
                  </ul>
                </p>
                <p>Where the processing of personal data concerning you has been restricted, such data may be processed, with the exception of storage, only with your 
                  consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for 
                  reasons of important public interest of the European Union or a Member State. If the restriction of processing has been in turn restricted in accordance 
                  with the above conditions, you will be informed by the responsible company before the restriction is lifted.</p>
               
               
                <p> <b>Right to deletion</b></p>
                <p>Obligation to delete</p>
                <p>You may request the responsible company to erase the personal data concerning you without undue delay, and the responsible company is obliged to erase 
                  such data without undue delay, if one of the following reasons applies:</p>
                <p>The personal data concerning you is no longer necessary for the purposes for which it was collected or otherwise processed.</p>
                <p>You revoke your consent on which the processing was based pursuant to Art. 6 (1) a or Art. 9 (2) a GDPR and there is no other legal basis for the processing.</p>
                <p>You object to the processing pursuant to Art. 21 (1) GDPR and there are no overriding legitimate interests for the processing, or you object to the processing pursuant to Art. 21 (2) GDPR.</p>
                <p>The personal data concerning you has been processed unlawfully.</p>
                <p>The erasure of the personal data concerning you is necessary for compliance with a legal obligation under European Union or Member State law to which 
                  the responsible company is subject.</p>
                <p>The personal data concerning you has been collected in relation to information society services pursuant to Article 8(1) of the GDPR.</p>
                <p>Information provided to third parties</p>
                <p>If the responsible company has made the personal data concerning you public and is obliged to erase it pursuant to Article 17(1) of the GDPR, it shall 
                  take reasonable steps, including technical measures, to inform data processing entities, which process your personal data, about the request for erasure.</p>
                <p>Exceptions</p>
                <p>The right to erasure does not exist as far as the processing is necessary</p>
                <p>for the exercise of the right to freedom of expression and information;</p>
                <p>for compliance with a legal obligation which requires processing under European Union or Member State law to which the responsible company is subject, 
                  or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the responsible company;</p>
                <p>for reasons of public interest in the area of public health pursuant to Art. 9(2)(h) and (i) and Art. 9(3) GDPR;</p>
                <p>for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes pursuant to Article 89(1) GDPR, as far as 
                  the right referred to in section a) is likely to prevent the achievement of those aforementioned purposes; or</p>
                <p>for the assertion, exercise or defense of legal claims.</p>
                <p><b>Right to notification </b></p>
                <p>If you have asserted the right to rectification, erasure or restriction of processing against the responsible company, the responsible company is obliged 
                  to inform all recipients to whom the personal data concerning you has been disclosed of this rectification or erasure of the data or restriction of 
                  processing, unless this proves impossible or involves a disproportionate effort.</p>
                <p>You have the right against the responsible company to be informed about these recipients.</p>
                <p><b>Right to data portability</b></p>
                <p>You have the right to receive the personal data concerning you that you have provided to the responsible company in a structured, commonly used 
                  and machine-readable format. In addition, you have the right to transfer this data to another processing entity without hindrance from the responsible 
                  company to whom the personal data was provided, under the condition that</p>
                <p>the processing is based on consent pursuant to Art. 6 (1) a GDPR or Art. 9 (2) a GDPR or on a contract pursuant to Art. 6 (1) b GDPR and</p>
                <p>the processing is carried out with the help of automated procedures.</p>
                <p>By exercising this right, you also have the right that the personal data concerning you is transferred directly from the responsible company to 
                  another processing entity, as far as this is technically feasible. Freedoms and rights of other individuals must not be affected by this.</p>
                <p>The right to data portability does not apply to processing of personal data necessary for the performance of a task carried out in the public 
                  interest or in the exercise of official authority vested in the responsible company.</p>
                <p><b>Right to objection</b></p>
                <p>You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning you which 
                  is carried out on the basis of Article 6(1)(e) or (f) GDPR; this also applies to profiling based on these provisions.</p>
                <p>The responsible company shall no longer process the personal data concerning you unless it can demonstrate compelling legitimate interests for 
                  the processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending legal claims.</p>
                <p>If the personal data concerning you is processed for the purposes of direct marketing, you have the right to object at any time to the processing 
                  of personal data concerning you for the purposes of such marketing; this also applies to profiling, as far as it is related to such direct marketing.</p>
                <p>If you object to the processing for direct marketing purposes, the personal data concerning you will no longer be processed for these purposes.</p>
                <p>You have the possibility, in connection with the use of information society services - notwithstanding Directive 2002/58/EC - to exercise your 
                  right to object by means of automated procedures using technical specifications.</p>
                <p><b>Right to revoke the declaration of consent under data protection law</b></p>
                <p>You have the right to revoke your declaration of consent under data protection law at any time. The revocation of consent does not affect the 
                  lawfulness of the processing carried out on the basis of the consent until the revocation.</p>
                <p><b>Automated decision in individual cases including profiling</b></p>
                <p>You have the right not to be subject to a decision based solely on automated processing - including profiling - which produces legal effects 
                  concerning you or affects you in a similarly significant way. This does not apply if the decision</p>
                <p>
                  <ol>
                    <li>is necessary for the conclusion or performance of a contract between you and the responsible company,</li>
                    <li>is permissible on the basis of legal provisions of the European Union or the Member States to which the responsible company is subject and 
                      these legal provisions contain appropriate measures to protect your rights and freedoms as well as your legitimate interests, or</li>
                    <li>is made with your consent.</li>
                  </ol>
                </p>
                <p>However, these decisions may not be based on special categories of personal data pursuant to Article 9(1) of the GDPR, unless Article 9(2)(a) or 
                  (g) of the GDPR applies and appropriate measures have been taken to protect the rights and freedoms as well as your legitimate interests.</p>
                <p>With regard to the cases mentioned in 1. and 3. above, the responsible company shall take reasonable steps to safeguard the rights and freedoms 
                  as well as your legitimate interests, which include at least the right to obtain the intervention of a person, to express his or her point of view and to contest the decision.</p>
                <p><b>Right to complaint towards a supervisory authority</b></p>
                <p>Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, 
                  in particular in the Member State of your residence, workplace or the place of the alleged infringement, if you consider that the processing of
                   personal data concerning you infringes the GDPR.</p>
                <p>The supervisory authority to which the complaint has been lodged shall inform the complainant of the status and outcome of the complaint, 
                  including the possibility of a judicial remedy under Article 78 GDPR.</p>
                <p></p>
                
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
    };
  },
  components: {},
  methods: {},

  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
