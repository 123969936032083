<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid">
          <div class="row m-0 pb-5 mb-0 justify-content-center" id="top">
            <div class="col-10">
              <div class="BereichUeberschrift">
                <i class="fas fa-landmark footerIcon2 BereichUeberschriftPicto"></i>
              
                <span>Terms & Conditions</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6 ">
              <h1 class="titel text-center BereichUeberschrift m-4 text-uppercase textBlau">
                General Terms and Conditions <br/> of STFG Filamente GmbH
              </h1>
              <h3 class="titel text-center pb-5">dated 03.06.2024</h3>
              <p>
                If a quality assurance agreement has been signed with customers
                or suppliers, regulations from this specific QAA must be
                applied.
              </p>
              <p>
                The paragraphs below include terms and conditions regarding
                procurement, sales and deliveries.
              </p>
              <article class="pt-5">
                <h3>
                  Terms and Conditions for Purchase of Goods and/ or Services
                </h3>
                <h4>§ 1 General</h4>
                <p>
                  The following terms and conditions must be applied exclusively
                  to all deliveries and services provided by the supplier. STFG
                  does not acknowledge the supplier's general terms and
                  conditions, unless STFG has agreed to their validity in
                  writing. Unconditional acceptance of goods and services or
                  corresponding payments, despite knowledge of conflicting or
                  deviating terms and conditions of the supplier, do not
                  constitute agreement to those regulations by STFG.
                </p>
                <h4>§ 2 Orders/ Order confirmations</h4>
                <p>
                  STFG’s orders require written form or text form. Text form is
                  understood as transmission by e-mail, whereby the
                  issuing company and the issuing person must be clearly
                  identifiable. Orders are valid without a handwritten
                  signature.
                </p>
                <p>
                  The supplier is obliged to accept the order in the same form
                  or, in the case of another agreed form, in the agreed form
                  within a period of two weeks. After expiry of this period,
                  STFG is entitled to revoke the order.
                </p>
                <p>
                  All conditions, specifications, standards and other documents
                  listed in the order or as annexes are part of the order.
                </p>
                <h4>§ 3 Changes to the delivery item</h4>
                <p>
                  If STFG requests a change to the delivery item, the supplier
                  must inform STFG immediately in writing of any additional or
                  reduced charges as well as effects on deadlines and provide
                  evidence of these.
                </p>
                <h4>§ 4 Force majeure</h4>
                <p>
                  Interruptions in production due to unavoidable events (force
                  majeure, e.g. labour dispute) entitle STFG to withdraw orders.
                  Furthermore, in the event of delayed acceptance beyond the
                  responsibility of STFG, delivery and payment dates must be
                  extended in accordance with the duration of the delay.
                </p>
                <h4>§ 5 Delivery time</h4>
                <p>
                  Agreed delivery dates and deadlines are binding. If they are
                  exceeded for reasons of which the supplier is responsible, the
                  supplier will be in default without further announcement. The
                  supplier must inform STFG immediately of any foreseeable
                  delays in delivery. In the event of a delay in delivery, STFG
                  will be entitled to statutory claims, in particular to
                  compensation for any damage incurred as a result of the delay.
                  Additional costs, in particular in the case of necessary
                  covering purchases, must be borne by the supplier. The
                  unconditional acceptance of the delayed delivery does not
                  constitute a waiver of claims for compensation.
                </p>
                <h4>§ 6 Invoice and payment</h4>
                <p>
                  The supplier must submit an invoice for each delivery or
                  service, separated from the consignment. The invoice must
                  correspond in wording to the order designations and contain
                  STFG’s order number. Invoices that do not contain this
                  information will be returned and do not constitute a due date.
                  The period for payment of the invoice will commence on the
                  working day following receipt of a proper and verifiable
                  invoice or acceptance of the goods or service - whichever is
                  the later. The settlement of payment will be without prejudice
                  to STFG’s right to assert complaints at a later date. In the
                  event of early acceptance of the delivery items, the payment
                  period will commence from the delivery date in accordance with
                  the order or from receipt of the invoice - whichever is the
                  later. In the case of contracts for work and services or
                  contractually agreed acceptance, the payment period will not
                  commence before acceptance.
                </p>
                <p>
                  In the event of defective delivery, STFG is entitled to
                  withhold payment until proper fulfilment, without loss of
                  discounts and similar payment concessions.
                </p>
                <h4>§ 7 Delivery and acceptance</h4>
                <ol>
                  <li>
                    If it becomes apparent that agreements, made with regards to
                    quality characteristics, deadlines or delivery quantities,
                    cannot be complied with, the supplier must inform STFG of
                    this condition without delay. The supplier must also inform
                    STFG immediately of any deviations detected after delivery.
                    In the interest of a quick resolution, the supplier must
                    disclose all required data and facts.
                  </li>
                  <li>
                    The supplier is obliged to label all units intended for
                    delivery in accordance with the order conditions to ensure
                    clear identification. A reference to the corresponding order
                    must be possible at all times.
                  </li>
                  <li>
                    The type of packaging must be in accordance with
                    product-specific requirements. In particular, it must be
                    selected in such a way that the delivery item is suitably
                    protected against the foreseeable, usual stresses and
                    environmental influences during transport or interim
                    storage.
                  </li>
                </ol>
                <p>
                  If the aforementioned requirements are not met by the
                  supplier, STFG will accept the goods with reservation and
                  reserves the right to take further measures (clarification
                  with the supplier/retrieval by the supplier/price reduction in
                  the event of a reduction in the quality of the goods due to
                  damaged packaging, etc.).
                </p>
                <h4>§ 8 Product regulation/ requalification tests</h4>
                <p>
                  Specific conditions for the products falling within the scope
                  of REACH (EC 1907/2006) and CLP (EC 1272/2008): All orders are
                  placed on condition that the following agreements are
                  unambiguously in place between STFG and the supplier:
                </p>
                <ol>
                  <li>
                    Substances that fall under the regulations of the REACH (EC
                    1907/2006) have been correctly registered by the supplier.
                  </li>
                  <li>
                    The packaging of the delivered goods is labelled in
                    accordance with the Regulation CLP (EC 1272/2008).
                  </li>
                  <li>
                    STFG reserves the right to return substances and/or
                    preparations to the supplier that do not meet these
                    conditions and therefore may not be marketed or sold in the
                    EU or must be withdrawn from the market due to
                    non-compliance with REACH and CLP obligations. Corresponding
                    expenses must be borne by the supplier, including all costs
                    for STFG associated with the non-compliance (reimbursement
                    of the purchase price, transport, damage claims).
                  </li>
                </ol>
                <p>
                  STFG has the right to conduct a query twice a year regarding
                  the following conformities: RoHS, California Prop 65, REACH,
                  Conflict Minerals, POP's, TSCA and German/ EU supply chain legislation. The supplier must answer these
                  within 2 weeks and grant STFG access to the relevant evidence
                  on request.
                </p>
                <h4>§ 9 Minimum wage</h4>
                <p>
                  For our orders regarding services or work performances within
                  Germany, the supplier complies with the provisions of the
                  Minimum Wage Act ("Gesetz zur Regelung des allgemeinen
                  Mindestlohns" of 11 August 2014, as amended).
                </p>
                <h3>Terms and Conditions for Sales and Deliveries</h3>
                <h4>§ 1 General</h4>
                <p>
                  The following terms and conditions regarding sales and
                  deliveries must be applied exclusively to all deliveries and
                  services of STFG. STFG does not acknowledge the buyer's
                  general terms and conditions unless STFG has agreed to their
                  validity in writing. This regulation will also apply if STFG
                  performs deliveries and provides invoices unconditionally,
                  despite the knowledge of conflicting or deviating terms and
                  conditions of the buyer.
                </p>
                <h4>§ 2 Conclusion of contract</h4>
                <p>
                  STFG’s offers are always subject to change. Orders and verbal
                  agreements will only be binding if STFG acknowledges them in
                  the form of an order confirmation or complies with them by
                  sending the goods or the invoice.
                </p>
                <h4>
                  § 3 Place of performance, prices, delivery and acceptance
                </h4>
                <ol>
                  <li>
                    <h4>Place of performance</h4>
                    <p>
                      The place of performance for all services arising from the
                      delivery contract is the registered office of STFG.
                    </p>
                  </li>
                  <li>
                    <h4>Prices</h4>
                    <p>
                      Unless stated otherwise in writing, prices apply ex works
                      of the respective production location. Prices do not
                      include value added tax. Confirmed prices only apply upon
                      acceptance of the agreed quantities. STFG is entitled to
                      adjust the sales prices in the event of price and cost
                      increases, changes to freight, customs duties, taxes,
                      levies and similar alterations, occurring after signing
                      the contract. All information such as dimensions, weights,
                      quality, quality assurance, illustrations, color
                      specifications, samples, descriptions, etc. in offers,
                      sample books, price lists, brochures and other printed
                      material are determined to the best of STFG’s ability, but
                      are only approximates and stated without guarantee for the
                      correctness of this information.
                    </p>
                  </li>
                  <li>
                    <h4>Delivery</h4>
                    <p>
                      STFG reserves the right to carefully select the shipping
                      route or the means of transport. If a different mode of
                      transport is permitted at the request of the buyer, the
                      additional costs must be borne by the buyer and may be
                      collected by cash on delivery. Delivery to the shipping
                      address or place of use presupposes unobstructed,
                      reasonable access. The time of delivery and transfer of
                      risk are deemed to be the time at which the goods are
                      handed over to a forwarding agent, a carrier or any other
                      person or institution designated to carry out the shipment
                      or the time when the goods are made available to the buyer
                      as ready for shipment. Compliance with the delivery period
                      is subject to the fulfilment of the customer's contractual
                      obligations. INCOTERMS in their up-to-date version apply. Partial deliveries are permissible. If STFG
                      is in default with a partial delivery or partial
                      performance, the customer is only entitled to withdraw
                      from the contract if the already received partial
                      performance of the contract is not usable for him and
                      conditions of § 5, 1. are fulfilled. If early partial
                      deliveries are arranged at the request of the buyer, any
                      additional costs incurred must be borne by the buyer. The
                      goods will be packaged by STFG as customary in the
                      industry.
                    </p>
                  </li>
                  <li>
                    <h4>Acceptance</h4>
                    <p>
                      If, due to the fault of the buyer, the acceptance of a
                      contractually agreed delivery does not take place on time,
                      STFG has the right, at her discretion, after the expiry of
                      a grace period of 15 calendar days, to either invoice the
                      goods with immediate due date, taking into account any
                      price increase that may have occurred since signing the
                      contract, or to withdraw from the contract or to claim
                      damages.
                    </p>
                  </li>
                </ol>
                <h4>§ 4 Content of the contract</h4>
                <p>
                  The goods are delivered on specific dates (defined working day
                  or specific calendar week). All contracts refer to specific
                  quantities, articles, qualities and fixed prices. Both parties
                  are bound by these.
                </p>
                <h4>§ 5 Delivery disruptions</h4>
                <ol>
                  <li>
                    STFG will comply with agreed delivery periods and dates as
                    far as possible. Delay in delivery does not entitle the
                    buyer to withdraw from the contract and/or to claim damages.
                    However, if the delivery has not been made within a period
                    of grace of at least 8 weeks to be set by the buyer, the
                    buyer is entitled to withdraw from the contract as long as
                    the delivery has not yet been made.
                  </li>
                  <li>
                    Prior to the expiry of the grace period, claims of the buyer
                    due to delayed delivery are not applicable.
                  </li>
                  <li>Consequential damages are excluded in any case.</li>
                  <li>
                    Force majeure, operational disruptions, shortages of raw
                    materials, energy or labour, failure of upstream suppliers
                    to meet deadlines, strikes, lockouts, shipping difficulties,
                    acts of government, political unrest and similar unforeseen
                    obstacles will release STFG from the obligation to deliver
                    for the duration of the disruption and the extent of the
                    effect. If delivery or performance becomes impossible due to
                    circumstances of the aforementioned kind, STFG must be
                    released from her obligations. If delivery is delayed by
                    more than two months, STFG and the buyer will have the right
                    to withdraw from the contract with regard to the quantity
                    affected by the disruption in delivery, as long as the goods
                    have not yet been produced. There must be no other claims.
                  </li>
                </ol>
                <h4>§ 6 Warranty and compensation</h4>
                <ol>
                  <li>
                    Warranty is only given for goods of first grade quality. For
                    such goods, STFG only guarantees that they meet STFG’s
                    general quality standards. In case of agreements regarding
                    inferior qualities, e.g. in case of sale of off-spec
                    material (lumps and strands, sweepings, etc.), any warranty
                    is excluded.
                  </li>
                  <li>
                    The buyer must check immediately, by means of trial
                    processing if necessary, whether the delivered goods are
                    flawless and suitable for the intended use. If the buyer
                    fails to carry out this inspection, the warranty will not
                    apply. Notification of claims or other complaints must be
                    made in writing without delay, but at the latest within 30
                    days of receipt of the goods, by sending in supporting
                    documents, samples, packing slips, packaging etc. and
                    stating all order and delivery data. In the case of hidden
                    defects, the complaint must be made immediately after
                    discovery, but at the latest within two months after receipt
                    of the goods. The buyer must bear the burden of proof that
                    the defect is hidden. If a defect is detected, further
                    processing of the material must be stopped and the
                    possibility of replacement delivery must be granted within
                    the scope of optimal damage settlement after consultation
                    with STFG.
                  </li>
                  <li>
                    Claims due to obvious defects expire in case the detected
                    defect has not been reported to STFG and the delivered goods
                    have been subjected to any form of treatment or processing.
                    Claims for hidden defects will expire for the goods which
                    the buyer, after having discovered or should have discovered
                    the hidden defect, processes in any form or resells the
                    processed goods. However, in the event that the goods in
                    question were treated or processed in order to avert major
                    damage that would otherwise have occurred and with prior written
                    consent by STFG, the buyer may assert the rights resulting
                    from the timely notice of the defect.
                  </li>
                  <li>
                    In the case of justified complaints made within the time
                    limit, STFG will, at her discretion, conduct a replacement
                    delivery (provided the goods in question have been returned
                    to STFG in their original condition) or grant a reduction of
                    the purchase price in accordance with the reduced value of
                    the delivered goods. Return deliveries of any kind must be
                    made in accordance with STFG's instructions. Claims for
                    damages, redhibitory action and withdrawal from the contract
                    are excluded. If the buyer proves that, through no fault of
                    his own, he is no longer in a position to return the
                    rejected goods in their original condition as a result of
                    treatment or processing, the reduction in the purchase price
                    will be applied to the treated or processed part of the
                    defective delivery.
                  </li>
                  <li>
                    Due payments may neither be retained nor set off against any
                    damage or claims for damage by third parties.
                  </li>
                  <li>
                    No warranty is given for damages caused by unsuitable or
                    improper use, faulty processing, natural wear and tear or
                    excessive stress. Furthermore, no warranty is given for
                    damages caused to the delivery item by the influence of
                    third parties.
                  </li>
                </ol>
                <h4>§ 7 Withdrawal and cancellation</h4>
                <p>
                  A return of goods provided on special order of the customer,
                  without a corresponding legal obligation, is excluded. If STFG
                  agrees to a cancellation of the contract before delivery
                  without a legal obligation, a reasonable compensation for
                  expenses must be paid by the buyer.
                </p>
                <h4>§ 8 Payment</h4>
                <ol>
                  <li>
                    Payment must be completed within 30 calendar days from the
                    date of invoice without any deduction, unless otherwise
                    agreed and stated in the invoice. The basis for calculating
                    any discounts must be the value of the goods
                  </li>
                  <li>
                    Payments will always be used to settle the oldest debt items
                    plus any accrued interest on arrears.
                  </li>
                  <li>
                    STFG's claim and its due date will remain unaffected until
                    cheques and bills of exchange or payment from the assignment
                    of claims have been honoured. Discount, protest and
                    collection charges for bills of exchange must be borne by
                    the customer.
                  </li>
                  <li>
                    Offsetting with counterclaims is excluded unless the
                    counterclaim is set off against a claim acknowledged by STFG
                    or established by declaratory judgment.
                  </li>
                  <li>
                    If the buyer is more than one week in arrears with a due
                    payment, all claims arising from the
                    business relationship will become due immediately. In the
                    event of late payment, interest of 5% p.a. above the
                    respective rate of Deutsche Bundesbank - but at least 8%
                    p.a. – must be paid on top of the open amount, in addition
                    to the claim of further rights. All disadvantageous
                    consequences accruing to STFG from this default must be
                    borne by the buyer. The right to assert a higher damage
                    caused by default is reserved.
                  </li>
                  <li>
                    Default in payment, justified doubts about the solvency or
                    willingness to pay, deteriorating financial circumstances of
                    the buyer according to information from the bank or the
                    trade credit insurer of STFG entitle STFG to withhold
                    further deliveries until the payment owed, including
                    interest, has been performed in full. If the Buyer fails to
                    pay a due invoice despite a reminder within a reasonable
                    period of time (to be set with the reminder), STFG will be
                    entitled to withdraw from the contract - without prejudice
                    to the rights otherwise resulting from the default - without
                    the need to set further deadlines. In the case of deliveries
                    and partial deliveries, STFG will also be entitled to demand
                    advanced payments or sufficient securities for the open
                    partial deliveries. Furthermore, STFG is entitled to take
                    possession of goods delivered under retention of title
                    without automatically exercising the right to withdraw from
                    the contract.
                  </li>
                </ol>
                <h4>§ 9 Retention of title</h4>
                <ol>
                  <li>
                    STFG retains title to the delivered goods until the buyer
                    has fulfilled all obligations arising from the mutual
                    business relations, including settlement of a current
                    account balance. 
                  </li>
                  <li>
                    STFG's ownership also extends to the goods not belonging to
                    STFG as a result of processing, combining or mixing. The
                    buyer is obliged to clearly mark the goods belonging to STFG
                    or goods and products to which she is entitled as co-owner,
                    to keep them free of harm and to insure them against fire,
                    theft and other damage. The buyer is not entitled to pledge
                    these goods and products or to assign them as security. The
                    buyer must immediately notify STFG of any seizure or other
                    access by third parties affecting STFG's property or rights.
                  </li>
                  <li>
                    All claims arising
                    from the sale of goods subject to retention of title for
                    current or future deliveries must be assigned by the buyer
                    to STFG here and now as security for the claims based on
                    clauses 1 and 2. In the event of the sale of goods in which
                    STFG has co-ownership pursuant to clause 2, the assignment
                    will be limited to the share of the claim corresponding to
                    the share of co-ownership; the same applies if goods subject
                    to retention of title are sold together with other items at
                    a total price.
                  </li>
                  <li>
                    As long as the buyer properly fulfils his obligations
                    towards STFG, in particular is not in default, he may
                    process the goods subject to retention of title and dispose
                    of them in the ordinary course of business as well as
                    collect the claims assigned to STFG himself. In the event of
                    sale, the buyer must transfer ownership of the delivered
                    goods or the products manufactured therefrom dependent on
                    payment of the purchase price claim. The buyer will have to
                    assert the rights resulting from this retention of title in
                    favour of STFG if the buyer’s customer is in default of
                    payment.
                  </li>
                  <li>
                    If the realisation of the STFG's claims appears to be at
                    risk, in particular if the buyer is in default of payment,
                    the buyer must, upon STFG's request, disclose the
                    inventories of goods subject to retention of title,
                    including the products manufactured therefrom, as well as
                    the open claims from the sale of goods subject to retention
                    of title and must provide all necessary information and
                    surrender corresponding documents.
                  </li>
                  <li>
                    Pledges and transfers in terms of security pertaining to
                    goods reserved by STFG are not permitted. Measures by third
                    parties which endanger the rights of STFG must be indicated
                    to STFG in writing without delay. In the event of seizure,
                    the customer must send a copy of the seizure report to STFG
                    immediately. The customer must immediately notify STFG in
                    writing of any impairment of the STFG's rights by third
                    parties. Intervention costs must be borne by the buyer.
                  </li>
                </ol>
                <h3>Place of jurisdiction</h3>
                <p>
                  The place of performance and jurisdiction for all claims
                  arising from the contractual relationship is Rudolstadt.
                </p>
                <h3>Other</h3>
                <ol>
                  <li>
                    The law of the Federal Republic of Germany applies to
                    contractual relationships with STFG.
                  </li>
                  <li>
                    The above terms and conditions and the contract remain
                    legally binding in their remaining parts even if individual
                    clauses are legally invalid.
                  </li>
                </ol>
              </article>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
