<template>
  <Layout>
<DSE/>
    
    
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import DSE from "../pages/Datenschutztext2.vue";

export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    DSE,
  },
  methods: {},
  
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
