<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 pb-4">
        <div class="row m-0 pb-5 mb-0 justify-content-center" id="top">
          <div class="col-10">
            <div class="BereichUeberschrift">
              <i class="far fa-envelope-open BereichUeberschriftPicto"></i>
              <span v-if="sprachID === 0">Kontakt </span>
              <span v-if="sprachID === 1">Contact </span>
            </div>
          </div>
        </div>
        <div class="row m-0 justify-content-center m-2">
          <div class="col-8">
            <img src="../assets/letter.jpg" class="card-img-top" alt="..." />
          </div>
        </div>
        <div class="row m-0">
          <div class="col-12">
            <div class="title pt-4">
              <h5 style="text-align: center">
                <span v-if="sprachID === 0">Wir freuen uns auf Ihre Nachricht</span>
                <span v-if="sprachID === 1">We are looking forward to your message</span>
              </h5>
            </div>
          </div>
        </div>
        <div class="row m-0 justify-content-center mt-5">
          <div class="col-md-8">
            <div class="titleKlein">
              <h5>
                <span v-if="sprachID === 0">Persönliches</span>
                <span v-if="sprachID === 1">Personal Information</span>
              </h5>
            </div>
          </div>
        </div>

        <div class="row m-0 mb-3 justify-content-center">
          <div class="col-md-4">
            <label for="anrede" class="form-label">
              <span v-if="sprachID === 0">Anrede</span>
              <span v-if="sprachID === 1">Title</span></label>
            <select class="form-select" aria-label="Default select example" v-model="kontaktschreiben.Anrede">
              <option value="ohne" selected>
                <span v-if="sprachID === 0">Bitte auswählen</span>
                <span v-if="sprachID === 1">Please select</span>
              </option>
              <option value="Frau">
                <span v-if="sprachID === 0">Frau</span>
                <span v-if="sprachID === 1">Mrs.</span>
              </option>
              <option value="Herr">
                <span v-if="sprachID === 0">Herr</span>
                <span v-if="sprachID === 1">Mr.</span>
              </option>
              <option value="Neutrale Anrede">
                <span v-if="sprachID === 0">Neutrale Anrede</span>
                <span v-if="sprachID === 1">Neutral salutation</span>
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="titel" class="form-label">
              <span v-if="sprachID === 0">Firma</span>
              <span v-if="sprachID === 1">Company</span></label>
            <input type="text" class="form-control" id="titel" v-model="kontaktschreiben.Firma" />
          </div>
        </div>
        <div class="row m-0 mb-3 justify-content-center">
          <div class="col-md-4">
            <label for="vname" class="form-label">
              <span v-if="sprachID === 0">Vorname*</span>
              <span v-if="sprachID === 1">First name</span></label>
            <input type="text" class="form-control" id="vname" v-model="kontaktschreiben.Vorname" 
              v-bind:class="[error.Vorname == true ? 'bg-warning' : '']" />
            <label class="form-check-label inputerror" v-if="error.Vorname == true">
              {{ error.VornameText }}
            </label>
          </div>
          <div class="col-md-4">
            <label for="nname" class="form-label">
              <span v-if="sprachID === 0">Nachname*</span>
              <span v-if="sprachID === 1">Name*</span></label>
            <input type="text" class="form-control" id="nname" v-model="kontaktschreiben.Nachname"
              v-bind:class="[error.Nachname == true ? 'bg-warning' : '']" />
            <label class="form-check-label inputerror" v-if="error.Nachname == true">
              {{ error.NachnameText }}
            </label>
          </div>
        </div>
        <div class="row m-0 mb-3 justify-content-center">
          <div class="col-md-4">
            <label for="email" class="form-label">
              <span v-if="sprachID === 0">E-Mail*</span>
              <span v-if="sprachID === 1">E-Mail*</span></label>
            <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
              v-model="kontaktschreiben.Email" v-bind:class="[error.Email == true ? 'bg-warning' : '']" />
            <label class="form-check-label inputerror" v-if="error.Email == true">
              {{ error.EmailText }}
            </label>
          </div>
          <div class="col-md-4">
            <label for="telefon" class="form-label">
              <span v-if="sprachID === 0">Telefon</span>
              <span v-if="sprachID === 1">Phone</span></label>
            <input type="text" class="form-control" id="telefon" v-model="kontaktschreiben.Telefon" />
          </div>
        </div>
        <div class="row m-0 mb-3 justify-content-center">
          <div class="col-md-4">
            <label for="strasse" class="form-label">
              <span v-if="sprachID === 0">Straße</span>
              <span v-if="sprachID === 1">Street</span></label>
            <input type="text" class="form-control" id="strasse" v-model="kontaktschreiben.Strasse" />
          </div>
          <div class="col-md-4">
            <label for="hausnummer" class="form-label">
              <span v-if="sprachID === 0">Hausnummer</span>
              <span v-if="sprachID === 1">Number</span></label>
            <input type="text" class="form-control" id="hausnummer" v-model="kontaktschreiben.Hausnummer" />
          </div>
        </div>

        <div class="row m-0 mb-3 justify-content-center">
          <div class="col-md-4">
            <label for="plz" class="form-label">
              <span v-if="sprachID === 0">Postleitzahl</span>
              <span v-if="sprachID === 1">Zip Code </span></label>
            <input type="text" class="form-control" id="plz" v-model="kontaktschreiben.PLZ" />
          </div>
          <div class="col-md-4">
            <label for="stadt" class="form-label">
              <span v-if="sprachID === 0">Stadt</span>
              <span v-if="sprachID === 1">City</span></label>
            <input type="text" class="form-control" id="stadt" v-model="kontaktschreiben.Stadt" />
          </div>
        </div>

        <div class="row m-0 justify-content-center">
          <div class="col-md-8">
            <label class="form-label" for="message">
              <span v-if="sprachID === 0">Mitteilung</span>
              <span v-if="sprachID === 1">Message</span></label>
            <textarea class="form-control" id="message" type="text" placeholder="" style="height: 10rem"
              v-model="kontaktschreiben.message"></textarea>
          </div>
        </div>

        <div class="row m-0 justify-content-center pt-4 pb-4">
          <div class="col-md-4">
            <h5>
              <span v-if="sprachID === 0">Datenschutzerklärung</span>
              <span v-if="sprachID === 1">Privacy Policy</span>
            </h5>
            <input class="form-check-input" type="checkbox" value="" id="andere" v-model="kontaktschreiben.Datenschutz"
              v-bind:class="[
                error.Datenschutz == true ? 'bg-warning' : '',
              ]" />

            <span v-if="sprachID === 0">
              <label class="form-check-label" for="andere">&nbsp; Ich habe die           

                <span class="dse" @click="openDSE()">Datenschutzerklärung</span>

                zur Kenntnis genommen.
              </label>
              <label class="form-check-label inputerror" v-if="error.Datenschutz == true">
                {{ error.DatenschutzText }}
              </label>
            </span>
            
            <span v-if="sprachID === 1">
              <label class="form-check-label" for="andere">&nbsp; I have read the
                
                <span class="dse" @click="openDSE()">Privacy Policy</span>         
                .
              </label>
              <label class="form-check-label inputerror" v-if="error.Datenschutz == true">
                {{ error.DatenschutzText }}
              </label>
            </span>
            <div class="pt-5">
             <p> {{ mail.gesendet }}</p>
              <span v-if="sprachID === 0">
                <input class="formularButtonsGruen ps-5 pe-5" type="submit" value="Senden" @click="checkForm()" /></span>
              <span v-if="sprachID === 1">
                <input class="formularButtonsGruen" type="submit" value="Submit" @click="checkForm()" /></span>
                
            </div>
          </div>
          <div class="col-md-4">
            <span v-if="sprachID === 0">
              <p>
                <b>STFG Filamente GmbH</b>
              </p>
              <p>
                Breitscheidstrasse 135<br />
                07407 Rudolstadt<br />
              <table>
                <tr>
                  <td>Tel.:</td>

                  <td>+49 3672 313 - 0</td>
                </tr>
                <tr>
                  <td>E-Mail: &nbsp; &nbsp; </td>
                  <td><a href="mailto:info@stfg-rudolstadt.com" class="">info@stfg-rudolstadt.com</a></td>
                </tr>

              </table>
              </p>
            </span>
            <span v-if="sprachID === 1">
              <div>
                
                <p>
                  <b>STFG Filamente GmbH</b>
                </p>
                <p>
                  Breitscheidstrasse 135<br />
                  07407 Rudolstadt, Germany <br />
                  Phone:
                  +49 3672 313 - 0<br />
                  E-Mail:
                  <a href="mailto:info@stfg-rudolstadt.com" class="">info@stfg-rudolstadt.com</a><br />
                </p>
                
              </div>
            </span>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>


function validEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
import Layout from "@/layouts/Layout";
import axios from "axios";
import { openModal } from "jenesius-vue-modal";
import modalDSE from "../components/Modal_DSE.vue";

export default {
  name: "AktuellesStell",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
      kontaktschreiben: {
        Anrede: "",
        Firma: "",
        Vorname: "",
        Nachname: "",
        Email: "",
        Telefon: "",
        Strasse: "",
        Hausnummer: "",
        PLZ: "",
        Stadt: "",
        message: "",

        Datenschutz: false,
      },
      error: {
        Nachname: false,
        Vorname:false,
        Email: false,
        Datenschutz: false,
        message: false,
        NachnameText: "",
        VornameText: "",
        EmailText: "",
        messageText: "",
        DatenschutzText: "",
      },
      mail: {
        gesendet: "",
      },
      errors: 0,
    };
  },
  components: {
    Layout,
  },
  methods: {
    async openDSE() {
    
      let modal = await openModal(modalDSE);
      modal.onclose = () => {
        
        return true;
      };
    },
    checkForm: function () {
      this.errors = 0;

      if (this.kontaktschreiben.Vorname == "") {
        this.error.Vorname = true;
        if(this.sprachID === 0)
        {
          this.error.VornameText = "Bitte tragen Sie einen Vorname ein";
        }
        else
        {
          this.error.VornameText = "Please enter a first name";
        }
        this.errors = 1;
      } else {
        this.error.Vorname = false;
      }

      if (this.kontaktschreiben.Nachname == "") {
        this.error.Nachname = true;
        if(this.sprachID === 0)
        {
          this.error.NachnameText = "Bitte tragen Sie einen Nachnamen ein";
        }
        else
        {
          this.error.NachnameText = "Please enter a last name";
        }
        this.errors = 1;
      } else {
        this.error.Nachname = false;
      }
      if (this.kontaktschreiben.Email == "") {
        this.error.Email = true;
        if(this.sprachID === 0)
        {
          this.error.EmailText = "Bitte tragen Sie eine E-Mail Adresse ein";
        }
        else
        {
          this.error.EmailText = "Please enter an email address";
        }
        this.errors = 1;
      } else {
        this.error.Email = false;
      }

      if (this.kontaktschreiben.Datenschutz == false) {
        this.error.Datenschutz = true;       
        if(this.sprachID === 0)
        {
          this.error.DatenschutzText = "Bitte Bitte bestätigen Sie den Datenschutz";
        }
        else
        {
          this.error.DatenschutzText = "Please confirm that you have read the privacy policy";
        }
        this.errors = 1;
      } else {
        this.error.Datenschutz = false;
      }

      if (validEmail(this.kontaktschreiben.Email) == false) {
        this.error.Email = true;
        if(this.sprachID === 0)
        {
          this.error.EmailText = "Bitte tragen Sie eine E-Mail Adresse ein";
        }
        else
        {
          this.error.EmailText = "Please enter an email address";
        }
        this.errors = 1;
      } else {
        this.error.Email = false;
      }

      console.log(this.errors);
      if (this.errors == 0) {
        console.log("keine Fehler");
        this.sendmail();
        return true;
      }
    },

    sendmail: function () {

      const axiosConfig = {
        headers: {
          Accept: "text/plain",
          "Content-Type": "text/plain",
        },
      };
      let payload = this.kontaktschreiben;
    
     payload.empfaenger = "info@stfg-rudolstadt.com";    
      payload.Grund = "Kontaktformular";
      payload.sender='Webseite@stfg.com';
      

      let mail = this.mail;
      let sendok=false;
      axios
        .post(
          "https://pillar.de/mailrelay/post_attachment.php",
          payload,
          axiosConfig
        )
        .then((response) => {
          console.log("success", response.data);
         
        if(this.sprachID === 0)
        {
          mail.gesendet = "Wir bedanken uns für Ihre Informationen. Ihre Kontaktdaten wurden erfolgreich versendet.";
        }
        else
        {
          mail.gesendet = "Thank you for your information. Your contact details have been sent successfully.";
        }
            console.log(sendok);
          sendok=true;
          console.log(sendok);
        })
        .catch((error) => {
          console.log(error);
          if(sendok==false)
         { 
         
          
        if(this.sprachID === 0)
        {
          mail.gesendet = "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
        }
        else
        {
          mail.gesendet = "Unfortunately, your contact details could not be sent. Please call us.";
        }
            } 
            else{
              console.log("catch ok");
            }
        });
    },

  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  created() {
    this.show = true;
  },
};
</script>

<style scoped></style>
