<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 pb-4">
        <div class="row mb-0 justify-content-center m-0" id="top">
          <div class="col-10">
            <div class="BereichUeberschrift">
              <i class="far fa-address-book BereichUeberschriftPicto"></i>
              <span v-if="sprachID === 0">Karriere</span>
              <span v-if="sprachID === 1">Career</span>
            </div>
          </div>
        </div>
        <div>
          <div class="row m-0 pb-4 pt-4 px-5">
            <div class="col-12 text-center textBlau">
              <span v-if="sprachID === 0">
                <h2 class="titel text-center pt-3">
                  Kein erfolgreiches Unternehmen existiert ohne Menschen, die es aufbauen
                  und sich täglich für dessen Ziele einsetzen.
                </h2>
              </span>
              <span v-if="sprachID === 1">
                <h2 class="titel text-center pt-3">
                  No successful company exists without individuals who build it up and
                  commit to its goals every day.
                </h2>
              </span>
            </div>
          </div>
        </div>

        <div class="row pt-2 pb-5 justify-content-center m-0 noHandy">
          <div class="col-10">
            <div class="row justify-content-center">
              <div class="col-3 text-center p-5 m-3 border border-1">
                <a href="#phil" class="navlink">
                  <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                    >Das<br />
                    Arbeitsumfeld
                  </span>
                  <span v-if="sprachID === 0" class="pb-5 KachelBase"
                    >Perspektiven/<br />
                    Rahmenbedingungen</span
                  >
                  <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                    >The<br />
                    Environment
                  </span>
                  <span v-if="sprachID === 1" class="pb-5 KachelBase"
                    >Perspectives/<br />
                    Conditions</span
                  >
                </a>
              </div>
              <div class="col-3 text-center p-5 m-3 border border-1">
                <a href="#offeneStellen" class="navlink">
                  <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                    >Offene <br />Stellen</span
                  >
                  <span v-if="sprachID === 0" class="pb-5 KachelBase"
                    >Aktuelle<br />
                    Ausschreibungen</span
                  >
                  <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                    >Jobs<br />Vacancies</span
                  >
                  <span v-if="sprachID === 1" class="pb-5 KachelBase"
                    >Available<br />
                    Positions</span
                  >
                </a>
              </div>
              <div class="col-3 text-center p-5 m-3 border border-1">
                <a href="#onlinebewerbung" class="navlink">
                  <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                    >Online<br />
                    Bewerbung</span
                  >
                  <span v-if="sprachID === 0" class="pb-5 KachelBase"
                    >Dein direkter Weg<br />
                    zu uns</span
                  >
                  <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                    >online<br />
                    application</span
                  >
                  <span v-if="sprachID === 1" class="pb-5 KachelBase"
                    >Your direct route<br />
                    to us</span
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-5 justify-content-center noMonitor">
          <div class="col-11 text-center border">
            <a href="#philH" class="navlink">
              <span v-if="sprachID === 0" class="KachelTop underline"
                >Das<br />
                Arbeitsumfeld
              </span>
              <span v-if="sprachID === 0" class="KachelBase"
                >Perspektiven/<br />
                Rahmenbedingungen</span
              >
              <span v-if="sprachID === 1" class="KachelTop underline"
                >The<br />
                Environment
              </span>
              <span v-if="sprachID === 1" class="KachelBase"
                >Perspectives/<br />
                Conditions</span
              >
            </a>
          </div>
        </div>
        <div class="row pt-5 justify-content-center noMonitor">
          <div class="col-11 text-center border">
            <a href="#teH" class="navlink">
              <span v-if="sprachID === 0" class="KachelTop underline"
                >Offene <br />Stellen</span
              >
              <span v-if="sprachID === 0" class="KachelBase"
                >Aktuelle<br />
                Ausschreibungen</span
              >
              <span v-if="sprachID === 1" class="KachelTop underline"
                >Jobs<br />Vacancies</span
              >
              <span v-if="sprachID === 1" class="KachelBase"
                >Available<br />
                Positions</span
              >
            </a>
          </div>
        </div>
        <div class="row pt-5 pb-5 justify-content-center noMonitor">
          <div class="col-11 text-center p border">
            <a href="#onlinebewerbungH" class="navlink">
              <span v-if="sprachID === 0" class="KachelTop underline"
                >Online <br />
                Bewerbung</span
              >
              <span v-if="sprachID === 0" class="KachelBase"
                >Dein direkter Weg<br />
                zu uns</span
              >
              <span v-if="sprachID === 1" class="KachelTop underline"
                >online<br />
                application</span
              >
              <span v-if="sprachID === 1" class="KachelBase"
                >Your direct route<br />
                to us</span
              >
            </a>
          </div>
        </div>

        <div class="noHandy" :style="'min-height: ' + windowHeight * 0.2 + 'px;'"></div>
        <div  class="row m-0 mb-4 justify-content-center noHandy"
          v-if="sprachID === 0"
          id="phil"
        >
          <div class="col-md-8 mt-5 title">
            <div class="row m-0">
              <div class="col-8 textblock blocksatz p-4">
                <h2 class="titel text-center BereichUeberschrift">Das Arbeitsumfeld</h2>
                <p class="pt-4">
                  Entdecke spannende Möglichkeiten und werde Teil unseres Teams!
                </p>
                <p>
                  Bei der STFG findest du ein inspirierendes Arbeitsumfeld, das auf
                  Wachstum, Innovation und Zusammenarbeit ausgerichtet ist. Wir bieten
                  herausfordernde Aufgaben und Projekte, in denen du deine Talente
                  entfalten und neue Fähigkeiten entwickeln kannst. Mit einer offenen
                  Unternehmenskultur fördern wir eigenverantwortliches Arbeiten und
                  wünschen uns Mitarbeiter, die eigene Ideen einbringen.
                </p>
                <p>
                  Werde Teil eines dynamischen Unternehmens, das auf Qualität,
                  Kundenzufriedenheit und Nachhaltigkeit setzt. Gemeinsam gestalten wir
                  die Weiterentwicklung der STFG.
                </p>
                <p>
                  Als Hersteller PA6-basierter Verbundwerkstoffe bieten wir
                  Entwicklungsmöglichkeiten für Berufsanfänger, Quereinsteiger und
                  erfahrene Fachkräfte.
                </p>
                <p>
                  Bewirb dich jetzt bei uns, um den Weg in die Zukunft gemeinsam zu gehen.
                </p>
                <p>Wir freuen uns darauf von dir zu hören.</p>

                <p>Die STFG Personalabteilung</p>
              </div>
              <div class="col-1"></div>
              <div class="col-md-3 col-sm-12 text-center centriert bg-footer mt-3">
                <div class="">
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/Uhr.png"
                        class="img-fluid align-middle qualiBild4 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Flexible Arbeitszeiten</h4>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/hand.png"
                        class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Vergünstigungen</h4>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/wissen.png"
                        class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Weiterbildung</h4>
                    </div>
                  </div>
                </div>
                <!-- <img src="../assets/entrepreneur-1340649_1920.jpg" class="img-fluid align-middle" alt="iso_50001" />-->
              </div>
            </div>
            <div :style="'min-height: ' + windowHeight * 0.2 + 'px;'"></div>

            <span id="offeneStellen" class="pb-5"></span>
            <div class="row pt-5 m-0">
              <div class="row m-0 mt-3 justify-content-center">
                <div class="col-12 pt-3">
                  <h2 class="titel text-center BereichUeberschrift">Offene Stellen</h2>
                </div>
              </div>
            </div>

            <div class="row m-0 mt-4 mb-5 justify-content-center title">
              <div class="col-12 textblock blocksatz p-4 border border-3">
                <p>Wir suchen zum 01.04.2024 einen</p>
                <h2 class="titel text-center ">Qualitäts- und Energiemanagementbeauftragten mit Verantwortung als Hygienebeauftragter und Laborleiter (m/w/d)</h2>
                
                <h4 class="rotSgrau fw-bolder pt-4">
                  Diese Tätigkeit erfordert:
                </h4>
                <p class="pt-4">              
                  -	Hoch- oder Fachhochschulabschluss
                </p>
                <p>-	Erfolgreiche Fortbildung zum Energiemanagementbeauftragten EnMB – TÜV</p>
                <p>-	Erfolgreich absolvierte Schulung „Hygiene bei Verdunstungskühlanlagen nach VDI 2047“</p>
                <p>-	Mehrjährige Berufserfahrung in Produktion/ Technologie und/ oder Qualitätsmanagement</p>
                <p>-	Englisch in Wort und Schrift</p>
                <p>-	hohe Leistungs-, Team-, Kommunikationsfähigkeit sowie Verantwortungsbewusstsein</p>
                <p>-	ein hohes Maß an Eigeninitiative und Lernbereitschaft</p>
                <p>-	Sorgfalt, Zuverlässigkeit, Ehrlichkeit </p>
                <h4 class="rotSgrau fw-bolder pt-4">
                  Sie erwartet:
                </h4>
                <p class="pt-4">-	ein gutes und erfahrenes Team</p>                
                <p>-	eine umfassende Einarbeitung</p>
                <p>-	eine sehr interessante und vielseitige Aufgabe</p>
                <p>-	eine berufliche Perspektive</p>
                <p>-	attraktive Entlohnung</p>

                <h5 class="pt-4">
                  Sollten wir Ihr Interesse geweckt haben und Sie dem Anforderungsprofil entsprechen, dann richten Sie bitte Ihre aussagekräftigen Bewerbungsunterlagen schriftlich mit Gehaltsvorstellung und frühesten Eintrittstermin an:
                </h5>
                
                
                <h5 class="pt-5 fw-bold">STFG Filamente GmbH</h5>
                <h5>Frau Antje El Haiba</h5>
                <h5>Breitscheidstraße 135 </h5>                
                <h5>07407 Rudolstadt</h5>
                <h5 class="pt-4">Oder</h5>
                <h5 class="pt-4">
                  <a href="a.elhaiba@stfg-rudolstadt.com" >a.elhaiba@stfg-rudolstadt.com</a>
                </h5>



                
                








                
              </div>
            </div>

            <div :style="'min-height: ' + windowHeight * 0.5 + 'px;'"></div>

            <span id="onlinebewerbung" class="pb-5"></span>
            <div class="row pt-5 m-0">
              <div class="row m-0 mt-3 mb-4 justify-content-center">
                <div class="col-12 pt-3">
                  <h2 class="titel text-center BereichUeberschrift">Online Bewerbung</h2>
                </div>
              </div>
            </div>

            <div class="row m-0 mb-2 justify-content-center">
              <div class="col-12 p-5 border bg-white border-3">
                <h5 style="text-align: center">Wir freuen uns auf deine Nachricht</h5>

                <div class="row m-0 justify-content-center m-0 p-0">
                  <div class="col-12">
                    <div class="row m-0">
                      <div class="col-12">
                        <div class="title"></div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5 class="">Persönliches</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anrede" class="form-label">Anrede</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="bewerbung.Anrede"
                        >
                          <option value="ohne" selected>Bitte auswählen</option>
                          <option value="Frau">Frau</option>
                          <option value="Herr">Herr</option>
                          <option value="Neutrale Anrede">Neutrale Anrede</option>
                        </select>
                      </div>
                      <div class="col-md-5"></div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="vname" class="form-label">Vorname*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="vname"
                          v-model="bewerbung.Vorname"
                          v-bind:class="[error.Vorname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Vorname == true"
                        >
                          {{ error.VornameText }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="nname" class="form-label">Nachname*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nname"
                          v-model="bewerbung.Nachname"
                          v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Nachname == true"
                        >
                          {{ error.NachnameText }}
                        </label>
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="email" class="form-label">E-Mail*</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          v-model="bewerbung.Email1"
                          v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Email1 == true"
                        >
                          {{ error.Email1Text }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="telefon" class="form-label">Telefon</label>
                        <input
                          type="text"
                          class="form-control"
                          id="telefon"
                          v-model="bewerbung.Telefon"
                        />
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="strasse" class="form-label">Straße</label>
                        <input
                          type="text"
                          class="form-control"
                          id="strasse"
                          v-model="bewerbung.Strasse"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="hausnummer" class="form-label">Hausnummer</label>
                        <input
                          type="text"
                          class="form-control"
                          id="hausnummer"
                          v-model="bewerbung.Hausnummer"
                        />
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="plz" class="form-label">Postleitzahl</label>
                        <input
                          type="text"
                          class="form-control"
                          id="plz"
                          v-model="bewerbung.PLZ"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="stadt" class="form-label">Stadt</label>
                        <input
                          type="text"
                          class="form-control"
                          id="stadt"
                          v-model="bewerbung.Stadt"
                        />
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.andere_Stellen_moeglich"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; Ich bin damit einverstanden, dass meine Bewerbung auch
                          für andere Stellen berücksichtig wird.<br />
                          Natürlich werden deine Daten vertraulich bei uns behandelt.
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5>
                            Bitte sende uns ein aussagekräftiges Anschreiben und einen
                            vollständigen Lebenslauf.
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anschreiben" class="form-label">Anschreiben</label>
                        <input
                          type="file"
                          ref="anschreibenfile"
                          name="anschreiben"
                          class="form-control"
                          id="anschreiben"
                          @change="uploadAnschreiben()"
                        />
                        <label class="form-check-label inputok">
                          {{ bewerbung.Anschreiben }}
                        </label>
                      </div>

                      <div class="col-md-5">
                        <label for="lebenslauf" class="form-label">Lebenslauf</label>
                        <input
                          type="file"
                          ref="lebenslauffile"
                          name="lebenslauf"
                          class="form-control"
                          id="lebenslauf"
                          @change="uploadLebenslauf()"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Lebenslauf == true"
                        >
                          {{ error.LebenslaufText }}
                        </label>
                        <label class="form-check-label inputok">
                          {{ bewerbung.Lebenslauf }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5>Datenschutzerklärung</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.Datenschutz"
                          v-bind:class="[error.Datenschutz == true ? 'bg-warning' : '']"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; Ich habe die

                          <span class="dse" @click="openDSE()">Datenschutzerklärung</span>

                          zur Kenntnis genommen.
                        </label>
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Datenschutz == true"
                        >
                          {{ error.DatenschutzText }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-4 justify-content-center">
                      <div class="col-md-10">
                        {{ mail.gesendet }}
                      </div>
                    </div>

                    <div class="row m-0 mt-4 justify-content-center">
                      <div class="col-md-10">
                        <div class="d-grid">
                          <button class="formularButtonsGruen" @click="checkForm()">
                            Bewerbung abschicken
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row m-0 justify-content-center noHandy"
          v-if="sprachID === 1"
          id="phil"
        >
          <div class="col-md-8 mt-5 mb-5 title">
            <div class="row m-0">
              <div class="col-8 textblock blocksatz p-4">
                <h2 class="titel text-center BereichUeberschrift">The Environment</h2>

                <p class="pt-3">Discover fascinating possibilities and join our team!</p>
                <p>
                  You will find an inspiring working environment with us that focuses on
                  growth, innovation as well as cooperation. We offer challenging tasks
                  and projects, helping to unfold your talents and to develop new
                  abilities. Having established an open company culture, we foster
                  self-dependent working attitude and seek employees contributing own
                  ideas.
                </p>
                <p>
                  Become part of a dynamic company that believes in quality, customer
                  satisfaction and the importance of sustainability. Together we will
                  shape the evolution of STFG.
                </p>
                <p>
                  As a producer of PA6-Compounds we provide perspectives on an
                  entry-level, for individuals intending to change career and experienced
                  professionals.
                </p>
                <p>Apply now, so we can progress into the future together.</p>
                <p>We are looking forward to hearing from you.</p>
                <p>STFG Human Resources Department</p>
              </div>
              <div class="col-1"></div>
              <div class="col-md-3 col-sm-12 text-center centriert bg-footer mt-3">
                <div class="">
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/Uhr.png"
                        class="img-fluid align-middle qualiBild4 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Flexible Working Hours</h4>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/hand.png"
                        class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Perks</h4>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/wissen.png"
                        class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Trainings</h4>
                    </div>
                  </div>
                </div>
                <!-- <img src="../assets/entrepreneur-1340649_1920.jpg" class="img-fluid align-middle" alt="iso_50001" />-->
              </div>
            </div>
            <div :style="'min-height: ' + windowHeight * 0.2 + 'px;'"></div>
            <span id="offeneStellen" class="pb-5"></span>

            <div class="row pt-5 m-0">
              <div class="row m-0 mt-3 mb-3 justify-content-center">
                <div class="col-12 pt-3">
                  <h2 class="titel text-center BereichUeberschrift">Job Vacancies</h2>
                </div>
              </div>
            </div>

            <div class="row m-0 mt-3 mb-0 justify-content-center">
              <div class="col-12 p-5 border bg-white border-3">
                <h5>
                  Unfortunately, there are no job vacancies for international applicants
                  at this point in time.
                </h5>
                <h5>
                  Please check back for updates or get in touch via online application in
                  case you are convinced that we should review your credentials anyways.
                </h5>
              </div>
            </div>
            <div :style="'min-height: ' + windowHeight * 0.4 + 'px;'"></div>
            <span id="onlinebewerbung" class="pb-5"></span>
            <h2 class="mt-5 titel text-center BereichUeberschrift">Online Application</h2>
            <div class="row m-0 mt-5 pb-0 justify-content-center">
              <div class="col-12 p-0 pt-5 border bg-white border-3">
                <h5 style="text-align: center">We are looking forward to your message</h5>
                <div class="row m-0 justify-content-center m-0 p-0">
                  <div class="col-12">
                    <div class="row m-0">
                      <div class="col-12">
                        <div class="title"></div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5>Personal Information</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anrede" class="form-label">Title</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="bewerbung.Anrede"
                        >
                          <option value="ohne" selected>
                            <span v-if="sprachID === 0">Bitte auswählen</span>
                            <span v-if="sprachID === 1">Please select</span>
                          </option>
                          <option value="Frau">
                            <span v-if="sprachID === 0">Frau</span>
                            <span v-if="sprachID === 1">Mrs.</span>
                          </option>
                          <option value="Herr">
                            <span v-if="sprachID === 0">Herr</span>
                            <span v-if="sprachID === 1">Mr.</span>
                          </option>
                          <option value="Neutrale Anrede">
                            <span v-if="sprachID === 0">Neutrale Anrede</span>
                            <span v-if="sprachID === 1">Neutral salutation</span>
                          </option>
                        </select>
                      </div>
                      <div class="col-md-5"></div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="vname" class="form-label">First Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="vname"
                          v-model="bewerbung.Vorname"
                          v-bind:class="[error.Vorname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Vorname == true"
                        >
                          {{ error.VornameText }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="nname" class="form-label">Last Name*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nname"
                          v-model="bewerbung.Nachname"
                          v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Nachname == true"
                        >
                          {{ error.NachnameText }}
                        </label>
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="email" class="form-label">E-Mail*</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          v-model="bewerbung.Email1"
                          v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Email1 == true"
                        >
                          {{ error.Email1Text }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="telefon" class="form-label">Phone</label>
                        <input
                          type="text"
                          class="form-control"
                          id="telefon"
                          v-model="bewerbung.Telefon"
                        />
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="strasse" class="form-label">Street </label>
                        <input
                          type="text"
                          class="form-control"
                          id="strasse"
                          v-model="bewerbung.Strasse"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="hausnummer" class="form-label">Number</label>
                        <input
                          type="text"
                          class="form-control"
                          id="hausnummer"
                          v-model="bewerbung.Hausnummer"
                        />
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="plz" class="form-label">ZIP Code </label>
                        <input
                          type="text"
                          class="form-control"
                          id="plz"
                          v-model="bewerbung.PLZ"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="stadt" class="form-label">City</label>
                        <input
                          type="text"
                          class="form-control"
                          id="stadt"
                          v-model="bewerbung.Stadt"
                        />
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.andere_Stellen_moeglich"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; I hereby consent that my application may also be
                          considered for other vacancies.
                          <br />
                          Your information will be treated confidentially.
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5>
                            Please provide a convincing cover letter and a complete
                            curriculum vitae.
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anschreiben" class="form-label">Cover Letter *</label>
                        <input
                          type="file"
                          ref="anschreibenfile2"
                          name="anschreiben"
                          class="form-control"
                          id="anschreiben"
                          @change="uploadAnschreiben2"
                        />
                        <label class="form-check-label inputok">
                          {{ bewerbung.Anschreiben }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="lebenslauf" class="form-label"
                          >Curriculum Vitae *</label
                        >
                        <input
                          type="file"
                          ref="lebenslauffile2"
                          name="lebenslauf"
                          class="form-control"
                          id="lebenslauf"
                          @change="uploadLebenslauf2"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Lebenslauf == true"
                        >
                          {{ error.LebenslaufText }}
                        </label>
                        <label class="form-check-label inputok">
                          {{ bewerbung.Lebenslauf }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5>Privacy Policy</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.Datenschutz"
                          v-bind:class="[error.Datenschutz == true ? 'bg-warning' : '']"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; I have read the
                          <span class="dse" @click="openDSE()">Privacy Policy</span>
                        </label>
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Datenschutz == true"
                        >
                          {{ error.DatenschutzText }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-4 justify-content-center">
                      <div class="col-md-10">
                        {{ mail.gesendet }}
                      </div>
                    </div>

                    <div class="row m-0 mt-4 mb-4 justify-content-center">
                      <div class="col-md-10">
                        <div class="d-grid">
                          <button class="formularButtonsGruen" @click="checkForm()">
                            Send Application
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="noMonitor"
          id="philH"
          :style="'min-height: ' + windowHeight * 0.1 + 'px;'"
        ></div>
        <div class="row m-0 justify-content-center noMonitor" v-if="sprachID === 0">
          <h2 class="titel text-center BereichUeberschrift">Das Arbeitsumfeld</h2>


          <div class="col-md-11 mt-1 title">
            <div class="row">
              <div class="col-12 text-center centriert bg-footer mt-1">
                <div class="">
                  <div class="row justify-content-center pt-4">
                    <div class="col-10">
                      <img
                        src="../assets/Uhr.png"
                        class="img-fluid align-middle qualiBild4 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Flexible Arbeitszeiten</h4>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/hand.png"
                        class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Vergünstigungen</h4>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-10">
                      <img
                        src="../assets/wissen.png"
                        class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                        alt="iso_50001"
                      />
                      <h4>Weiterbildung</h4>
                    </div>
                  </div>
                </div>
                <!-- <img src="../assets/entrepreneur-1340649_1920.jpg" class="img-fluid align-middle" alt="iso_50001" />-->
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 textblock blocksatz p-2">
                <p class="pt-4">
                  Entdecke spannende Möglichkeiten und werde Teil unseres Teams!
                </p>
                <p>
                  Bei der STFG findest du ein inspirierendes Arbeitsumfeld, das auf
                  Wachstum, Innovation und Zusammenarbeit ausgerichtet ist. Wir bieten
                  herausfordernde Aufgaben und Projekte, in denen du deine Talente
                  entfalten und neue Fähigkeiten entwickeln kannst. Mit einer offenen
                  Unternehmenskultur fördern wir eigenverantwortliches Arbeiten und
                  wünschen uns Mitarbeiter, die eigene Ideen einbringen.
                </p>
                <p>
                  Werde Teil eines dynamischen Unternehmens, das auf Qualität,
                  Kundenzufriedenheit und Nachhaltigkeit setzt. Gemeinsam gestalten wir
                  die Weiterentwicklung der STFG.
                </p>
                <p>
                  Als Hersteller PA6-basierter Verbundwerkstoffe bieten wir
                  Entwicklungsmöglichkeiten für Berufsanfänger, Quereinsteiger und
                  erfahrene Fachkräfte.
                </p>
                <p>
                  Bewirb dich jetzt bei uns, um den Weg in die Zukunft gemeinsam zu gehen.
                </p>
                <p>Wir freuen uns darauf von dir zu hören.</p>

                <p>Die STFG Personalabteilung</p>
              </div>
            </div>
            <span  class="pb-5"></span>
            <div class="noMonitor" id="teH" :style="'min-height: ' + windowHeight * 0.10 + 'px;'"></div>
            <div class="row pt-5 m-0">
              <div class="row m-0 mt-3 mb-3 justify-content-center">
                <div class="col-12 pt-3">
                  <span class="KachelTop">Offene Stellen</span>
                </div>
              </div>
            </div>

            <div class="row m-0 mt-3 mb-5 justify-content-center">
              <div class="col-12 p-2 border bg-white border-3">
                <p>Wir suchen zum 01.04.2024 einen</p>
                <h2 class="titel text-center ">Qualitäts- und Energiemanagement- beauftragten mit Verantwortung als Hygienebeauftragter und Laborleiter (m/w/d)</h2>
                
                <h4 class="rotSgrau fw-bolder pt-4">
                  Diese Tätigkeit erfordert:
                </h4>
                <p class="pt-4">              
                  -	Hoch- oder Fachhochschulabschluss
                </p>
                <p>-	Erfolgreiche Fortbildung zum Energiemanagementbeauftragten EnMB – TÜV</p>
                <p>-	Erfolgreich absolvierte Schulung „Hygiene bei Verdunstungskühlanlagen nach VDI 2047“</p>
                <p>-	Mehrjährige Berufserfahrung in Produktion/ Technologie und/ oder Qualitätsmanagement</p>
                <p>-	Englisch in Wort und Schrift</p>
                <p>-	hohe Leistungs-, Team-, Kommunikationsfähigkeit sowie Verantwortungsbewusstsein</p>
                <p>-	ein hohes Maß an Eigeninitiative und Lernbereitschaft</p>
                <p>-	Sorgfalt, Zuverlässigkeit, Ehrlichkeit </p>
                <h4 class="rotSgrau fw-bolder pt-4">
                  Sie erwartet:
                </h4>
                <p class="pt-4">-	ein gutes und erfahrenes Team</p>                
                <p>-	eine umfassende Einarbeitung</p>
                <p>-	eine sehr interessante und vielseitige Aufgabe</p>
                <p>-	eine berufliche Perspektive</p>
                <p>-	attraktive Entlohnung</p>

                <h5 class="pt-4">
                  Sollten wir Ihr Interesse geweckt haben und Sie dem Anforderungsprofil entsprechen, dann richten Sie bitte Ihre aussagekräftigen Bewerbungsunterlagen schriftlich mit Gehaltsvorstellung und frühesten Eintrittstermin an:
                </h5>
                
                
                <h5 class="pt-5 fw-bold">STFG Filamente GmbH</h5>
                <h5>Frau Antje El Haiba</h5>
                <h5>Breitscheidstraße 135 </h5>                
                <h5>07407 Rudolstadt</h5>
                <h5 class="pt-4">Oder</h5>
                <h5 class="pt-4"><a href="a.elhaiba@stfg-rudolstadt.com" >a.elhaiba@stfg-rudolstadt.com</a></h5>
              </div>
            </div>
            <div class="noMonitor" id="onlinebewerbungH" :style="'min-height: ' + windowHeight * 0.15 + 'px;'"></div>
            
            <span
              id="onlinebewerbungh"
              class="pb-5"
              style="position: relative; top: -100px"
            ></span>
            <span id="onlinebewerbung" class="pb-5"></span>
            <span class="KachelTop"> Online Bewerbung</span>
            <div class="row m-0 mt-3 mb-5 justify-content-center">
              <div class="col-12 p-1 border bg-white border-3">
                <h5 style="text-align: center">Wir freuen uns auf deine Bewerbung</h5>
                <div class="row m-0 justify-content-center m-0 p-0">
                  <div class="col-12">
                    <div class="row m-0">
                      <div class="col-12">
                        <div class="title"></div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5 class="p-2">Persönliches</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anrede" class="form-label">Anrede</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="bewerbung.Anrede"
                        >
                          <option value="ohne" selected>
                            <span v-if="sprachID === 0">Bitte auswählen</span>
                            <span v-if="sprachID === 1">Please select</span>
                          </option>
                          <option value="Frau">
                            <span v-if="sprachID === 0">Frau</span>
                            <span v-if="sprachID === 1">Mrs.</span>
                          </option>
                          <option value="Herr">
                            <span v-if="sprachID === 0">Herr</span>
                            <span v-if="sprachID === 1">Mr.</span>
                          </option>
                          <option value="Neutrale Anrede">
                            <span v-if="sprachID === 0">Neutrale Anrede</span>
                            <span v-if="sprachID === 1">Neutral salutation</span>
                          </option>
                        </select>
                      </div>
                      <div class="col-md-5"></div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="vname" class="form-label">Vorname*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="vname"
                          v-model="bewerbung.Vorname"
                          v-bind:class="[error.Vorname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Vorname == true"
                        >
                          {{ error.VornameText }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="nname" class="form-label">Nachname*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nname"
                          v-model="bewerbung.Nachname"
                          v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Nachname == true"
                        >
                          {{ error.NachnameText }}
                        </label>
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="email" class="form-label">E-Mail*</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          v-model="bewerbung.Email1"
                          v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Email1 == true"
                        >
                          {{ error.Email1Text }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="telefon" class="form-label">Telefon</label>
                        <input
                          type="text"
                          class="form-control"
                          id="telefon"
                          v-model="bewerbung.Telefon"
                        />
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="strasse" class="form-label">Straße</label>
                        <input
                          type="text"
                          class="form-control"
                          id="strasse"
                          v-model="bewerbung.Strasse"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="hausnummer" class="form-label">Hausnummer</label>
                        <input
                          type="text"
                          class="form-control"
                          id="hausnummer"
                          v-model="bewerbung.Hausnummer"
                        />
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="plz" class="form-label">Postleitzahl</label>
                        <input
                          type="text"
                          class="form-control"
                          id="plz"
                          v-model="bewerbung.PLZ"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="stadt" class="form-label">Stadt</label>
                        <input
                          type="text"
                          class="form-control"
                          id="stadt"
                          v-model="bewerbung.Stadt"
                        />
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.andere_Stellen_moeglich"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; Ich bin damit einverstanden, dass meine Bewerbung auch
                          für andere Stellen berücksichtigt wird.<br />
                          Natürlich werden deine Daten vertraulich bei uns behandelt.
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5 class="p-2">
                            Bitte sende uns ein aussagekräftiges Anschreiben und einen vollständigen Lebenslauf.
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anschreiben" class="form-label">Anschreiben *</label>
                        <input
                          type="file"
                          ref="anschreibenfile3"
                          name="anschreiben"
                          class="form-control"
                          id="anschreiben"
                          @change="uploadAnschreiben3"
                        />
                        <label class="form-check-label inputok">
                          {{ bewerbung.Anschreiben }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="lebenslauf" class="form-label">Lebenslauf *</label>
                        <input
                          type="file"
                          ref="lebenslauffile3"
                          name="lebenslauf"
                          class="form-control"
                          id="lebenslauf"
                          @change="uploadLebenslauf3"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Lebenslauf == true"
                        >
                          {{ error.LebenslaufText }}
                        </label>
                        <label class="form-check-label inputok">
                          {{ bewerbung.Lebenslauf }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5 class="p-2">Datenschutzerklärung</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.Datenschutz"
                          v-bind:class="[error.Datenschutz == true ? 'bg-warning' : '']"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; Ich habe die
                          <span class="dse" @click="openDSE()">Datenschutzerklärung</span>
                          zur Kenntnis genommen.
                        </label>
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Datenschutz == true"
                        >
                          {{ error.DatenschutzText }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-4 justify-content-center">
                      <div class="col-md-10">
                        {{ mail.gesendet }}
                      </div>
                    </div>

                    <div class="row m-0 mt-4 justify-content-center">
                      <div class="col-md-10">
                        <div class="d-grid">
                          <button class="formularButtonsGruen" @click="checkForm()">
                            Bewerbung abschicken
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row m-0 justify-content-center noMonitor"   v-if="sprachID === 1"
          id="philH"
        >
                <h2 class="titel text-center BereichUeberschrift">The Environment</h2>
                <div class="col-md-11 mt-1 title">
                <div class="row">
                  <div class="col-12 text-center centriert bg-footer mt-1">
                    <div class="">
                      <div class="row justify-content-center pt-4">
                        <div class="col-10">
                          <img
                            src="../assets/Uhr.png"
                            class="img-fluid align-middle qualiBild4 pb-3"
                            alt="iso_50001"
                          />
                          <h4>Flexible Working Hours
                          </h4>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-10">
                          <img
                            src="../assets/hand.png"
                            class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                            alt="iso_50001"
                          />
                          <h4>Perks</h4>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-10">
                          <img
                            src="../assets/wissen.png"
                            class="img-fluid align-middle qualiBild4 pt-5 pb-3"
                            alt="iso_50001"
                          />
                          <h4>Trainings</h4>
                        </div>
                      </div>
                    </div>
                    <!-- <img src="../assets/entrepreneur-1340649_1920.jpg" class="img-fluid align-middle" alt="iso_50001" />-->
                  </div>
                </div>
          
          
          
                <div class="col-md-11 mt-1 title">
            <div class="row m-0">
              <div class="col-12 textblock blocksatz p-2">  
                <p>Discover fascinating possibilities and join our team!</p>
                <p>
                  You will find an inspiring working environment with us that focuses on
                  growth, innovation as well as cooperation. We offer challenging tasks
                  and projects, helping to unfold your talents and to develop new
                  abilities. Having established an open company culture, we foster
                  self-dependent working attitude and seek employees contributing own
                  ideas.
                </p>
                <p>
                  Become part of a dynamic company that believes in quality, customer
                  satisfaction and the importance of sustainability. Together we will
                  shape the evolution of STFG.
                </p>
                <p>
                  As a producer of PA6-Compounds we provide perspectives on an
                  entry-level, for individuals intending to change career and experienced
                  professionals.
                </p>
                <p>Apply now, so we can progress into the future together.</p>
                <p>We are looking forward to hearing from you.</p>
                <p>STFG Human Resources Department</p>
              </div>
            </div>
          </div>
            <div class="noMonitor" id="teH" :style="'min-height: ' + windowHeight * 0.10 + 'px;'"></div>
            <div class="row pt-5 m-0">
              <div class="row m-0 mt-3 mb-3 justify-content-center">
                <div class="col-12 pt-3">
                  <span class="KachelGross">Job Vacancies</span>
                </div>
              </div>
            </div>

            <div class="row m-0 mt-3 mb-5 justify-content-center">
              <div class="col-12 p-5 border bg-white border-3">
                <h5>
                  Unfortunately, there are no job vacancies for international applicants
                  at this point in time.
                </h5>
                <h5>
                  Please check back for updates or get in touch via online application in
                  case you are convinced that we should review your credentials anyways.
                </h5>
              </div>
            </div>
            
            <div id="onlinebewerbungH" :style="'min-height: ' + windowHeight * 0.2 + 'px;'"></div>
            <span class="KachelTop">Online Application</span>

            <span id="onlinebewerbung" class="pb-0"></span>
            <div class="row m-0 mt-4 justify-content-center">
              <div class="col-12 p-1 pt-4 border bg-white border-3">
                <h5 style="text-align: center">
                  We are looking forward to your message.
                </h5>
                <div class="row m-0 justify-content-center m-0 p-0">
                  <div class="col-12">
                    <div class="row m-0">
                      <div class="col-12">
                        <div class="title"></div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5 class="p-2">Personal Information</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anrede" class="form-label">Title</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="bewerbung.Anrede"
                        >
                          <option value="ohne" selected>please select</option>
                          <option value="Frau">Frau</option>
                          <option value="Herr">Herr</option>
                          <option value="Neutrale Anrede">Neutrale Anrede</option>
                        </select>
                      </div>
                      <div class="col-md-5"></div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="vname" class="form-label">First Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="vname"
                          v-model="bewerbung.Vorname"
                          v-bind:class="[error.Vorname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Vorname == true"
                        >
                          {{ error.VornameText }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="nname" class="form-label">Last Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="nname"
                          v-model="bewerbung.Nachname"
                          v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Nachname == true"
                        >
                          {{ error.NachnameText }}
                        </label>
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="email" class="form-label">E-Mail*</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          v-model="bewerbung.Email1"
                          v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Email1 == true"
                        >
                          {{ error.Email1Text }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="telefon" class="form-label">Phone</label>
                        <input
                          type="text"
                          class="form-control"
                          id="telefon"
                          v-model="bewerbung.Telefon"
                        />
                      </div>
                    </div>
                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="strasse" class="form-label">Street </label>
                        <input
                          type="text"
                          class="form-control"
                          id="strasse"
                          v-model="bewerbung.Strasse"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="hausnummer" class="form-label">Number</label>
                        <input
                          type="text"
                          class="form-control"
                          id="hausnummer"
                          v-model="bewerbung.Hausnummer"
                        />
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="plz" class="form-label">ZIP Code </label>
                        <input
                          type="text"
                          class="form-control"
                          id="plz"
                          v-model="bewerbung.PLZ"
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="stadt" class="form-label">City</label>
                        <input
                          type="text"
                          class="form-control"
                          id="stadt"
                          v-model="bewerbung.Stadt"
                        />
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.andere_Stellen_moeglich"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; I hereby consent that my application may also be
                          considered for other vacancies. <br />
                          Your information will be treated confidentially.
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5 class="p-2">
                            Please provide a convincing cover letter and a complete
                            curriculum vitae.
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 mb-3 justify-content-center">
                      <div class="col-md-5">
                        <label for="anschreiben" class="form-label">Cover Letter *</label>
                        <input
                          type="file"
                          ref="anschreibenfile4"
                          name="anschreiben"
                          class="form-control"
                          id="anschreiben"
                          @change="uploadAnschreiben4"
                        />
                        <label class="form-check-label inputok">
                          {{ bewerbung.Anschreiben }}
                        </label>
                      </div>
                      <div class="col-md-5">
                        <label for="lebenslauf" class="form-label"
                          >Curriculum Vitae *</label
                        >
                        <input
                          type="file"
                          ref="lebenslauffile4"
                          name="lebenslauf"
                          class="form-control"
                          id="lebenslauf"
                          @change="uploadLebenslauf4"
                        />
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Lebenslauf == true"
                        >
                          {{ error.LebenslaufText }}
                        </label>
                        <label class="form-check-label inputok">
                          {{ bewerbung.Lebenslauf }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-5 justify-content-center">
                      <div class="col-md-10">
                        <div class="titleKlein">
                          <h5>Privacy Policy</h5>
                        </div>
                      </div>
                    </div>

                    <div class="row m-0 justify-content-center">
                      <div class="col-md-10">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="andere_Stellen_moeglich"
                          v-model="bewerbung.Datenschutz"
                          v-bind:class="[error.Datenschutz == true ? 'bg-warning' : '']"
                        />
                        <label class="form-check-label" for="andere_Stellen_moeglich"
                          >&nbsp; I have read the .
                          <span class="dse" @click="openDSE()">Privacy Policy</span>
                        </label>
                        <label
                          class="form-check-label inputerror"
                          v-if="error.Datenschutz == true"
                        >
                          {{ error.DatenschutzText }}
                        </label>
                      </div>
                    </div>

                    <div class="row m-0 mt-4 justify-content-center">
                      <div class="col-md-10">
                        {{ mail.gesendet }}
                      </div>
                    </div>

                    <div class="row m-0 mt-4 mb-4 justify-content-center">
                      <div class="col-md-10">
                        <div class="d-grid">
                          <button class="formularButtonsGruen" @click="checkForm()">
                            Send Application
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a href="#top" class="stickyPfeil">
          <i class="fas fa-angle-double-up"></i>
        </a>
      </div>
    </template>
  </Layout>
</template>

<script>
const axiosConfig = {
  crossDomain: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
import Layout from "@/layouts/Layout";
import axios from "axios";
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";
import { openModal } from "jenesius-vue-modal";
import modalDSE from "../components/Modal_DSE.vue";

export default {
  name: "AktuellesStell",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
      bewerbung: {
        Stelle: "",
        Anrede: "",
        Vorname: "",
        Nachname: "",
        Email1: "",
        Strasse: "",
        Hausnummer: "",
        PLZ: "",
        Stadt: "",
        Telefon: "",
        andere_Stellen_moeglich: true,

        Lebenslauf: "",
        Lebenslauf_temp: "",
        Anschreiben: "",
        Anschreiben_temp: "",

        Datenschutz: false,
      },
      error: {
        Vorname: false,
        Nachname: false,
        Email1: false,
        Datenschutz: false,
        VornameText: "",
        NachnameText: "",
        Email1Text: "",
        DatenschutzText: "",
      },
      mail: {
        gesendet: "",
      },
      errors: 0,
    };
  },
  setup() {
    const job = ref(null);
    const jobIsVisible = useElementVisibility(job);
    return {
      windowHeight: window.innerHeight,
      job,
      jobIsVisible,
    };
  },
  components: {
    Layout,
  },

  methods: {
    async openDSE() {
      let modal = await openModal(modalDSE);
      modal.onclose = () => {
        return true;
      };
    },
    checkForm: function () {
      this.errors = 0;

      if (this.bewerbung.Nachname == "") {
        this.error.Nachname = true;
        if (this.sprachID === 0) {
          this.error.NachnameText = "Bitte tragen Sie einen Nachnamen ein";
        } else {
          this.error.NachnameText = "Please enter a last name";
        }
        this.errors = 1;
      } else {
        this.error.Nachname = false;
      }

      if (this.bewerbung.Email1 == "") {
        this.error.Email1 = true;
        if (this.sprachID === 0) {
          this.error.Email1Text = "Bitte tragen Sie eine E-Mail Adresse ein";
        } else {
          this.error.Email1Text = "Please enter an email address";
        }
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      if (this.bewerbung.Datenschutz == false) {
        this.error.Datenschutz = true;
        if (this.sprachID === 0) {
          this.error.DatenschutzText = "Bitte Bitte bestätigen Sie den Datenschutz";
        } else {
          this.error.DatenschutzText =
            "Please confirm that you have read the privacy policy";
        }
        this.errors = 1;
      } else {
        this.error.Datenschutz = false;
      }
      console.log(validEmail(this.bewerbung.Email1));

      if (validEmail(this.bewerbung.Email1) == false) {
        this.error.Email1 = true;
        if (this.sprachID === 0) {
          this.error.Email1Text = "Bitte tragen Sie eine E-Mail Adresse ein";
        } else {
          this.error.Email1Text = "Please enter an email address";
        }
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      console.log(this.errors);
      if (this.errors == 0) {
        console.log("keine Fehler");
        this.sendmail();
        return true;
      }
    },
    clearscreen() {
      this.bewerbung.Stelle = "";
      this.bewerbung.Vorname = "";
      this.bewerbung.Nachname = "";
      this.bewerbung.Email1 = "";
      this.bewerbung.Strasse = "";
      this.bewerbung.Hausnummer = "";
      this.bewerbung.PLZ = "";
      this.bewerbung.Stadt = "";
      this.bewerbung.Telefon = "";
      this.bewerbung.andere_Stellen_moeglich = true;
      this.bewerbung.Datenschutz = false;
      this.bewerbung.Lebenslauf = "";
      this.bewerbung.Anschreiben = "";
      this.bewerbung.Anschreiben_temp = "";
      this.error.LebenslaufText = "";

      this.$refs.lebenslauffile.value = "";
      this.$refs.anschreibenfile.value = "";
      this.$refs.lebenslauffile2.value = "";
      this.$refs.anschreibenfile2.value = "";
      this.$refs.lebenslauffile3.value = "";
      this.$refs.anschreibenfile3.value = "";
      this.$refs.lebenslauffile4.value = "";
      this.$refs.anschreibenfile4.value = "";
    },
    sendmail: function () {
      let payload = this.bewerbung;

      payload.empfaenger = "L.Jentsch@stfg-rudolstadt.com";

      payload.Grund = "Bewerbung";

      payload.sender = "Webseite@stfg.com";

      let mail = this.mail;
      let sendok = false;
      axios
        .post("https://pillar.de/mailrelay/post_attachment.php", payload, axiosConfig)
        .then((response) => {
          console.log("success", response.data);
          if (this.sprachID === 0) {
            mail.gesendet =
              "Wir bedanken uns für Ihre Informationen. Ihre Kontaktdaten wurden erfolgreich versendet.";
          } else {
            mail.gesendet =
              "Thank you for your information. Your contact details have been sent successfully.";
          }
          console.log(sendok);
          sendok = true;
          console.log(sendok);
        })
        .catch((error) => {
          if (sendok == false) {
            console.log(error);
            console.log(error.response);
            if (this.sprachID === 0) {
              mail.gesendet =
                "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
            } else {
              mail.gesendet =
                "Unfortunately, your contact details could not be sent. Please call us.";
            }
          } else {
            console.log("catch ok");
          }
        });
      this.clearscreen();
    },
    uploadLebenslauf() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.lebenslauffile.files.length; i++) {
        let file = this.$refs.lebenslauffile.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Lebenslauf = response.data.file_org;
          bewerbung.Lebenslauf_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadAnschreiben() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.anschreibenfile.files.length; i++) {
        let file = this.$refs.anschreibenfile.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Anschreiben = response.data.file_org;
          bewerbung.Anschreiben_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },

    uploadLebenslauf2() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.lebenslauffile2.files.length; i++) {
        let file = this.$refs.lebenslauffile2.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Lebenslauf = response.data.file_org;
          bewerbung.Lebenslauf_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadAnschreiben2() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.anschreibenfile2.files.length; i++) {
        let file = this.$refs.anschreibenfile2.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Anschreiben = response.data.file_org;
          bewerbung.Anschreiben_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },

    uploadLebenslauf3() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.lebenslauffile3.files.length; i++) {
        let file = this.$refs.lebenslauffile3.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Lebenslauf = response.data.file_org;
          bewerbung.Lebenslauf_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadAnschreiben3() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.anschreibenfile3.files.length; i++) {
        let file = this.$refs.anschreibenfile3.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Anschreiben = response.data.file_org;
          bewerbung.Anschreiben_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },

    uploadLebenslauf4() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.lebenslauffile4.files.length; i++) {
        let file = this.$refs.lebenslauffile4.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Lebenslauf = response.data.file_org;
          bewerbung.Lebenslauf_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadAnschreiben5() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.anschreibenfile5.files.length; i++) {
        let file = this.$refs.anschreibenfile5.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Anschreiben = response.data.file_org;
          bewerbung.Anschreiben_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
  },

  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  created() {
    this.show = true;
  },

  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<style scoped></style>
