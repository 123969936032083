<template #default>
  <div class="row m-0 m-1 justify-content-center">
    <div class="col-10 p-4">
      <span v-if="sprachID === 0"
        ><h2 class="titel text-center BereichUeberschrift">Handel</h2>
        
        
      </span>
      <span v-if="sprachID === 1"
        ><h2 class="titel text-center BereichUeberschrift">Trading</h2>       
      </span>
    </div>
  </div>

  <div class="row m-0 justify-content-center" style="position:relative;">
    <div class="anchor" id="po6" style="position:absolute; top:-80px;"></div>
    <div class="col-8">
      <div class="row m-0">
        <div class="col-3">
          <span v-if="sprachID === 0"> <h3 class="titelH3">Polyamid 6</h3></span>
          <span v-if="sprachID === 1"> <h3 class="titelH3">Polyamide 6</h3></span>
        </div>

        <div class="col-9">
          <span v-if="sprachID === 0"
            ><h3 class="titelH3">
              Rohpolymer verschiedener Viskositäten<br />
              Verpackung: BigBag (bis zu 1.000 kg) auf Palette
            </h3></span
          >
          <span v-if="sprachID === 1"
            ><h3 class="titelH3">raw polymer of varying viscosity <br/>
              packaging: BigBags (up to 1.000 kg) on pallets 
              </h3></span
          >
        </div>
      </div>

      <div class="row m-0">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/p1.png"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span class="deColor pe-1">(DE)</span>
              <span class="enColor pe-1">(EN)</span>
              <span class="itColor">(IT)</span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">
              <span v-if="sprachID === 0"> niedrige Viskosität</span>
              <span v-if="sprachID === 1"> low viscosity</span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">Viskosität um 2,4</span>
              <span v-if="sprachID === 1">viscosity approx. 2,4</span>
            </div>
            <div class="col-1">
              <i
              class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('PDS PA6-24.pdf')"
              ></i>
            </div>
            <!-- <div class="col-2 text-center">
              <span>
                <i
                class="ps-3 fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_Formosa_NP2400 FIC2400 SDS().pdf')"
                ></i
                ></span>
            </div> -->
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">
              <span v-if="sprachID === 0">mittlere Viskosität</span>
              <span v-if="sprachID === 1">medium viscosity</span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">Viskosität um 2,7</span>
              <span v-if="sprachID === 1">viscosity approx 2,7</span>
            </div>
            <div class="col-1">
              <i
              class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('PDS PA6-27.pdf')"
              ></i>
            </div>
            <div class="col-2 text-center">
              <!-- <span>
                <i
                class="fas ps-3 fa-notes-medical downloadIcon pe-3 enColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_Formosa_NP2700 FCFC P2700 SDS(U).pdf')"
                ></i>
              </span> -->
            </div>
          </div>
          
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">
              <span v-if="sprachID === 0">recyceltes PA6</span>
              <span v-if="sprachID === 1">recycled PA6</span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">hohe Viskosität</span>
              <span v-if="sprachID === 1">high viscosity</span>
            </div>
            <div class="col-1">
              <i
              class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('PDS PA6 recycle.pdf')"
              ></i>
            </div>
            <!-- <div class="col-2 text-center">
              <span>
                <i
                class="fas ps-3 fa-notes-medical downloadIcon pe-3 enColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_Anadolu_recycled PA 6.pdf')"
                ></i
                ></span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 justify-content-center pt-5" style="position:relative;">
    <div class="anchor" id="gf2" style="position:absolute; top:-20px;"></div>
    <div class="col-8">
      <div class="row m-0 justify-content-center">
        <div class="col-3">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">Unverstärktes Polyamid 6</h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">              
              Unreinforced Polyamide 6
            </h3>
          </span>
        </div>
        <div class="col-9">
          <span v-if="sprachID === 0">
            <h3 class="titelH3">
              Rohpolymer verschiedener Viskositäten mit Additiven (verbesserte Verarbeitung)<br/>
Verpackung: Alusäcke (25 kg) auf Paletten

            </h3>
          </span>
          <span v-if="sprachID === 1">
            <h3 class="titelH3">raw polymer of varying viscosity with additives (improved processing, specific properties)<br/>
              packaging: aluminium bags (25 kg) on pallets
              </h3>
          </span>
        </div>
      </div>

      <div class="row m-0 justify-content-center">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/p1.png"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span class="deColor pe-1">(DE)</span>
              <span class="enColor pe-1">(EN)</span>
              <span class="itColor">(IT)</span>
            </div>
          </div>
          <div class="row m-0 borderGrauUnten">
            <div class="col-4">B2D</div>
            <div class="col-5">
              <span v-if="sprachID === 0"> mittlere Viskosität</span>
              <span v-if="sprachID === 1"> medium viscosity</span>
            </div>
            <div class="col-1">
              <i
                class="fas fa-clipboard-list downloadIcon  enColor"
                title="technisches Datenblatt"
                @click="openPDF('EN PDS B2D 2023 STFG.pdf')"
              ></i>
            </div>
            <!-- <div class="col-2 text-center" v-if="sprachID === 0">
              <span>
                <i
                  class="fas fa-notes-medical downloadIcon pe-3  deColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon pe-3 enColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
                ></i
                ><i
                  class="fas fa-notes-medical downloadIcon  itColor"
                  title="Sicherheitsdatenblatt"
                  @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
                ></i
              ></span>
            </div> -->
            <!-- <div class="col-2 text-center" v-if="sprachID === 1">
              <span>
                <i
                class="fas fa-notes-medical downloadIcon pe-3  deColor"
                title="Sicherheitsdatenblatt"
                @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
              ></i
              ><i
                class="fas fa-notes-medical downloadIcon pe-3 enColor"
                title="Sicherheitsdatenblatt"
                @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
              ></i
              ><i
                class="fas fa-notes-medical downloadIcon  itColor"
                title="Sicherheitsdatenblatt"
                @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
              ></i
            ></span>
              
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

   <!-- <div class="row m-0 justify-content-center pt-5" style="position:relative;">
    <div class="anchor" id="urea" style="position:absolute; top:-20px;"></div>
    <div class="col-8">
      <div class="row m-0">
        <div class="col-3">
          <span v-if="sprachID === 0"> <h3 class="titelH3">Sonstiges</h3></span>
          <span v-if="sprachID === 1"> <h3 class="titelH3">Miscellaneous</h3></span>
        </div>

        <div class="col-9">
          <span v-if="sprachID === 0"
            ><h3 class="titelH3">
              Diverse Chemieprodukte in Großgebinden (z. B. Harnstoff, Düngemittel) 
            </h3></span
          >
          <span v-if="sprachID === 1"
            ><h3 class="titelH3">Various chemical products in bulk packaging (e.g. fertilizer) </h3></span
          >
        </div>
      </div>

      <div class="row m-0">
        <div class="col-3 p-3 grau20 centriert">
          <img
            src="../assets/fluessig.jpg"
            class="img-fluid align-middle borderBlauKlein"
            alt="..."
          />
        </div>
        <div class="col-9 p-3 grau20">
          <div class="row m-0 blauStandart">
            <div class="col-4">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Bezeichnung</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Name</h4>
              </span>
            </div>
            <div class="col-5">
              <span v-if="sprachID === 0">
                <h4 class="titelH4">Kerneigenschaften</h4>
              </span>
              <span v-if="sprachID === 1">
                <h4 class="titelH4">Key Properties</h4>
              </span>
            </div>

            <div class="col-2">
              <h4 class="titelH4">Download</h4>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-2 offset-10 text-center">
              <span class="deColor pe-1">(DE)</span>
              <span class="enColor pe-1">(EN)</span>
              <span class="itColor">(IT)</span>
            </div>
          </div>

          <div class="row m-0 borderGrauUnten">
            <div class="col-4">UREA</div>
            <div class="col-5">
              <span v-if="sprachID === 0"> Stickstoffgehalt 46 % (min.)</span>
              <span v-if="sprachID === 1"> nitrogen content 46 % (min)</span>
            </div>
            <div class="col-3">
              <span>
                <i
                  class="fas fa-clipboard-list downloadIcon p-1 enColor"
                  title="technisches Datenblatt"
                  @click="openPDF('PDS UREA EN2.pdf')"
                ></i>
              </span>
            </div>
          </div>

          <div class="row m-0 borderGrauUnten">
            <div class="col-4">UAN-32</div>
            <div class="col-5">
              <span v-if="sprachID === 0"> Stickstoffgehalt 31,7 % (min.)</span>
              <span v-if="sprachID === 1"> nitrogen content 31,7 % (min)</span>
            </div>
            <div class="col-3">             
                <i
                  class="fas fa-clipboard-list downloadIcon p-1 enColor"
                  title="technisches Datenblatt"
                  @click="openPDF('PDS UAN EN2.pdf')"
                ></i>              
            </div>
            <div class="col-3">
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>  -->
</template>

<script>
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
      polyAktiv: false,
      polyAktiv2: false,
      sonstiges: false,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
