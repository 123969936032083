<template>
  <Layout>
    <template #default>
      
        <div class="container-fluid p-0">
          <div class="row m-0 pb-5 mb-0 justify-content-center" id="top">
            <div class="col-10">
              <div class="BereichUeberschrift">
                <i class="fas fa-microscope BereichUeberschriftPicto"></i>
                <span v-if="sprachID === 0">QUALITÄT</span>
                <span v-if="sprachID === 1">QUALITY</span>
              </div>
            </div>
          </div>

          <div class="row justify-content-center m-0">
          <div  class="col-10 text-center textBlau">
                <h2 v-if="sprachID === 0">Gerne entwickeln wir uns mit Ihnen und Ihren Ansprüchen weiter.</h2>
                <h2 v-if="sprachID === 1">We are looking forward to evolving with you and your ambitions.</h2>
              </div>
            </div>

            <div class=" noMonitor">
              <div class="row justify-content-center">                
                  <div class="col-9 text-center">
                    <img src="../assets/IMG_5g.jpg" class="card-img-top py-2" alt="..." />
                  </div>
                
              </div>
              <span v-if="sprachID === 0">
                <div class="row justify-content-center">                
                  <div class="col-11 blocksatz textAllgemein">
                    Die STFG Filamente GmbH ist DIN EN ISO 9001 und 50001 zertifiziert. Qualität ergibt sich jedoch nicht nur aus den nachstehenden Zertifikaten, sondern aus kontinuierlicher Überwachung sowie der täglichen Selbstverpflichtung engagierter Mitarbeiter zu Gewissenhaftigkeit und Verantwortungsbewusstsein im Sinne unserer Kunden.
                  </div>                  
                </div>
                <div class="row justify-content-center mt-2">
                  <div class="col-7 text-center pt-5">
                    <img src="../assets/DSC_0035-weiss.png" class="card-img-top " alt="..." />
                  </div>
                </div>
                <div class="row justify-content-center mt-5">
                  <div class="col-11 blocksatz textAllgemein">
                   <p>Unser Prüflabor führt produktionsbegleitend Feuchtigkeitsmessungen und Glasfasergehaltsbestimmungen durch. </p>
                   <p>Darüber hinaus können die folgenden Eigenschaften ermittelt
                    werden:
                  <ul>
                    <li>Zugfestigkeit</li>
                    <li>Bruchdehnung</li>
                    <li>Kerb- und Schlagzähigkeit
                    (Charpy & IZOD)</li>
                    <li>Schmelze-Volumenfließrate</li>
                    <li>Produktfarbe</li>
                  </ul>
                    </p> 
                  </div>
                </div>
                <div class="row justify-content-center pt-5">
                  <div class="col-9 text-center">
                    <img src="../assets/quali1.jpg" class="card-img-top " alt="..." />
                    
                  </div>
                </div>
                <div class="row justify-content-center pt-5">
                  <div class="col-11 blocksatz textAllgemein">
                    <p>
                      Spezialprüfungen werden bei einem akkreditierten Labor in direkter Nachbarschaft durchgeführt.
                    </p>   
                   
                  </div>
                 
                </div>
              </span>
              <span v-if="sprachID === 1">
                <div class="row justify-content-center">                
                  <div class="col-11 blocksatz textAllgemein">
                    STFG is certified according to DIN EN ISO 9001 and 50001. However, quality is not just about the certificates below, but most importantly about continuous monitoring as well as the daily effort of committed employees towards precision and responsibility with regards to our customers and their requirements.
                  </div>                  
                </div>
                <div class="row justify-content-center mt-2">
                  <div class="col-7 text-center pt-5">
                    <img src="../assets/DSC_0035-weiss.png" class="card-img-top " alt="..." />
                  </div>
                </div>
                <div class="row justify-content-center mt-5">
                  <div class="col-11 blocksatz textAllgemein">
                   <p>Our test laboratory analyses moisture and ash contents alongside production.</p>
                   <p>In addition, the following properties can be determined:
                  <ul>
                    <li>tensile strength</li>
                    <li>strain at break</li>
                    <li>impact strength (Charpy & IZOD)</li>
                    <li>melt volume-flow rate </li>
                    <li>product color</li>
                  </ul>
                    </p> 
                  </div>
                </div>
                <div class="row justify-content-center pt-5">
                  <div class="col-9 text-center">
                    <img src="../assets/quali1.jpg" class="card-img-top " alt="..." />
                    
                  </div>
                </div>
                <div class="row justify-content-center pt-5">
                  <div class="col-11 blocksatz textAllgemein">
                    <p>
                      Special tests will be performed at an accredited laboratory in the neighbourhood of STFG. 
                    </p>  
                  </div>
                 
                </div>
              </span>
            </div>

          <div class="row justify-content-center noHandy m-0">
            <div class="col-8 ">
              <div class="pt-5 px-5">
                <span >
                  <div class="row justify-content-center align-middle">
                    <div class="col-5 blocksatz textblock " v-if="sprachID === 0">
                      Die STFG Filamente GmbH ist DIN EN ISO 9001 und 50001 zertifiziert. Qualität ergibt sich jedoch nicht nur aus den nachstehenden Zertifikaten, sondern aus kontinuierlicher Überwachung sowie der täglichen Selbstverpflichtung engagierter Mitarbeiter zu Gewissenhaftigkeit und Verantwortungsbewusstsein im Sinne unserer Kunden.
                    </div>
                    <div class="col-5 blocksatz textblock" v-if="sprachID === 1">
                      STFG is certified according to DIN EN ISO 9001 and 50001. However, quality is not just about the certificates below, but most importantly about continuous monitoring as well as the daily effort of committed employees towards precision and responsibility with regards to our customers and their requirements.
                    </div>
                    <div class="col-5 text-center">
                      <img src="../assets/IMG_5g.jpg" class="card-img-top " alt="..." />
                    </div>
                  </div>

                  <div class="row justify-content-center  mt-5">
                    <div class="col-5 text-center pt-5">
                      <img src="../assets/DSC_0035-weiss.png" class="card-img-top qualiBild3" alt="..." />
                    </div>
                    <div class="col-5 blocksatz textblock pt-5" v-if="sprachID === 0">
                      <p >Unser Prüflabor führt produktionsbegleitend Feuchtigkeits&shy;messungen und Glas&shy;faser&shy;gehalts&shy;bestimmungen durch. </p>
                      <p>Darüber hinaus können die folgenden Eigenschaften ermittelt
                       werden:
                     <ul>
                       <li>Zugfestigkeit</li>
                       <li>Bruchdehnung</li>
                       <li>Kerb- und Schlagzähigkeit <br/>
                       (Charpy & IZOD)</li>
                       <li>Schmelze-Volumenfließrate</li>
                       <li>Produktfarbe</li>
                     </ul>
                      </p> 
                    </div>
                    <div class="col-5 blocksatz textblock pt-5" v-if="sprachID === 1">
                     <p>Our test laboratory analyses moisture and ash contents alongside production.</p>
                     <p>In addition, the following properties can be determined:
                      <ul>
                        <li>tensile strength</li>
                        <li>strain at break</li>
                        <li>impact strength (Charpy & IZOD)</li>
                        <li>melt volume-flow rate </li>
                        <li>product color</li>
                      </ul>
                      </p> 
                    </div>
                  </div>

                  <div class="row justify-content-center">
                    <div class="col-5 blocksatz textblock pt-5" v-if="sprachID === 0">
                      Spezialprüfungen werden bei einem akkreditierten Labor in direkter Nachbarschaft durchgeführt.
                    </div>
                    <div class="col-5 blocksatz textblock pt-5" v-if="sprachID === 1">
                      Special tests will be performed at an accredited laboratory in the neighbourhood of STFG. 
                    </div>
                    <div class="col-5 text-center">
                      <img src="../assets/quali1.jpg" class="card-img-top qualiBild3" alt="..." />
                    </div>
                  </div>
                  
                </span>
                
                
              </div>
            </div>
          </div>

          <!-- <div class="row justify-content-center px-5 pb-3  m-0">
            <div class="col-md-3 col-sm-12 text-center bg-white borderBlau m-3">
            <img src="../assets/iso_9001.jpg" class="img-fluid align-middle" alt="iso_50001" />
            <p class="pt-3 ">
              <span class="downloadLink pe-5 pointer" v-if="sprachID === 0" 
                @click="openPDF('STFG_ISO9001_2022_de.pdf')">
                <i class="far fa-file-alt"></i> Download (DE)
              </span>
              <span class="downloadLink pointer" 
                @click="openPDF('STFG_ISO9001_2022_en.pdf')">
                <i class="far fa-file-alt"></i> Download (EN)
              </span>
            </p>

          </div>
          <div class="col-md-3 col-sm-12 text-center bg-white borderBlau m-3">
            <img src="../assets/iso_50001.jpg" class="img-fluid align-middle" alt="iso_9001" />
            <p class="pt-3">
              <span class="downloadLink pe-5 pointer" 
                v-if="sprachID === 0" 
                @click="openPDF('STFG_ISO50001_2022_de.pdf')">
                <i class="far fa-file-alt"></i> Download (DE)
              </span>
              <span class="downloadLink  pointer" 
                @click="openPDF('STFG_ISO50001_2022_en.pdf')">
                <i class="far fa-file-alt"></i> Download (EN)
              </span>
            </p>
          </div>
        </div>
        <div class="row justify-content-center   pb-2  m-0  noHandy">
          <div class="col-6 m-3 text-center bg-white mb-5  borderBlau">
            <div class="row">
              <div class="col-3 centriert pt-5">
                <img
                  src="../assets/globus.png"
                  class="img-fluid align-middle qualiBild1"
                  alt="iso_9001"
                />

              </div>
              <div class="col-8 pt-5">
                <p class=" Produktregulierung underline" v-if="sprachID === 0">Produktregulierung</p>
                <p class=" Produktregulierung underline" v-if="sprachID === 1">Regulatory Information</p>
                <p class=" ProduktregulierungKlein"> REACH, RoHS …</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-3" v-if="sprachID === 0">
                Gemäß Veröffentlichung der SVHC-Liste (Übersicht
                        besorgniserregender Substanzen), werden die
                        entsprechenden Erklärungen der STFG hier zweimal
                        jährlich aktualisiert.
              </div>
              <div class="col-12 p-3" v-if="sprachID === 1">
                According to updates regarding the SVHC-list (substances of very high concern), STFG will publish corresponding declarations here twice a year. 
              </div>
            </div>
            <div class="row">
              <div class="col-12 pb-3">
                <span class="downloadLink pe-5 pb-3 pointer" 
                v-if="sprachID === 0" @click="openPDF('ProduktregulationDE.pdf')">
                  <i class="far fa-file-alt"></i> Download (DE)
                </span>
                <span class="downloadLink pointer" @click="openPDF('ProduktregulationEN.pdf')">
                  <i class="far fa-file-alt"></i> Download (EN)
                </span>
              </div>
            </div>
          </div> 

        </div>-->

        <div class="row justify-content-center px-5 pb-3  m-0">
          <div class="col-md-3 col-sm-12 text-center bg-white borderBlau m-3">
            <img
              src="../assets/iso_9001.jpg"
              class="img-fluid align-middle"
              alt="iso_9001"
            />
            <p class="pt-3">
              <span
                class="downloadLink pe-5 pointer"
                v-if="sprachID === 0"
                @click="openPDF('STFG_ISO9001_2022_de.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (DE)
              </span>
              <span class="noMonitor"><br/></span>
              <span
                class="downloadLink pointer"
                @click="openPDF('STFG_ISO9001_2022_en.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (EN)
              </span>
            </p>
          </div>
          <div class="col-md-3 col-sm-12 text-center bg-white borderBlau m-3">
            <img
              src="../assets/iso_50001.jpg"
              class="img-fluid align-middle"
              alt="iso_50001"
            />
            <p class="pt-3">
              <span
                class="downloadLink pe-5 pointer"
                v-if="sprachID === 0"
                @click="openPDF('STFG_ISO50001_2022_de.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (DE)
              </span>
              <span class="noMonitor"><br/></span>
              <span
                class="downloadLink pointer"
                @click="openPDF('STFG_ISO50001_2022_en.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (EN)
              </span>
            </p>
          </div>
        </div>
        <div class="row justify-content-center px-3 pb-4  m-0">
          <div class="col-md-6 text-center bg-white borderBlau mb-4">
            <div class="row">
              <div class="col-3 centriert pt-5">
                <img
                  src="../assets/globus.png"
                  class="img-fluid align-middle qualiBild1"
                  alt="iso_9001"
                />
              </div>
              <div class="col-9 pt-5">
                <p class=" Produktregulierung underline" v-if="sprachID === 0">Produktregulierung</p>
                <p class=" Produktregulierung underline" v-if="sprachID === 1">Regulatory Information</p>
                <p class="ProduktregulierungKlein">REACH, RoHS …</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-3" v-if="sprachID === 0">
                Gemäß Veröffentlichung der SVHC-Liste (Übersicht
                        besorgniserregender Substanzen), werden die
                        entsprechenden Erklärungen der STFG hier zweimal
                        jährlich aktualisiert.
              </div>
              <div class="col-12 p-3" v-if="sprachID === 1">
                According to updates regarding the SVHC-list (substances of very high concern), STFG will publish corresponding declarations here twice a year. 
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-12 pb-3">
                <span
                  class="downloadLink pe-5 pb-3 pointer"
                  v-if="sprachID === 0"
                  @click="openPDF('ProduktregulationDE.pdf')"
                >
                  <i class="far fa-file-alt"></i> Download (DE)
                </span>
                <span
                  class="downloadLink pointer"
                  @click="openPDF('ProduktregulationEN.pdf')"
                >
                  <i class="far fa-file-alt"></i> Download (EN)
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row justify-content-center pb-0  noMonitor">
          <div class="col-11 text-center bg-white  pt-4 borderBlau">
            <div class="row">
              <div class="col-3 centriert">
                <img src="../assets/forward.png" class="img-fluid align-middle" alt="iso_9001" />

              </div>
              <div class="col-9 ">
                <p class=" Produktregulierung underline">Produktregulierung</p>
                <p class=" ProduktregulierungKlein"> REACH, RoHS …</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 p-3" v-if="sprachID === 0">

                Gemäß Veröffentlichung der SVHC-Liste (Übersicht
                        besorgniserregender Substanzen), werden die
                        entsprechenden Erklärungen der STFG hier zweimal
                        jährlich aktualisiert.
              </div>
            </div>
            <div class="row">
              <div class="col-12 pb-3">
                <span class="downloadLink pe-5 pb-3 pointer" 
                v-if="sprachID === 0" @click="openPDF('ProduktregulationDE.pdf')">
                  <i class="far fa-file-alt"></i> Download (DE)
                </span>
                <span class="downloadLink pointer" @click="openPDF('ProduktregulationEN.pdf')">
                  <i class="far fa-file-alt"></i> Download (EN)
                </span>
              </div>
            </div>
          </div>

        </div> -->
       
        </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";

export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
    };
  },
  setup() {
    const quali = ref(null);
    const qualiIsVisible = useElementVisibility(quali);

    return {
      quali,
      qualiIsVisible,
    };
  },
  components: {
    Layout,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
