<template>
  <div class="card  text-vue" style="width: 1200px; overflow: hidden">
    <div class="card-body">
      <div
      class="noMonitor" style="height: 620px; overflow-y: auto; overflow-x: hidden"
      :style="'height: 400px;'" >
        <DSE/>
      </div>
      <div
      class="noHandy" style="height: 620px; overflow-y: auto; overflow-x: hidden">
        <DSE/>
      </div>
      
    </div>
    <div class="card-footer text-center">

      <button type="button" class="mt-0 btn btn-danger m-1 noMonitor" @click="close()">
        Close
      </button>
      <button type="button" class="mt-3 btn btn-danger m-1 noHandy" @click="close()">
        Close
      </button>
    </div>
    <!-- Modal footer -->
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
import DSE from "../pages/Datenschutztext2.vue";

export default {
  name: "Modal_DSE",
 
  computed: {
    
  },
  methods: {
    close() {
      closeModal();
    },
    
  },
  components: {
    DSE,
  },
  created() {
    
    
  },
};
</script>
