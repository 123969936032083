<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0">       
          <div class="row m-0 pb-5 mb-0 justify-content-center" id="top">
            <div class="col-10">
              <div class="BereichUeberschrift">
                <i class="fas fa-flask BereichUeberschriftPicto"></i>
                <span v-if="sprachID === 0">Produkte</span>
                <span v-if="sprachID === 1">Products</span>
                <article></article>
              </div>
            </div>
          </div>

          <div class="row m-0 m-0 pb-2 justify-content-center"
          >
            <div class="col-11 text-center textBlau noMonitor">
              <h2 v-if="sprachID === 0">
                Ausgehend von jahrzehntelanger Erfahrung als Polyamid 6-Veredler, arbeiten
                wir Hand in Hand mit unseren Kunden bei der Herstellung PA6-basierter
                Verbundwerkstoffe.
                <br /><br />
                Lassen Sie sich von der Qualität unserer Produkte überzeugen!
              </h2>
              <h2 v-if="sprachID === 1">
                Based on decades of experience regarding the processing of polyamide 6, we
                regularly exchange with customers to ensure meeting requirements during
                the production of PA6-Compounds.
                <br /><br />
                Let the quality of our products convince you!
              </h2>
            </div>
            <div class="col-9 text-center textBlau noHandy">
              <h2 v-if="sprachID === 0">
                Ausgehend von jahrzehntelanger Erfahrung als Polyamid 6-Veredler, arbeiten
                wir Hand in Hand mit unseren Kunden bei der Herstellung PA6-basierter
                Verbundwerkstoffe.
                <br /><br />
                Lassen Sie sich von der Qualität unserer Produkte überzeugen!
              </h2>
              <h2 v-if="sprachID === 1">
                Based on decades of experience regarding the processing of polyamide 6, we
                regularly exchange with customers to ensure meeting requirements during
                the production of PA6-Compounds.
                <br /><br />
                Let the quality of our products convince you!
              </h2>
            </div>
          </div>
        <div class="row m-0 pt-4 px-5 justify-content-center noHandy">
          <div class="col-3 text-center m-3 p-5 border border-1">
            <a href="#eigen" class="navlink">
              <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                >Produktion</span
              >
              <span v-if="sprachID === 0" class="pb-5 KachelBase"
                >Übersicht/<br />
                Datenblätter</span
              >
              <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                >Production</span
              >
              <span v-if="sprachID === 1" class="pb-5 KachelBase"
                >Overview/ <br />Data Sheets</span
              >
            </a>
          </div>
          <div class="col-3 text-center m-3 p-5 border border-1">
            <a href="#fremd" class="navlink">
              <span v-if="sprachID === 0" class="pt-5 KachelTop underline">Handel</span>
              <span v-if="sprachID === 0" class="pb-5 KachelBase"
                >Übersicht/<br />
                Datenblätter</span
              >
              <span v-if="sprachID === 1" class="pt-5 KachelTop underline">Trading</span>
              <span v-if="sprachID === 1" class="pb-5 KachelBase"
                >Overview/<br />
                Data Sheets</span
              >
            </a>
          </div>
          <div class="col-3 text-center m-3 p-5 border border-1">
            <a href="#info" class="navlink">
              <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                >Informationen</span
              >
              <span v-if="sprachID === 0" class="pb-5 KachelBase"
                >Eigenschaften/<br />
                Anwendungen</span
              >
              <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                >Information</span
              >
              <span v-if="sprachID === 1" class="pb-5 KachelBase"
                >Properties/<br />
                Applications</span
              >
            </a>
          </div>
        </div>
      <div  :style="'min-height: ' + windowHeight * 0.20 + 'px;'"></div>
        <div class="row m-0 pt-0 justify-content-center noMonitor">
          <div class="col-11 text-center border border-1">
            <a href="#eigen" class="navlink">
              <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                >Produktion</span
              >
              <span v-if="sprachID === 0" class="pb-5 KachelBase"
                >Übersicht/<br />
                Datenblätter</span
              >
              <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                >Production</span
              >
              <span v-if="sprachID === 1" class="pb-5 KachelBase"
                >Overview/ <br />Data Sheets</span
              >
            </a>
          </div>
        </div>
        <div class="row m-0 pt-2 justify-content-center noMonitor">
          <div class="col-11 text-center border border-1">
            <a href="#fremd" class="navlink">
              <span v-if="sprachID === 0" class="pt-5 KachelTop underline">Handel</span>
              <span v-if="sprachID === 0" class="pb-5 KachelBase"
                >Übersicht/<br />
                Datenblätter</span
              >
              <span v-if="sprachID === 1" class="pt-5 KachelTop underline">Trading</span>
              <span v-if="sprachID === 1" class="pb-5 KachelBase"
                >Overview/<br />
                Data Sheets</span
              >
            </a>
          </div>
        </div>
        <div class="row m-0 pt-2 justify-content-center noMonitor">
          <div class="col-11 text-center  border border-1">
            <a href="#info" class="navlink">
              <span v-if="sprachID === 0" class="pt-5 KachelTop underline"
                >Informationen</span
              >
              <span v-if="sprachID === 0" class="pb-5 KachelBase"
                >Eigenschaften/<br />
                Anwendungen</span
              >
              <span v-if="sprachID === 1" class="pt-5 KachelTop underline"
                >Information</span
              >
              <span v-if="sprachID === 1" class="pb-5 KachelBase"
                >Properties/<br />
                Applications</span
              >
            </a>
          </div>
        </div>

        <span id="eigen" class="pb-5 m-0"></span>
        <div class="noHandy">
          <Produkte_eigen />
        </div>
        <div class="noMonitor">
          <Produkte_eigen_Handy />
        </div>

        <span id="fremd" class="pb-5 m-0"></span>
       <div class="noHandy">
          <Produkte_fremd />
        </div>
        <div class="noMonitor">
          <Produkte_fremd_Handy />
        </div> 
        <span id="info" class="pb-5 m-0"></span>
        <div class="noHandy">
          <Produkte_info />
        </div>
        <div class="noMonitor">
          <Produkte_info_Handy />
        </div>

        <a href="#top" class="stickyPfeil">
          <i class="fas fa-angle-double-up"></i>
        </a>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";

import Produkte_eigen from "./Produkte_eigen.vue";
import Produkte_eigen_Handy from "./Produkte_eigen_Handy.vue";
 import Produkte_fremd from "./Produkte_fremd.vue";
 import Produkte_fremd_Handy from "./Produkte_fremd_Handy.vue";
import Produkte_info from "./Produkte_info.vue";
import Produkte_info_Handy from "./Produkte_info_Handy.vue";

export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
      glasfaserAktiv: false,
      hitzeAktiv: false,
      thermoAktiv: false,
      recAktiv: false,
      polyAktiv: false,
      polyAktiv2: false,
      sonstiges: false,
    };
  },
  components: {
    Layout,
    Produkte_eigen,
    Produkte_eigen_Handy,
     Produkte_fremd,
     Produkte_fremd_Handy,
    Produkte_info,
    Produkte_info_Handy,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },

  setup() {
    const prod = ref(null);
    const prodIsVisible = useElementVisibility(prod);

    return {
      windowHeight: window.innerHeight,
      prod,
      prodIsVisible,
    };
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<style scoped></style>
