<template>
  <carousel :items-to-show="0.8" :wrap-around="true" :autoplay="10000" :class="topPicture">
    <slide v-for="slide in slides" :key="slide">
      <div>
        <img
        class="img-fluid  noHandy"
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"          
          v-bind:class="imgCH "           />      
          <img
        class="img-fluid  noMonitor"
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"          
          v-bind:class="imgCH "           />        
      </div>      
          <div class="textCarusellUntenMitte">{{slide.text}}</div>      
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);      
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      {bild:"pr2",text:""},
      {bild:"pr3",text:""},
    {bild:"pr4",text:""},
    {bild:"pr1",text:""},
      
    ];
    return {
      
      slides,
    };
  },
  
};
</script>


