<template #default>
  <div class="row m-0 m-1 justify-content-center">
    <div class="col-8 p-4">
      <span v-if="sprachID === 0">
        <h2 class="titel text-center BereichUeberschrift">Informationen</h2>
      </span>
      <span v-if="sprachID === 1">
        <h2 class="titel text-center BereichUeberschrift">Information</h2>
      </span>
    </div>
  </div>


  <div class="row m-0 pt-0 justify-content-center">
    <div class="col-4 textblock blocksatz text-start">
      <h4  v-if="sprachID === 0">Eigenschaften unserer Produkte</h4>
      <h4  v-if="sprachID === 1">Properties of our products</h4>
      <ol>
        <li class="pb-3 blocksatz ps-0" v-if="sprachID === 0">
          Modifizierbare mechanische Eigenschaften <br/>Glasfasern verleihen dem
          PA6-Verbundmaterial eine beträchtliche Steifigkeit und Festigkeit. Jedoch nimmt
          mit zunehmendem Glasfaseranteil die Schlagzähigkeit ab.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 1">
          Modifiable mechanical properties  <br/>Glass fibres contribute to significant rigidity
          of PA6-Compounds. However, by increasing glass fibre content, impact strength
          will be diminished.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 0">
          Beständigkeit gegenüber chemischen Einflüssen  <br/>Aufgrund seiner strukturellen
          Beschaffenheit ist PA6 bereits von Natur aus gegenüber vielen chemischen
          Einflüssen unempfindlich. Die Anwesenheit von Glasfasern verstärkt diese
          Eigenschaft noch weiter.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 1">
          Resistance to chemical exposure  <br/>Due to its structural character PA6 is inured to
          many chemical effects. The presence of glass fibres further improves this
          capability.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 0">
          Abrieb- und Verschleißfestigkeit  <br/>Die Verwendung von Glasfasern verbessert auch
          die Abrieb- und Verschleißfestigkeit des Verbundwerkstoffs.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 1">
          Resistance to wear <br/> Application of glass fibres improves the ability of compounds
          to withstand abrasion.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 0">
          Dimensionsstabilität  <br/>Glasfasern verhindern Verformungen und Verzerrungen des
          PA6-Verbundwerkstoffs.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 1">
          Dimensional stability <br/> Glass fibres prevent deformation of PA6-Compounds.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 0">
          Elektrisch isolierende Eigenschaften <br/> Die Kombination der chemischen und
          physikalischen Eigenschaften von Polyamid 6 und Glasfaser verleiht dem Material
          elektrisch isolierende Eigenschaften.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 1">
          Electrically isolating capabilities  <br/>The chemical and physical properties of
          Polyamide 6 as well as glass fibres result in electrically isolating attributes.
          
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 0">
          Wiederverwertbarkeit  <br/>Aufgrund der thermoplastischen Eigenschaften von Polyamid 6
          ist eine einfache Wiederverwertung des Materials möglich.
        </li>
        <li class="pb-3 blocksatz" v-if="sprachID === 1">
          Recyclability  <br/>Due to the thermoplastic character of Polyamide 6, recycling
          efforts are facilitated.
        </li>
      </ol>
      <div class="pt-3 ps-1 bold">
        <p class="pt-4 fw-bolder blocksatz" v-if="sprachID === 0">
          Zur Erfüllung kundenspezifischer Anforderungen können unsere Produkte durch Zusatz
          von Additiven und Regranulat weiter modifiziert werden.
        </p>
        <p class="pt-4 fw-bolder blocksatz" v-if="sprachID === 1">
          In order to satisfy customer specific requirements, our products can be modified further by utilization of additives and recycled PA6.
        </p>
      </div>
    </div>
    <div class="col-4  ps-5 pt-0 centriert">
      <div class="row">
        <div class="col-12 pb-4">
          <img
        src="../assets/IMG_6g.jpg"
        class="img-fluid align-middle"
        alt="Produktfoto"
      />
        </div>
      
        <div class="col-12 ">
          <img
          src="../assets/IMG_8g.jpg"
          class="img-fluid align-middle"
          alt="Produktfoto"
        />
        </div>
      </div>
      <br/>
      
    </div>
  </div>
  <div class="row m-0 pt-3 justify-content-center">
    <div class="col-8">
    </div>
  </div>
  <div class="row m-0 mt-5 pb-4 justify-content-center">
    <div class="col-4 p-3 ">
      
      <h4  v-if="sprachID === 0">Anwendungen unserer Produkte</h4>
      <h4  v-if="sprachID === 1">Applications of our products</h4>
      <CarouselProdukte></CarouselProdukte>
    </div>
    <div class="col-4 ps-5 pt-5 mt-1 textblock" v-if="sprachID === 0">
      <div class="row fw-bold bg-footer">
        <div class="col-1"></div>
        <div class="col-5">Anwendungsgebiet</div>
        <div class="col-5 ps-5">Beispiele</div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Bau und Haushalt</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">Dübel</li>
            <li class="kontaktLi">Rollen</li>
            <li class="kontaktLi">Werkzeuggehäuse</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Transportwesen</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">Sicherungselemente</li>
            <li class="kontaktLi">Verkleidungen</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Möbelproduktion</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">Abdeckungen</li>
            <li class="kontaktLi">Distanzstücke</li>
            <li class="kontaktLi">Füße</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Automobilindustrie</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">Halterungen</li>
            <li class="kontaktLi">Anbauteile</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-4 ps-5 pt-5 mt-1 textblock" v-if="sprachID === 1">
      <div class="row fw-bold bg-footer">
        <div class="col-1"></div>
        <div class="col-5">Applications </div>
        <div class="col-5 ps-5">Examples</div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Construction and home supplies</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">screw anchors</li>
            <li class="kontaktLi">wheels</li>
            <li class="kontaktLi">tool casings</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Transportation</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">safety elements</li>
            <li class="kontaktLi">covers</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Furniture production</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">guards</li>
            <li class="kontaktLi">distancers</li>
            <li class="kontaktLi">stands</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-5">Automotive industry</div>
        <div class="col-5">
          <ul>
            <li class="kontaktLi">mounts</li>
            <li class="kontaktLi">attachments</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselProdukte from "../components/carouselProdukte.vue";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    CarouselProdukte,
  },
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
