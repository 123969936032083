<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0">
        <div class="row pb-5 m-0 justify-content-center" id="top">
          <div class="col-10">
            <div class="BereichUeberschrift">
              <i class="fas fa-industry BereichUeberschriftPicto"></i>
              <span v-if="sprachID === 0">Unternehmen</span>
              <span v-if="sprachID === 1">Company</span>
            </div>
          </div>
        </div>

        <div class="row justify-content-center m-0 pb-4" v-if="sprachID == 0">
          <div class="col-md-8 col-sm-12 text-center textBlau">
            <h2>Wir produzieren glasfaserverstärktes Polyamid.</h2>
          </div>
        </div>
        <div class="row justify-content-center m-0 pb-4" v-if="sprachID == 1">
          <div class="col-md-8 col-sm-12 text-center textBlau">
            <h2>We produce glass reinforced polyamide.</h2>
          </div>
        </div>

        <div class="row justify-content-center pt-4 m-0 noHandy" v-if="sprachID == 0">
          <div class="col-lg-4 col-sm-3 text-center p-1">
            <img
              src="../assets/Smart-Art-Unternehmen.png "
              class="card-img-top produktBildRad"
              alt="Smart-Art-Unternehmen"
            />
          </div>
          <div
            class="col-md-4 col-sm-5  pe-5 textAllgemein grau40"
            style="margin-bottom"
          >
            <h4>Stärken</h4>
            <p class="pt-2 fw-bolder mb-0">Zentrale Lage in Europa</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              kurze, umwelt- und <br/>ressourcenschonende Vertriebswege
            </p>
            <p class="pt-5 fw-bolder  mb-0">Schlanker Unternehmensaufbau</p>
            <p class="mb-0 ps-3" style="margin-bottom">mehr Effizienz</p>
            <p class="mb-0 ps-3" style="margin-bottom">hohe Flexibilität</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              wettbewerbsfähige Kostenstruktur
            </p>

            <p class="pt-5 fw-bolder  mb-0">Engagiertes Team</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              individualisierte Lösungsansätze
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">exzellenter Kundenservice</p>
            <p class="mb-0 ps-3" style="margin-bottom">schnelle Reaktionszeiten</p>
          </div>
        </div>

        <div class="row justify-content-center pt-4 m-0 noHandy" v-if="sprachID == 1">
          <div class="col-lg-4 col-sm-3 text-center p-1">
            <img
              src="../assets/Smart-Art-Unternehmeneng.png "
              class="card-img-top produktBildRad"
              alt="Smart-Art-Unternehmen"
            />
          </div>
          <div
            class="col-md-4 col-sm-5 blocksatz pe-5 textblock textAllgemein grau40"
            style="margin-bottom"
          >
            <h4>Strengths</h4>            
            <p class=" fw-bolder  mb-0">Located in central Europe</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              short distances to customers
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">             
              environmentally friendly distribution
            </p>
            <p class="pt-5 fw-bolder  mb-0">Lean Organisation</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              improved efficiency
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              increased flexibility
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              competitive cost structure
            </p>

            <p class="pt-5 fw-bolder  mb-0">Committed Team</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              individualized conception
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              excellent customer service
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              fast response times
            </p>
          </div>
        </div>

        <div class="row justify-content-center pt-5 m-0 noMonitor" v-if="sprachID == 0">
          <div class="col-12 text-center p-1">
            <img src="../assets/Zahnrad.png " class="card-img-top qualiBild1" alt="..." />
          </div>
        </div>
        <div class="row justify-content-center m-0 noMonitor">
          <div class="col-11 blocksatz pe-5 textblock textAllgemein" v-if="sprachID == 0">
            
            <h4>Stärken</h4>
            <p class="pt-2 fw-bolder mb-0">Zentrale Lage in Europa</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              kurze, umwelt- und <br/>ressourcenschonende Vertriebswege
            </p>
            <p class="pt-5 fw-bolder  mb-0">Schlanker Unternehmensaufbau</p>
            <p class="mb-0 ps-3" style="margin-bottom">mehr Effizienz</p>
            <p class="mb-0 ps-3" style="margin-bottom">hohe Flexibilität</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              wettbewerbsfähige Kostenstruktur
            </p>

            <p class="pt-5 fw-bolder  mb-0">Engagiertes Team</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              individualisierte Lösungsansätze
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">exzellenter Kundenservice</p>
            <p class="mb-0 ps-3" style="margin-bottom">schnelle Reaktionszeiten</p>
            
          </div>
        </div>
        <div class="row justify-content-center pt-5 m-0 noMonitor" v-if="sprachID == 1">
          <div class="col-12 text-center p-3">
            <img src="../assets/Smart-Art-Unternehmeneng.png " class="card-img-top qualiBild1" alt="..." />
          </div>
        </div>
        <div class="row justify-content-center m-0 noMonitor">
          <div class="col-11 blocksatz pe-5 textblock textAllgemein" v-if="sprachID == 1">
            <h4>Strengths</h4>            
            <p class=" fw-bolder  mb-0">Located in central Europe</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              short distances to customers
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">             
              environmentally friendly distribution
            </p>
            <p class="pt-5 fw-bolder  mb-0">Lean Organisation</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              improved efficiency
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              increased flexibility
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              competitive cost structure
            </p>

            <p class="pt-5 fw-bolder  mb-0">Committed Team</p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              individualized conception
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              excellent customer service
            </p>
            <p class="mb-0 ps-3" style="margin-bottom">
              
              fast response times
            </p>
          </div>
        </div>

        <div class="row justify-content-center pt-5 m-0 p-0 noHandy">
          <div class="col-4 blocksatz px-5 textblock p-0" v-if="sprachID == 0">
            <h4>Vergangenheit</h4>
            Die STFG blickt zurück auf eine mehr als 70-jährige Geschichte am
            Chemiestandort Schwarza in Thüringen. Hervorgegangen aus dem
            Chemiefaser&shy;kombinat Rudolstadt, lag der Unternehmens&shy;schwerpunkt auf
            der Herstellung und Verarbeitung synthetischer Garne. Durch zunehmende
            Herausforderungen in diesem Markt wurde im September 2020 eine Anlage zur
            Produktion modifizierter Kunststoffgranulate in Betrieb genommen. Ziel war
            es das Portfolio bei ähnlicher Rohstoffbasis sinnvoll zu erweitern. Mitte 2021
            folgte die Entscheidung das Garngeschäft einzustellen. Seither bilden
            Granulatproduktion und Handel die beiden Standbeine der STFG.
          </div>
          <div class="col-4 blocksatz px-5 textblock p-0" v-if="sprachID == 1">
            <h4>Past</h4>
            STFG emerged from the state holding “chemical fibres Rudolstadt”, with a focus
            on manufacturing and processing synthetic yarn. In retrospective that means
            over 70 years of operation at the production site in Schwarza/ Thuringia. Due
            to increasing challenges within the fibre market, realignment was considered
            necessary. Hence, a new production facility for plastic granules has been
            established in September 2020, aiming for an extended portfolio while using
            similar raw materials. During the second half of 2021 a decision was made to
            discontinue yarn related activities. Since that point in time, granule
            production and distribution have constituted the foundations of STFG’s
            operation.
          </div>
          <div class="col-4 text-center p-0">
            <img
              src="../assets/Spinnmaschinen.jpg"
              class="card-img-top unternehmenBild1"
              alt="..."
            />
          </div>
        </div>
        <div class="row pt-5 justify-content-center m-0 noMonitor">
          <div class="col-11 text-center">
            <h4 v-if="sprachID == 0">Vergangenheit</h4>
            <h4 v-if="sprachID == 1">Past</h4>
            <img
              src="../assets/Spinnmaschinen.jpg"
              class="card-img-top unternehmenBild1"
              alt="..."
            />
          </div>
        </div>
        <div class="row pt-2 justify-content-center m-0 noMonitor">
          <div class="col-11 blocksatz textblock textAllgemein" v-if="sprachID == 0">
            Die STFG blickt zurück auf eine mehr als 70-jährige Geschichte am
            Chemiestandort Schwarza in Thüringen. Hervorgegangen aus dem
            Chemiefaser&shy;kombinat Rudolstadt, lag der Unternehmens&shy;schwerpunkt auf
            der Herstellung und Verarbeitung synthetischer Garne. Durch zunehmende
            Herausforderungen in diesem Markt wurde im September 2020 eine Anlage zur
            Produktion modifizierter Kunststoffgranulate in Betrieb genommen. Ziel war
            es das Portfolio bei ähnlicher Rohstoffbasis sinnvoll zu erweitern. Mitte 2021
            folgte die Entscheidung das Garngeschäft einzustellen. Seither bilden
            Granulatproduktion und Handel die beiden Standbeine der STFG.
          </div>
          <div class="col-11 blocksatz textblock textAllgemein" v-if="sprachID == 1">
            STFG emerged from the state holding “chemical fibres Rudolstadt”, with a focus
            on manufacturing and processing synthetic yarn. In retrospective that means
            over 70 years of operation at the production site in Schwarza/ Thuringia. Due
            to increasing challenges within the fibre market, realignment was considered
            necessary. Hence, a new production facility for plastic granules has been
            established in September 2020, aiming for an extended portfolio while using
            similar raw materials. During the second half of 2021 a decision was made to
            discontinue yarn related activities. Since that point in time, granule
            production and distribution have constituted the foundations of STFG’s
            operation.
          </div>
        </div>

        <div class="row pt-5 justify-content-center m-0  noHandy">
          <div class="col-4 text-center p-3">
            <img
              src="../assets/DSC_0006.jpg"
              class="card-img-top unternehmenBild2 p-1"
              alt="..."
            />
          </div>
          <div class="col-4 blocksatz textblock p-0" v-if="sprachID == 0">
            <h4>Gegenwart</h4>
            Produziert wird in einem maßgeschneiderten, hoch automatisierten Umfeld in bis
            zu 4 Schichten. Durch Variation der Hauptkomponenten und Zugabe
            von Additiven entstehen Produkttypen mit spezifischen Eigenschaften. <br/> <br/>
            Nachhaltigkeit besitzt für die STFG einen hohen Stellenwert, daher bieten wir
            ebenfalls Recyclingvarianten an. <br/>  <br/>Unsere Kunden sind Industrieunternehmen, die
            mithilfe des Spritzgussverfahrens eine breite Palette von Formteilen
            herstellen. Diese Bauteile finden Anwendung in Bereichen wie Bau,
            Transportwesen und Haushalt sowie in der Automobilindustrie. Gemeinsam mit
            Vertriebspartnern erschließen wir den europäischen Markt für Produkte aus
            Rudolstadt.
          </div>
          <div class="col-4 blocksatz textblock p-0" v-if="sprachID == 1">
            <h4>Present</h4>
            We produce in a customized, highly automated environment up to a schedule of 4
            shifts (24/7). In order to attain specific properties, various categories of
            finished products can be created by adjusting the ratio of raw materials and
            additives. <br/>  <br/>Sustainability is a major topic for STFG. Therefore, the portfolio
            includes granules based on recycled polyamide 6. <br/> <br/> Our customers are industrial
            enterprises that manufacture a wide range of parts by means of injection
            moulding. Applications can be found within the fields construction,
            transportation, home supplies as well as the automotive industry. Cooperating
            with distribution partners, we provide products from Rudolstadt to the
            European market.
          </div>
        </div>

        <div class="row pt-5 justify-content-center m-0  noMonitor">
          <div class="col-12 text-center">
            <h4 v-if="sprachID == 0">Gegenwart</h4>
            <h4 v-if="sprachID == 1">Present</h4>
            <img
              src="../assets/DSC_0006.jpg"
              class="card-img-top unternehmenBild2"
              alt="..."
            />
          </div>
        </div>
        <div class="row pt-2 justify-content-center m-0 noMonitor">
          <div class="col-11 blocksatz textblock textAllgemein p-0" v-if="sprachID == 0">
            Produziert wird in einem maßgeschneiderten, hoch automatisierten Umfeld in bis
            zu 4 Schichten. Durch Variation der Hauptkomponenten und Zugabe
            von Additiven entstehen Produkttypen mit spezifischen Eigenschaften. <br/> <br/>
            Nachhaltigkeit besitzt für die STFG einen hohen Stellenwert, daher bieten wir
            ebenfalls Recyclingvarianten an. <br/>  <br/>Unsere Kunden sind Industrieunternehmen, die
            mithilfe des Spritzgussverfahrens eine breite Palette von Formteilen
            herstellen. Diese Bauteile finden Anwendung in Bereichen wie Bau,
            Transportwesen und Haushalt sowie in der Automobilindustrie. Gemeinsam mit
            Vertriebspartnern erschließen wir den europäischen Markt für Produkte aus
            Rudolstadt.
          </div>
          <div class="col-11 blocksatz textblock textAllgemein p-0" v-if="sprachID == 1">
            We produce in a customized, highly automated environment up to a schedule of 4
            shifts (24/7). In order to attain specific properties, various categories of
            finished products can be created by adjusting the ratio of raw materials and
            additives. <br/>  <br/>Sustainability is a major topic for STFG. Therefore, the portfolio
            includes granules based on recycled polyamide 6. <br/> <br/> Our customers are industrial
            enterprises that manufacture a wide range of parts by means of injection
            moulding. Applications can be found within the fields construction,
            transportation, home supplies as well as the automotive industry. Cooperating
            with distribution partners, we provide products from Rudolstadt to the
            European market.
          </div>
        </div>

        <div class="row pt-5 pb-5 justify-content-center m-0 noHandy">
          <div class="col-4 blocksatz px-5 textblock p-0" v-if="sprachID == 0">
            <h4>Zukunft</h4>
            Unsere Unternehmensziele umfassen die fortlaufende Optimierung der Prozesse,
            die Erweiterung von Produktionskapazitäten, die konsequente Entwicklung
            innovativer Erzeugnisse, die Förderung von Nachhaltigkeit in allen
            Unternehmensbereichen und das kontinuierliche Lernen aus Erfahrungen. Durch
            diesen Anspruch werden wir unsere Effizienz steigern, die Wettbewerbsfähigkeit
            stärken, verantwortungsvoll mit Ressourcen umgehen und stetig als Unternehmen
            wachsen.
            <p class="pt-4 fw-bolder">
              Kundenspezifische Anforderungen sind der Motor für diese Weiterentwicklung.
            </p>
          </div>
          <div class="col-4 blocksatz px-5 textblock p-0" v-if="sprachID == 1">
            <h4>Future</h4>
            Our objectives include continuous process optimization, the expansion of
            capacities, consistent development of innovative products, the promotion of
            sustainability within the entire company as well as persistent utilization of
            experiences. Based on those ambitions we will increase efficiency, improve
            competitiveness, foster a sense of responsibility regarding the use of
            resources and grow steadily as a company.

            <p class="pt-4 fw-bolder">
              Customer requirements are key drivers for this evolution.
            </p>
          </div>
          <div class="col-4 p-0">
            <img
              src="../assets/future.jpg"
              class="card-img-top unternehmenBild3"
              alt="..."
            />
          </div>
        </div>
        <div class="row pt-5 justify-content-center m-0 noMonitor">
          <div class="col-11 text-center">
            <h4 v-if="sprachID == 0">Zukunft</h4>
            <h4 v-if="sprachID == 1">Future</h4>
            <img
              src="../assets/future.jpg"
              class="card-img-top unternehmenBild3"
              alt="..."
            />
          </div>
        </div>
        <div class="row pt-2 pb-5 justify-content-center m-0 noMonitor">
          <div class="col-11 blocksatz textblock textAllgemein" v-if="sprachID == 0">
            Unsere Unternehmensziele umfassen die fortlaufende Optimierung der Prozesse,
            die Erweiterung von Produktionskapazitäten, die konsequente Entwicklung
            innovativer Erzeugnisse, die Förderung von Nachhaltigkeit in allen
            Unternehmensbereichen und das kontinuierliche Lernen aus Erfahrungen. Durch
            diesen Anspruch werden wir unsere Effizienz steigern, die Wettbewerbsfähigkeit
            stärken, verantwortungsvoll mit Ressourcen umgehen und stetig als Unternehmen
            wachsen.
            <p class="pt-4 fw-bolder">
              Kundenspezifische Anforderungen sind der Motor für diese Weiterentwicklung.
            </p>
          </div>
          
          <div class="col-11 blocksatz textblock textAllgemein" v-if="sprachID == 1">
            Our objectives include continuous process optimization, the expansion of
            capacities, consistent development of innovative products, the promotion of
            sustainability within the entire company as well as persistent utilization of
            experiences. Based on those ambitions we will increase efficiency, improve
            competitiveness, foster a sense of responsibility regarding the use of
            resources and grow steadily as a company.

            <p class="pt-4 fw-bolder">Customer requirements are key drivers for this evolution.</p>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";

export default {
  name: "Datenschutz",

  data() {
    return {
      newSite: null,
    };
  },
  setup() {
    const untern = ref(null);
    const unternIsVisible = useElementVisibility(untern);

    return {
      untern,
      unternIsVisible,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
