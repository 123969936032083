<template #default>
  <div class="row m-0 justify-content-center">
    <div class="col-10">
      <span v-if="sprachID === 0"
        ><h2 class="titel text-center BereichUeberschrift">Handel</h2>
        <div id="po6"></div>
      </span>
      <span v-if="sprachID === 1"
        ><h2 class="titel text-center BereichUeberschrift">Trading</h2>
        <div id="po6"></div>
      </span>
    </div>
  </div>

  <div class="anchor" id="po6h" style="position: relative; top: -94px"></div>
  <div class="row m-0 justify-content-center">
    <div class="col-8 text-center">
      <img
        src="../assets/p1.png"
        class="img-fluid align-middle borderBlauKlein"
        alt="..."
      />
    </div>
  </div>
  <div class="row m-0 justify-content-center">
    <div class="col-11">
      <span v-if="sprachID === 0">
        <h3 class="titelH3">Polyamid 6</h3>
      </span>
      <span v-if="sprachID === 1">
        <h3 class="titelH3">Polyamide 6</h3>
      </span>
    </div>
  </div>

  <div class="row m-0 justify-content-center textAllgemein">
    <div class="col-11">
      <span v-if="sprachID === 0">
        Rohpolymer verschiedener Viskositäten<br />
        Verpackung: BigBag (bis zu 1.000 kg) auf Palette
      </span>
      <span v-if="sprachID === 1">
        raw polymer of varying viscosity <br />
        packaging: BigBags (up to 1.000 kg) on pallets
      </span>
    </div>
  </div>
  <div class="row m-0 justify-content-center pt-2">
    <div class="col-11 grau20 p-0">
      <div class="row m-0 blauStandart">
        <div class="col-5">
          <span v-if="sprachID === 0">
            <h4 class="titelH4">Bezeichnung</h4>
          </span>
          <span v-if="sprachID === 1">
            <h4 class="titelH4">Name</h4>
          </span>
        </div>
        <div class="col-7">
          <span v-if="sprachID === 0">
            <h4 class="titelH4">Kerneigenschaften</h4>
          </span>
          <span v-if="sprachID === 1">
            <h4 class="titelH4">Key Properties</h4>
          </span>
        </div>
      </div>

      <div class="row m-0 borderGrauUnten">
        <div class="col-5">
          <span v-if="sprachID === 0"> niedrige Viskosität</span>
          <span v-if="sprachID === 1"> low viscosity</span>
        </div>
        <div class="col-7">
          <span v-if="sprachID === 0">Viskosität um 2,4</span>
          <span v-if="sprachID === 1">viscosity approx 2,4</span>
        </div>
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-1 offset-8">
          <i
            class="fas fa-clipboard-list downloadIcon pe-3 enColor"
            title="technisches Datenblatt"
            @click="openPDF('PDS PA6-24.pdf')"
          ></i>
        </div>
        <!-- <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 deColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
          ></i>
        </div>
        <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 enColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
          ></i>
        </div>
        <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 itColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
          ></i>
        </div> -->
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-5">
          <span v-if="sprachID === 0">mittlere Viskosität</span>
          <span v-if="sprachID === 1">medium viscosity</span>
        </div>
        <div class="col-7">
          <span v-if="sprachID === 0">Viskosität um 2,7</span>
          <span v-if="sprachID === 1">viscosity approx 2,7</span>
        </div>
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-1 offset-8">
          <i
            class="fas fa-clipboard-list downloadIcon pe-3 enColor"
            title="technisches Datenblatt"
            @click="openPDF('PDS PA6-27.pdf')"
          ></i>
        </div>
        <!-- <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 deColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
          ></i>
        </div>
        <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 enColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
          ></i>
        </div>
        <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 itColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
          ></i>
        </div> -->
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-5">
          <span v-if="sprachID === 0">recyceltes PA6</span>
          <span v-if="sprachID === 1">recycled PA6</span>
        </div>
        <div class="col-7">
          <span v-if="sprachID === 0">Hohe Viskosität</span>
          <span v-if="sprachID === 1">high viscosity</span>
        </div>
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-1 offset-8">
          <i
              class="fas fa-clipboard-list downloadIcon pe-3 enColor"
                title="technisches Datenblatt"
                @click="openPDF('PDS PA6 recycle.pdf')"
              ></i>
        </div>
        <!-- <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 deColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
          ></i>
        </div>
        <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 enColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
          ></i>
        </div>
        <div class="col-1 text-center">
          <i
            class="fas fa-notes-medical downloadIcon pe-3 itColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
          ></i>
        </div> -->
      </div>
    </div>
  </div>

  <div class="anchor" id="gf2h" style="position: relative; top: -94px"></div>
  <div class="row m-0 mt-4 justify-content-center">
    <div class="col-8 text-center">
      <img
        src="../assets/p1.png"
        class="img-fluid align-middle borderBlauKlein"
        alt="..."
      />
    </div>
  </div>
  <div class="row m-0 justify-content-center">
    <div class="col-11">
      <span v-if="sprachID === 0">
        <h3 class="titelH3">Unverstärktes Polyamid 6</h3>
      </span>
      <span v-if="sprachID === 1">
        <h3 class="titelH3">Unreinforced Polyamide 6</h3>
      </span>
    </div>
  </div>

  <div class="row m-0 justify-content-center textAllgemein">
    <div class="col-11">
      <span v-if="sprachID === 0">
        Rohpolymer verschiedener Viskositäten mit Additiven (verbesserte Verarbeitung/
        Formgebung, spezifische Eigenschaften)<br />
        Verpackung: Alusäcke (25 kg) auf Paletten
      </span>
      <span v-if="sprachID === 1"
        >raw polymer of varying viscosity with additives (improved processing, specific
        properties)<br />
        packaging: aluminium bags (25 kg) on pallets</span
      >
    </div>
  </div>

  <div class="row m-0 justify-content-center pt-2">
    <div class="col-11 grau20 p-0">
      <div class="row m-0 blauStandart">
        <div class="col-5">
          <span v-if="sprachID === 0">
            <h4 class="titelH4">Bezeichnung</h4>
          </span>
          <span v-if="sprachID === 1">
            <h4 class="titelH4">Name</h4>
          </span>
        </div>
        <div class="col-7">
          <span v-if="sprachID === 0">
            <h4 class="titelH4">Kerneigenschaften</h4>
          </span>
          <span v-if="sprachID === 1">
            <h4 class="titelH4">Key Properties</h4>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 justify-content-center pt-0">
    <div class="col-11 grau20 p-0">
      <div class="row m-0 borderGrauUnten">
        <div class="col-5">B2D</div>
        <div class="col-6">
          <span v-if="sprachID === 0"> mittlere Viskosität</span>
          <span v-if="sprachID === 1"> medium viscosity</span>
        </div>
        <div class="col-1 offset-8">
          <i
            class="fas fa-clipboard-list downloadIcon pe-3 enColor"
            title="technisches Datenblatt"
            @click="openPDF('EN PDS B2D 2023 STFG.pdf')"
          ></i>
        </div>
        <!-- <div class="col-1 text-center">
        <span v-if="sprachID === 0">
          <i
            class="fas fa-notes-medical downloadIcon p-1 ps-4 deColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
          ></i
          ><i
            class="fas fa-notes-medical downloadIcon p-1 enColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
          ></i
          ><i
            class="fas fa-notes-medical downloadIcon p-1 itColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
          ></i
        ></span>
        <span v-if="sprachID === 1">
          <i
            class="fas fa-notes-medical downloadIcon p-1 ps-4 deColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG DE.pdf')"
          ></i
          ><i
            class="fas fa-notes-medical downloadIcon p-1 enColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG EN.pdf')"
          ></i
          ><i
            class="fas fa-notes-medical downloadIcon p-1 itColor"
            title="Sicherheitsdatenblatt"
            @click="openPDF('SDS_BxD_2023 STFG IT.pdf')"
          ></i
        ></span>
      </div> -->
      </div>
    </div>
  </div>
  <div class="anchor" id="ureah" style="position: relative; top: -94px"></div>
 <!-- <div class="row m-0 mt-5 justify-content-center">
    <div class="col-8 text-center">
      <img
        src="../assets/fluessig.jpg"
        class="img-fluid align-middle borderBlauKlein"
        alt="..."
      />
    </div>
  </div>
   <div class="row m-0 justify-content-center">
    <div class="col-11">
      <span v-if="sprachID === 0">
        <h3 class="titelH3">sonstiges</h3>
      </span>
      <span v-if="sprachID === 1">
        <h3 class="titelH3">Miscellaneous</h3>
      </span>
    </div>
  </div>

  <div class="row m-0 justify-content-center textAllgemein">
    <div class="col-11">
      <span v-if="sprachID === 0">
        Diverse Substanzen in Großgebinden (z. B. Harnstoff und Düngemittel)
      </span>
      <span v-if="sprachID === 1">
        Various chemical products in bulk packaging (e.g. fertilizer)
      </span>
    </div>
  </div>

  <div class="row m-0 justify-content-center pt-2">
    <div class="col-11 grau20 p-0">
      <div class="row m-0 blauStandart">
        <div class="col-4">
          <span v-if="sprachID === 0">
            <h4 class="titelH4">Bezeichnung</h4>
          </span>
          <span v-if="sprachID === 1">
            <h4 class="titelH4">Name</h4>
          </span>
        </div>
        <div class="col-5">
          <span v-if="sprachID === 0">
            <h4 class="titelH4">Kerneigenschaften</h4>
          </span>
          <span v-if="sprachID === 1">
            <h4 class="titelH4">Key Properties</h4>
          </span>
        </div>
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-4">UREA</div>
        <div class="col-8">
          <span v-if="sprachID === 0">Stickstoffgehalt 46 % (min.)</span>
          <span v-if="sprachID === 1">nitrogen content 46 % (min)</span>
        </div>
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-3 offset-8">
          <span>
            <i
              class="fas fa-clipboard-list downloadIcon p-1 enColor"
              title="technisches Datenblatt"
              @click="openPDF('PDS UAN EN2.pdf')"
            ></i>
          </span>
        </div>
      </div>

      <div class="row m-0 borderGrauUnten">
        <div class="col-4">UAN-32</div>
        <div class="col-8">
          <span v-if="sprachID === 0"> Stickstoffgehalt 31,7 % (min.)</span>
          <span v-if="sprachID === 1"> nitrogen content 31,7 % (min)</span>
        </div>
      </div>
      <div class="row m-0 borderGrauUnten">
        <div class="col-3 offset-8">
          <span v-if="sprachID === 0">
            <i
              class="fas fa-clipboard-list downloadIcon p-1 enColor"
              title="technisches Datenblatt"
              @click="openPDF('PDS UREA EN2.pdf')"
            ></i>
          </span>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
      polyAktiv: false,
      polyAktiv2: false,
      sonstiges: false,
    };
  },
  components: {},
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
